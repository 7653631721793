import * as React from 'react';
import { find, first, flow, map, get } from 'lodash';
import { Button, Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { IBenefit } from '../../../../../data/api/models';
import { IQuoteState } from '../../../../../services/quote/models';

interface IBenefitsSectionProps {
  quote: IQuoteState;
  isAggregator: boolean;
}

interface IBenefitsSectionStates {
  viewFullBenefits: boolean;
}

class AggBenefitsSection extends React.Component<IBenefitsSectionProps, IBenefitsSectionStates> {
  public state = {
    viewFullBenefits: false,
  };

  private toggleViewBenefits = () => {
    this.setState((prevState) => ({
      viewFullBenefits: !prevState.viewFullBenefits,
    }));
  }

  private benefitFormat = (value: string) => {
    return value === 'n/a' || value === 'nil' || !value ? 'x' : value;
  }

  private benefitsRow = (benefit: IBenefit, premiumSelected, key, subBenefit: boolean = false, cruiseCount) => {
    const isAggregator = true;

    const name = benefit.shortName ? benefit.shortName : benefit.name;
    let limit = this.benefitFormat(benefit.text);
    let subBenefits = [];

    if (benefit.shortName === 'Cruise Cover' && isAggregator && cruiseCount < 2) {
      return null;
    }

    // If nothing on top level then use from first sub benefit
    if (benefit.subBenefits && benefit.subBenefits.length > 0) {
      if (limit === 'x') {
        limit = this.benefitFormat(first(benefit.subBenefits).text);
      }
    }

    if (limit !== 'x' && limit !== 'NA') {
      let additionalText = '';

      switch (name) {
        case 'Personal Belongings':
        case 'Gadget Cover':
          additionalText = ' (wear and tear deductions will apply for items over a year old)'
          break;
        case 'Delayed/Missed Departure':
          subBenefits = benefit.subBenefits || [];
          break;
      }

      if (subBenefits.length > 0) {
        return subBenefits.map((item, index) => {
          return (
            <ul key={index} className="declaration-green">
              <li style={{ margin: '0 0 5px 0' }} className="declaration-list">
                <>{item.name + ' Up to ' + item.text}</>
              </li>
            </ul>
          )
        })

      } else {
        return (
          <ul key={key} className="declaration-green">
            <li style={{ margin: '0 0 5px 0' }} className="declaration-list">
              <>{name + ' ' + limit + additionalText}</>
            </li>
          </ul>
        );
      }
    }

  }

  private renderBenefits = () => {
    const { quote } = this.props;

    const premiumSelected = find(find(quote.quotes));
    const cruiseCount = premiumSelected.benefits.filter((benefit) => benefit.shortName === 'Cruise Cover').length;
    const benefits = premiumSelected.benefits.reduce((acc: IBenefit[], current: IBenefit) => {
      // Remove duplicate benefit names
      if (!map(acc, 'shortName').includes(current.shortName)) {
        acc.push(current);
        return acc;
      }

      return acc;
    }, []);

    // Requested order change from admiral for medical to be first
    let sortedOrder = benefits.filter((benefit) => benefit.shortName === 'Medical Expenses');
    sortedOrder = [...sortedOrder, ...benefits.filter((benefit) => benefit.shortName !== 'Medical Expenses')];

    return (
      map(sortedOrder, (benefit: IBenefit, key) => {
        return this.benefitsRow(benefit, premiumSelected, key, false, cruiseCount);
      })
    );
  }

  private getSource = (coverLevel: string): string => {
    switch (coverLevel) {
      case 'admiral':
        return require('../../../img/admiral_silver.png');
      case 'gold':
        return require('../../../img/admiral_gold.png');
      case 'platinum':
        return require('../../../img/admiral_platinum.png');
    }
  }

  public render() {
    const { viewFullBenefits } = this.state;
    const { quote } = this.props;

    const MIN_NUMBER_OF_BENEFITS = 3;

    let benefits = this.renderBenefits();

    if (!viewFullBenefits) {
      benefits = benefits.slice(0, MIN_NUMBER_OF_BENEFITS);
    }

    const premiumSelected = find(find(quote.quotes));
    const standardExcess = get(premiumSelected, 'information.standardExcess')
    const coverLevel = Object.keys(quote.quotes)[0];

    return (
      <div className="summary-section">
        <div>
          <Row>
            <Col xs={5}>
              <img src={this.getSource(coverLevel)} alt={''}/>
            </Col>
            <Col xs={7}>
              {benefits}
            </Col>
          </Row>
          <Row>
            <Col xs={5}/>
            <Col xs={7} style={{ paddingLeft: '55px' }}>Excess {standardExcess}</Col>
          </Row>
        </div>
        <div style={{ textAlign: 'right', marginBottom: '10px' }}>
          <Button
            bsStyle="link"
            onClick={this.toggleViewBenefits}
          >
            {viewFullBenefits ? 'Hide' : 'More info'}
          </Button>
        </div>
        <div style={{ textAlign: 'right' }}>
          <a href={premiumSelected && premiumSelected.scheme.documents['Insurance Product Information Document']}
             target="_blank">
            Insurance Product Information Document
          </a>
        </div>
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state) => {
      return {
        quote: get(state, 'quote'),
      };
    },
  ),
])(AggBenefitsSection);
