import { IAggregatorTracking, IBrandingAction, IBrandingActions, IBrandingState, II18nMessages } from './models';
import { IChannel, IOfflineSchedule, ITrackingLink } from '../../data/api/models';
import { IStore } from '../../redux/IStore';
import * as api from '../../data/api/api';

const SET_I18N = 'app/branding/SET_I18N';
const SET_LOCALE = 'app/branding/SET_LOCALE';
const SET_CHANNEL = 'app/branding/SET_CHANNEL';
const SET_IS_OFFLINE = 'app/branding/SET_IS_OFFLINE';
const SET_TRACKING_LINK = 'app//branding/SET_TRACKING_LINK';
const SET_AGGREGATOR_TRACKING = 'app/branding/SET_AGGREGATOR_TRACKING';
const SET_DEFAULT_CHANNEL_KEY = 'app/branding/SET_DEFAULT_CHANNEL_KEY';

const initialState: IBrandingState = {
  locale: 'en-GB',
  i18n: {},
  channel: null,
  isOffline: false,
  aggregatorTracking: {
    type: null,
  },
  trackingLink: null,
  defaultChannelKey: null,
};

export function reducer(state: IBrandingState = initialState, action: IBrandingAction): IBrandingState {
  switch (action.type) {
    case SET_I18N:
      return {
        ...state,
        i18n: action.payload.i18n,
      };
    case SET_LOCALE:
      return {
        ...state,
        locale: action.payload.locale,
      };
    case SET_CHANNEL:
      return {
        ...state,
        channel: action.payload.channel,
      };
    case SET_DEFAULT_CHANNEL_KEY:
      return {
        ...state,
        defaultChannelKey: action.payload.defaultChannelKey,
      };
    case SET_AGGREGATOR_TRACKING:
      return {
        ...state,
        aggregatorTracking: action.payload.aggregatorTracking,
      };
    case SET_TRACKING_LINK:
      return {
        ...state,
        trackingLink: action.payload.trackingLink,
        channel: {
          ...state.channel,
          phone: action.payload.trackingLink.phone,
        },
      };
    case SET_IS_OFFLINE:
      return {
        ...state,
        isOffline: action.payload.isOffline,
        offlineSchedule: action.payload.offlineSchedule,
      };
    default:
      return state;
  }
}

const setLocale = (locale: string): IBrandingAction => ({ type: SET_LOCALE, payload: { locale } });
const setChannel = (channel: IChannel): IBrandingAction => ({ type: SET_CHANNEL, payload: { channel } });
const setDefaultChannelKey =
  (defaultChannelKey: string): IBrandingAction => ({ type: SET_DEFAULT_CHANNEL_KEY, payload: { defaultChannelKey } });
const setI18n = (i18n: II18nMessages): IBrandingAction => ({ type: SET_I18N, payload: { i18n } });
const setAggregatorTracking = (parameters: IAggregatorTracking): IBrandingAction => ({
  type: SET_AGGREGATOR_TRACKING,
  payload: {
    aggregatorTracking: parameters,
  },
});
const setTrackingLink = (trackingLink: ITrackingLink) => ({ type: SET_TRACKING_LINK, payload: { trackingLink } });
// const setTrackingLink = (trackingLink: ITrackingLink) => {
//   if (trackingLink.status === 'active') {
//     return ({ type: SET_TRACKING_LINK, payload: { trackingLink } });
//   }
// };
const setIsOffline = (isOffline: boolean, offlineSchedule: IOfflineSchedule): IBrandingAction => ({
  type: SET_IS_OFFLINE,
  payload: { isOffline, offlineSchedule },
});

const loadChannel = (channelKey: string) => (dispatch, getState) => {
  const state: IStore = getState();

  if (state.branding.channel.key === channelKey) {
    return new Promise(((resolve) => {
      resolve(state.branding.channel);
    }));
  }

  return api.getChannel(channelKey)
    .then((channel) => {
      dispatch(setChannel(channel));

      return channel;
    });
};

const reLoadChannel = (channelKey: string) => (dispatch, getState) => {
  return api.getChannel(channelKey)
    .then((channel) => {
      dispatch(setChannel(channel));
      return channel;
    });
};

const getProduct = () => (dispatch, getState) => {
  const { branding } = getState();

  return branding.channel.products[0];
};

export const actions: IBrandingActions = {
  setLocale,
  setI18n,
  setChannel,
  loadChannel,
  reLoadChannel,
  getProduct,
  setIsOffline,
  setAggregatorTracking,
  setTrackingLink,
  setDefaultChannelKey,
};
