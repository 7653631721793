import axios from 'axios';
import { IStore } from '../../redux/IStore';
import { IErrorActions } from './models';
import { Endpoints } from '../../data/api/endpoints';
import { url } from '../../data/api/util';
import { get } from 'lodash';

export function storeClientError(error: string, info: string, locationPathName: string) {
  return (dispatch, getState) => {
    const state: IStore = getState();
    const data = {
      customerId: get(state , 'auth.customer.id', null),
      locationPathName,
      error: error ? error.toString() : null,
      info: info ? info.toString() : null,
    };
    return axios.post(
      url(Endpoints.PUBLIC_ERROR),
      { data },
      { headers: { 'Content-Type': 'application/vnd.api+json' } },
    ).then((response) => {
      return true;
    }).catch((err) => {
      return true;
    });
  };
}

export const errorActions: IErrorActions = {
  storeClientError,
};
