export const config = {
  env: process.env.NODE_ENV || 'local',
  host: process.env.HOST || '0.0.0.0',
  port: process.env.PORT || 3100,
  usePort: process.env.USE_PORT || false, // For dev use only
  karmaPort: 9876,

  apiUrl: process.env.API_URL || 'http://localhost:8080',
  defaultChannel: process.env.DEFAULT_CHANNEL_KEY,

  // This part goes to React-Helmet for Head of our HTML
  app: {
    head: {
      title: 'Trip Details',
      titleTemplate: 'Admiral Travel Insurance: %s',
      meta: [
        { charset: 'utf-8' },
        { 'http-equiv': 'x-ua-compatible', 'content': 'ie=edge' },
        { name: 'viewport', content: 'width=device-width, initial-scale=1' },
        { name: 'description', content: 'Travel Insurance' },
        { name: 'robots', content: 'noindex' },
      ],
    },
  },
};

export default config;
