import { IPersonalDetailsForm } from '../../../services/mta/models';
import { get } from 'lodash';

export function personalDetailsToJsonApi(formData: IPersonalDetailsForm) {

    // tslint:disable-next-line:variable-name
    const update_traveller_names = {
        adults: [],
        children: [],
    };

    formData.travellers.forEach((traveller, index) => {
        if (traveller.type === 'adult') {
            update_traveller_names.adults.push({
                title: traveller.title,
                first_name: traveller.firstName,
                last_name: traveller.lastName,
            });
        } else if (traveller.type === 'child') {
            update_traveller_names.children.push({
                title: traveller.title,
                first_name: traveller.firstName,
                last_name: traveller.lastName,
            });
        }
    });

    const attributes: any = {
        update_customer_phone1: get(formData.travellers[0], 'phone', ''),
        update_customer_email_address: get(formData.travellers[0], 'email', ''),
        update_customer_address: {
            postcode: get(formData.travellers[0], 'address.postcode', ''),
            line1: get(formData.travellers[0], 'address.line1', ''),
            line2: get(formData.travellers[0], 'address.line2', ''),
            line3: get(formData.travellers[0], 'address.line3', ''),
            town: get(formData.travellers[0], 'address.town', ''),
            county: get(formData.travellers[0], 'address.county', ''),
        },
        update_traveller_names,
    };

    const meta: any = {
        changes: attributes,
    };

    return { meta };
}
