import React = require('react');
import { Modal as BootstrapModal } from 'react-bootstrap/lib';
import { Button, ModalBody, ModalHeader, ModalTitle } from 'react-bootstrap';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../redux/IStore';
import { IModalActions, IModalState } from '../../../../../../services/modal/model';
import { bindActionCreators } from 'redux';
import { actions as modalActions } from '../../../../../../services/modal/reducer';

interface IOptionsModalProps {
  modal: IModalState
  actions: {
    modal: IModalActions
  }
}

const OptionsModal = ({ modal, actions }: IOptionsModalProps) => {

  const handleCloseModal = () => {
    actions.modal.closeOptionsModal()
  }

  const renderWinterSports = () => {
    return (
      <div>
        <div style={{ marginBottom: '10px' }}>Winter sports activities:</div>
        <ul>
          <li>Cross country skiing</li>
          <li>Dry slope skiing</li>
          <li>Dry slope snowboarding</li>
          <li>Glacier walking/trekking</li>
          <li>Husky sledge driving</li>
          <li>Ice hockey</li>
          <li>Off-piste skiing*</li>
          <li>Off-piste snowboarding*</li>
        </ul>

        <div style={{ marginTop: '10px' }}>
          *Cover for off-piste activities is restricted to recognised paths and within resort boundaries, accompanied by
          a qualified guide or instructor.
        </div>

        <div style={{ marginTop: '10px' }}>
          If your activity is not listed please call us and discuss this with an advisor to ensure you are correctly
          insured.
        </div>
      </div>
    )
  }

  const renderCruiseCover = () => {
    return (
      <div>
        <div style={{ marginBottom: '10px' }}>Cruise:</div>
        <div>
          A trip lasting more than 1 day where transport and accommodation are primarily on a sea going passenger ship.
        </div>
      </div>
    )
  }

  const renderBody = () => {
    if (modal?.optionsModal?.data === 'winter_sports') {
      return renderWinterSports()
    }

    if (modal?.optionsModal?.data === 'cruise_cover') {
      return renderCruiseCover()
    }
  }

  return (
    <>
      <BootstrapModal
        show={modal?.optionsModal?.opened}
        onHide={handleCloseModal}
        keyboard={false}
        backdrop="static"
      >
        <ModalHeader closeButton={true}>
          <ModalTitle>{'Option Details'}</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <div>{renderBody()}</div>
          <div style={{ margin: '10px 0 10px 0' }}>
            <Button
              bsStyle="primary"
              block={true}
              className={'documents-button'}
              onClick={handleCloseModal}
            >Close</Button>
          </div>

        </ModalBody>
      </BootstrapModal>
    </>
  )
}

export default connect(
  (state: IStore) => {
    return {
      modal: state.modal,
    };
  },
  (dispatch) => ({
    actions: {
      modal: bindActionCreators({ ...modalActions }, dispatch),

    },
  }))(OptionsModal);
