import * as React from 'react';
import { Panel, ProgressBar } from 'react-bootstrap';
import classNames from 'classnames';

class StrengthMeter extends React.Component<any, any> {

    private principleSatisfied = (principle) => {
        const { input } = this.props;
        return principle.predicate(input.value);
    }

    private principleClass = (principle) => {
        const satisfied = this.principleSatisfied(principle);

        return classNames({
            ['text-success']: satisfied,
            ['text-danger']: !satisfied,
        });
    }

    private progressColor = () => {
      const { satisfied } = this.props;
      const percentage = this.satisfiedPercent();
      percentage === 100 ? satisfied(true) : satisfied(false);

      return classNames({
            danger: (percentage < 33.4),
            success: (percentage >= 100),
            warning: (percentage >= 33.4 && percentage < 100),
        });
    }

    private satisfiedPercent = () => {
        const { principles, input} = this.props;
        const satisfiedCount = principles.map((p) => p.predicate(input.value))
            .reduce((count, satisfied) =>
                count + (satisfied ? 1 : 0), 0);

        const principlesCount = principles.length;
        return (satisfiedCount / principlesCount) * 100.0;
    }

    public render() {
        const { principles } = this.props;
        return (
            <Panel>
                <ProgressBar now={this.satisfiedPercent()} bsStyle={this.progressColor()}/>
                <h5>A good password is:</h5>
                <ul>
                    {principles.map((principle, key) =>
                        (<li key={key} className={this.principleClass(principle)}>
                                <small>
                                    {principle.label}
                                </small>
                            </li>
                        ))}
                </ul>
            </Panel>
        );
    }
}

export default StrengthMeter;
