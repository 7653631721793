import * as React from 'react';
import { connect } from 'react-redux';
import { flow, forEach, head, map } from 'lodash';
import { Col, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import { II18nMessages } from '../../../../../services/branding/models';
import {
  IChannel,
  IDestination,
  IPolicyApplication,
  IPremium,
  IProduct,
  IQuotes,
  ITraveller,
} from '../../../../../data/api/models';
import { formValueSelector, InjectedFormProps, submit } from 'redux-form';
import { IStore } from '../../../../../redux/IStore';
import { FormattedNumber } from 'react-intl';
import { ICompleteData, IQuoteState } from '../../../../../services/quote/models';
import { FaInfoCircle } from 'react-icons/fa/';
import { showDestinations, showRegions } from '../../../../../data/api/api';
import { bindActionCreators } from 'redux';
import { actions } from '../../../../../services/quote/reducer';
import { push } from 'react-router-redux';
import * as Commonmark from 'react-commonmark';
import { REGION_WORLDWIDE_EXCLUDING_LABEL_OLD } from '../../../index';
import BusinessContinuityPlan from '../../../../../helpers/BusinessContinuityPlan';
import MedicalImportantSection from '../../../scenes/QuoteSummary/MedicalImportantSection';
import AggBenefitsSection from '../AggBenefitsSection';

interface IAggQuoteSummaryProps extends InjectedFormProps {
  i18n: II18nMessages;
  policyApplication: IPolicyApplication;
  quote: IQuoteState;
  destinations: IDestination[];
  values: IPolicyApplication;
  product: IProduct;
  quotes: IQuotes;
  complete: ICompleteData;
  quoteReference: string;
  dispatch: any;
  actions: {
    submit(name: string);
    push: (route: string) => void;
    calculate: any;
  };
  isAggregator: boolean;
  channel: IChannel;
  permissions: boolean;
  quoteType: string;

  renderDefinition(name: string, useProvidedString?: string): any;
}

class AggQuoteSummary extends React.Component<IAggQuoteSummaryProps, any> {

  constructor(props) {
    super(props);
  }

  private renderTripType = () => {
    const { i18n, quotes, quoteReference } = this.props;
    const coverLevel = Object.keys(quotes)[0];
    const tripType = Object.keys(quotes[coverLevel]);

    return (
      <div className="summary-section">
        <Row>
          <Col xs={4} className="bold">
            {'Quote Reference'}
          </Col>
          <Col xs={8}>
            <div className="quote-info bold">
              {quoteReference}
            </div>
          </Col>
          <Col xs={4} className="bold">
            {i18n.quote.summary.policyType}
          </Col>
          <Col xs={8}>
            <div className="quote-info bold">
              {tripType} - <span className="text-capitalize">{coverLevel}</span>
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  private renderBenefitSection = () => {
    return (
      <AggBenefitsSection/>
    )
  }

  private renderTravelDate = () => {
    const { values } = this.props;

    let content = (
      <p>No dates entered</p>
    );

    if (values.startDate) {
      content = (
        <div>
          {values.startDate}
          {values.endDate ? ' - ' + values.endDate : ''}
        </div>
      );
    }

    const isSingleTrip = values.tripType === 'single';

    return (
      <div className="summary-section">
        <Row>
          <Col xs={4} className="bold">
            {isSingleTrip ? 'Travel Dates' : 'Cover Dates'}
          </Col>
          <Col xs={8}>
            <div className="quote-info bold">
              {content}
            </div>
          </Col>
          {!isSingleTrip && (
            <Col xs={12} style={{ textAlign: 'center' }}>
              You won't have Trip Cancellation cover until your cover start date.
            </Col>
          )}
        </Row>
      </div>
    );
  }

  private renderDestination = () => {
    const { values, product } = this.props;

    let content = (
      <div>Nothing Selected</div>
    );

    if (showDestinations(values.tripType) && values.destinations.length > 0 && values.destinations[0].length > 0) {
      content = (
        <div>
          {values.destinations.map((destination, key) => (<div key={key}>{destination}</div>))}
        </div>
      );
    }

    if (showRegions(values.tripType) && values.region) {
      let region = product.metadata.regions[values.region];
      if (values.region === 'worldwide_excluding' && this.props.policyApplication.createdBeforeFixedSchemeVersionRule) {
        region = REGION_WORLDWIDE_EXCLUDING_LABEL_OLD;
      }
      content = (
        <div>{region}</div>
      );
    }

    return (
      <>
        <div className={'summary-section'}>
          <Row>
            <Col xs={4} className="bold">
              {showDestinations(values.tripType) ?
                (values.destinations.length > 1 ? 'Destinations' : 'Destination') : 'Region'}
            </Col>
            <Col xs={8}>
              <div className="quote-info bold">
                {content}
              </div>
            </Col>
            {values.region === 'uk' && product.metadata.definitions.uk_region_only && (
              <Col xs={12} style={{ textAlign: 'center' }}>
                As your chosen region is UK only, cover for delayed or missed departures and for medical costs while
                abroad
                will not apply
              </Col>
            )}
          </Row>
        </div>
      </>
    );
  }

  private renderAdditionalOptions = () => {
    const { values, product } = this.props;

    const options = map(values.options, (value: any, key) => {
      const productOption = product.metadata.options[key];

      if (productOption && (productOption.type === 'boolean' || productOption.type === 'question') && value === true) {
        return (
          <div key={key}>{productOption.name}</div>
        );
      }

      if (productOption && productOption.type === 'list') {
        const item = productOption.items[value];

        const popover = (
          <Popover
            id={`popover-summary-${name}`}
          >
            {item}
          </Popover>
        );

        const info = (
          <OverlayTrigger
            trigger="click"
            rootClose={true}
            overlay={popover}
            placement="right"
            positionLeft={200}
            positionTop={50}
          >
            <FaInfoCircle className="pointer"/>
          </OverlayTrigger>
        );

        return (
          <div key={key}>
            {productOption.name} {info}
          </div>
        );
      }
    }).filter((option) => !!option);

    if (options.length === 0) {
      return null;
    }

    return (
      <div className="summary-section">
        <Row>
          <Col xs={4} className="bold">
            Additional {options.length > 1 ? 'Options' : 'Option'}
          </Col>
          <Col xs={8}>
            <div className="quote-info bold">
              {options}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  private renderTraveller = (type: string, display: string) => (traveller: ITraveller, key) => {
    let conditions = null;

    if (traveller.screening) {
      conditions = (
        <span className="traveller-conditions"><br/>{traveller.screening.meta.conditions.join(', ')}</span>
      );
    }

    return (
      <div key={key} className="sessioncamhidetext">
        {display} {key + 1}: <em>{traveller.title} {traveller.firstName} {traveller.lastName}</em> ({traveller.dob})
        {conditions}
      </div>
    );
  }

  private renderTravellers = () => {
    const { values, product } = this.props;
    const { travellers } = values;

    if (!travellers || !values.tripType) {
      return null;
    }

    const hasTravellerDeclaredCondition = travellers.some((traveller) => {
      return traveller.screening;
    });

    const adults = travellers.filter((traveller) => traveller.type === 'adult');
    const children = travellers.filter((traveller) => traveller.type === 'child');
    const groupTypes = product.metadata[`group_type_trip_${values.tripType}`];
    const total = adults.length + children.length;
    const leadTraveller = head(travellers);
    const premium = this.getPremium();

    let summaryMessage = `${adults.length} ${adults.length > 1 ? 'adults' : 'adult'}`;

    if (children.length) {
      summaryMessage += `, ${children.length} ${children.length > 1 ? 'children' : 'child'}`;
    }

    return (
      <div className="summary-section" style={{ padding: '15px 0' }}>
        <Row style={{ padding: '0 15px' }}>
          <Col xs={4} className="bold">
            {total > 1 ? 'Travellers' : 'Traveller'}
          </Col>
          <Col xs={8}>
            <div className="quote-info bold">
              <div>{groupTypes[values.groupType]} ({summaryMessage})</div>
              {adults.map(this.renderTraveller('adult', 'Adult'))}
              {children.map(this.renderTraveller('child', 'Child'))}
            </div>
          </Col>
        </Row>
        <Row style={{ padding: '0 15px' }}>
          <Col xs={4} className="bold">
            {'Email'}
          </Col>
          <Col xs={8}>
            <div className="quote-info bold">
              {leadTraveller?.email}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
  private getPremium = () => {
    const { quotes, values: { schemeId } } = this.props;

    let premium: IPremium = null;

    forEach(quotes, (tripTypes, level) => {
      forEach(tripTypes, (quotedPremium) => {
        if (!quotedPremium) {
          return null;
        }

        if (quotedPremium.scheme.id === schemeId) {
          premium = quotedPremium;
        }
      });
    });

    return premium;
  }

  public render() {
    const { values, product, complete, channel } = this.props;

    if (!values || !product) {
      return null;
    }

    const bcp = channel.bcp || null;
    const bcpMessage = BusinessContinuityPlan.getBCPMessage(bcp);

    return (
      <div>
        <Row>
          <Col sm={12}>
            <div className="section-title">
              <h2>{complete ? 'Your Policy Summary' : 'Your Quote Summary'}</h2>
              <Row>
                <Col xs={12}>
                  <div className="info-note primary-text">
                    Quotes are valid for 30 days.
                  </div>
                </Col>
              </Row>
            </div>
            <div className="section-group">
              {(bcpMessage) && (
                <div className={'section-important'}>
                  <div className={'important-idol'}>
                    <Commonmark source={bcpMessage}/>
                  </div>
                </div>
              )}

              {this.renderTripType()}
              {this.renderBenefitSection()}
              {this.renderTravelDate()}
              {this.renderDestination()}
              {this.renderAdditionalOptions()}
              {this.renderTravellers()}
            </div>
          </Col>
        </Row>
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const { quotes, complete } = state.quote;
      const values = selector(
        state,
        'destinations',
        'startDate',
        'endDate',
        'region',
        'tripType',
        'options',
        'travellers',
        'groupType',
        'schemeId',
        'quoteReference',
      );

      return {
        i18n: state.branding.i18n,
        policyApplication: state.quote.application,
        destinations: state.quote.destinations,
        values,
        quotes,
        complete,
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        submit: bindActionCreators(submit, dispatch),
        calculate: bindActionCreators(actions.calculate, dispatch),
        push: bindActionCreators(push, dispatch),
      },
    }),
  ),
])(AggQuoteSummary);
