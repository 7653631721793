import { ADMIRAL_LUCKY_TRIP_TRAVEL, ICustomer, ICustomerClaim, IPolicy } from '../models';
import { getIncludedResource } from '../util';
import * as application from './application';
import { filter, forEach, get, head } from 'lodash';
import * as customerPaymentMethod from './customerPaymentMethods';
import * as policy from './policy';
import { hasWaitingListExclusionOnly } from '../../../helpers/Quote';
import moment = require('moment');

export const POLICY_ACTIVE_STATUSES = ['on cover', 'active', 'issued', 'Purchased'];
export const POLICY_INACTIVE_STATUSES = ['cancelled', 'expired'];
export const EXCLUDED_QUOTE_CHANNELS = ['Instaquote', 'Admiral B2C Direct']
export const QUOTE_VALIDITY_DAYS = 31;

const getPoliciesByStatus = (policies: IPolicy[], statuses: string[]): IPolicy[] => {
  let policiesByStatus: IPolicy[] = [];
  if (policies && policies.length) {
    policiesByStatus = filter(
      policies,
      (policy: IPolicy) => {
        return (statuses.includes(policy.status));
      });
    return policiesByStatus;
  } else {
    return [];
  }
};

const hasReferrals = (quoteData: any, included: any, isRenewal: boolean = false) => {
  const premiums = getIncludedResource(quoteData, included, 'premiums', true);
  const schemeType = get(quoteData, 'attributes.metadata.scheme_type', '').toLowerCase();
  let shouldHideQuote = false;

  if (isRenewal && hasWaitingListExclusionOnly(get(head(premiums), 'attributes.metadata.referrals', []),
    get(quoteData, 'attributes.metadata.waiting_list_exclusion', false))
  ) {
    return false;
  }

  forEach(premiums, (premium) => {
    const premiumSchemeType = get(premium, 'attributes.metadata.scheme.scheme_type', '').toLowerCase();
    if (schemeType && premiumSchemeType && premiumSchemeType === schemeType) {
      if (get(premium, 'attributes.metadata.referrals', []).length > 0 ||
        get(premium, 'attributes.metadata.declines', []).length > 0 ||
        get(premium, 'attributes.metadata.errors', []).length > 0) {
        shouldHideQuote = true;
        return;
      }
    }
  });

  return shouldHideQuote;
};

const shouldShowApplication = (application, included) => {
  const originalQuoteDate = get(application, 'attributes.original_quote_date')
  const isValidQuote = moment().diff(moment(originalQuoteDate), 'days') < QUOTE_VALIDITY_DAYS
  return !get(application, 'attributes.metadata.aggregator', false) &&
    !hasReferrals(application, included) &&
    (!EXCLUDED_QUOTE_CHANNELS.includes(get(application, 'attributes.channel_name', ''))) &&
    (get(application, 'attributes.product_name', '') !== ADMIRAL_LUCKY_TRIP_TRAVEL) &&
    isValidQuote;
}

export function fromJsonApi(data: any, included: any[] = []): ICustomer {
  const policyApplications = getIncludedResource(data, included, 'quotes', true)
    .map((item) => {
      if (shouldShowApplication(item, included)) {
        return application.fromJsonApi(item, included);
      }
    }).filter((application) => !!application);

  const policies = getIncludedResource(data, included, 'policies', true)
    .map((item) => policy.fromJsonApi(item, included));

  const activePolicies = getPoliciesByStatus(policies, POLICY_ACTIVE_STATUSES);
  const expiredPolicies = getPoliciesByStatus(policies, POLICY_INACTIVE_STATUSES);

  const renewals = [
    ...getIncludedResource(data, included, 'renewals', true)
      .map((item) => {
        if (!hasReferrals(item, included, true)) {
          return application.fromJsonApi(item, included)
        }
      }).filter((application) => !!application),
    ...getIncludedResource(data, included, 'renewals_lapsed_by_customer', true)
      .map((item) => {
        if (!hasReferrals(item, included)) {
          return application.fromJsonApi(item, included)
        }
      }).filter((application) => !!application)
  ].filter((renewal) => {
    if (renewal.renewalStatus && renewal.renewalStatus !== 'lapse') {
      return renewal;
    }
  });

  const paymentMethods = getIncludedResource(data, included, 'payment_methods', true)
    .map((item) => {
      return customerPaymentMethod.fromJsonApi(item);
    }).filter((customerPaymentMethod) => !!customerPaymentMethod);

  return {
    id: data.id,
    title: data.attributes.title,
    firstName: data.attributes.first_name,
    lastName: data.attributes.last_name,
    companyName: data.attributes.company_name,
    customerName: data.attributes.customer_name,
    dob: data.attributes.dob,
    address: data.attributes.address,
    email: data.attributes.email,
    phone1: data.attributes.phone1,
    phone2: data.attributes.phone2,
    status: data.attributes.status,
    deceased: data.attributes.deceased,
    metadata: data.attributes.metadata,
    activePolicies,
    expiredPolicies,
    policyApplications,
    policies,
    renewals,
    marketingOptions: get(data, 'attributes.marketing_options'),
    paymentMethods,
  };
}

export function toJsonApi(customer: ICustomer) {
  const attributes: any = {
    first_name: customer.firstName,
    last_name: customer.lastName,
    address: customer.address,
    email: customer.email,
    phone1: customer.phone1,
    phone2: customer.phone2,
    password: customer.password,
    password_confirmation: customer.passwordConfirmation,
  };

  const data: any = {
    type: 'customer',
    attributes,
    relationships: {},
  };

  return { data };
}

export function customerClaimsIframeUrlFromJsonApi(data: any): string {
  return data.data.meta.url;
}

export function customerClaimsFromJsonApi(data: any): ICustomerClaim[] {
  const claims = data.data.meta.map((claim) => {
    return {
      claimNumber: claim.ClaimNumber,
      clients: claim.Clients ? claim.Clients.map((client) => {
        return {
          emailAddress: client.EmailAddress,
          title: client.Title,
          firstName: client.FirstName,
          lastName: client.LastName,
          homeTelephoneNumber: client.HomeTelephoneNumber,
          mobilePhoneNumber: client.MobilePhoneNumber,
          isContact: client.IsContact,
          address: client.Address ? {
            address1: client.Address.Address1,
            address2: client.Address.Address2,
            address3: client.Address.Address3,
            address4: client.Address.Address4,
            address5: client.Address.Address5,
            postcode: client.Address.PostCode,
          } : null,
        };
      }) : [],
      policyNumber: claim.PolicyNumber,
      tripStartDate: claim.TripStartDate,
      tripEndDate: claim.TripEndDate,
      tripBookingDate: claim.TripBookingDate,
      incidentDate: claim.IncidentDate,
      dateCreated: claim.DateCreated,
      dateClosed: claim.DateClosed,
      statusId: claim.StatusID,
      sections: claim.sections ? claim.sections.map((section) => {
        return {
          name: section.Name,
          limit: section.Limit,
          excess: section.Excess,
          status: section.Status,
          dateClosed: section.DateClosed,
          dateOpened: section.DateOpened,
        };
      }) : [],
    };
  });

  return claims;
}
