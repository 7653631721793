import * as React from 'react';
import { FaQuestionCircle, FaInfoCircle } from 'react-icons/fa';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as Commonmark from 'react-commonmark';

interface IDefinitionProps {
    name: string;
    definition: string;
    trigger?: any;
    iconType?: string;
}

class DefinitionPopup extends React.Component<IDefinitionProps, any> {
    public render() {
        const { name, definition, trigger= 'click', iconType } = this.props;
        let icon = <FaQuestionCircle className="info-btn"/>;

        if (!definition) {
            return null;
        }

        switch (iconType) {
          case 'info':
            icon = <FaInfoCircle className="info-btn"/>;
            break;
        }

        const popover = (
            <Popover id={`popover-${name}`}>
                <Commonmark source={definition}/>
            </Popover>
        );

        return (
            <OverlayTrigger
                trigger={trigger}
                rootClose={true}
                overlay={popover}
                placement="top">
                {icon}
            </OverlayTrigger>
        );
    }
}

export default DefinitionPopup;
