import * as React from 'react';
import { goodPasswordPrinciples } from '../../util/validators';
import StrengthMeter from './StrengthMeter';
import { FormControl, HelpBlock } from 'react-bootstrap';
import { IoMdEye, IoMdEyeOff } from 'react-icons/io';

class PasswordInput extends React.Component<any, any> {
  private validPassword: boolean;

  constructor(props) {
    super(props);

    this.validPassword = false;

    this.state = {
      hidden: true,
      showStrengthMeter: this.props.strengthMeter,
    };
    this.toggleShowPassword = this.toggleShowPassword.bind(this);
    this.handleSatisfied = this.handleSatisfied.bind(this);
  }

  private toggleShowPassword() {
    this.setState({ hidden: !this.state.hidden });
  }

  private handleSatisfied(value) {
    this.validPassword = value;
  }

  public render() {
    const {
      placeholder,
      input,
      type,
      componentClass,
      className = '',
      style,
      meta,
      mask,
      value,
      strengthMeter = false,
      complexValue = false,
      simpleValue = null,
      ...props
    } = this.props;

    const classes = className + (meta.dirty ? ' dirty-field' : '') + ' sessioncamhidetext';
    const onChange = input.onChange;
    const complexProps = { onChange, value: input.value };

    if (strengthMeter) {
      input.onFocus = () => input.value ? this.setState({ showStrengthMeter: true }) : null;
      (input.onBlur) = () => input.value ? this.validPassword ?
        this.setState({ showStrengthMeter: false }) : null : null;
    }

    return (
      <div>
        <div className={meta.touched && meta.error ? 'has-error input-with-icon' : 'input-with-icon'}>
          <FormControl
            style={style}
            type={this.state.hidden ? 'password' : 'text'}
            placeholder={placeholder}
            componentClass={componentClass}
            className={classes}
            {...input}
            {...props}
            {...complexProps}
          />
          {this.state.hidden && (
            <IoMdEye
              size={26}
              className="input-icon"
              onClick={this.toggleShowPassword}
            />
          )}
          {!this.state.hidden && (
            <IoMdEyeOff
              size={26}
              className="input-icon"
              onClick={this.toggleShowPassword}
            />
          )}
          {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
        </div>
        {strengthMeter && input.value && this.state.showStrengthMeter && (
          <StrengthMeter
            input={...input}
            principles={goodPasswordPrinciples()}
            satisfied={this.handleSatisfied}
          />
        )}
      </div>
    );
  }
}

export default PasswordInput;
