import * as React from 'react';
import { flow } from 'lodash';
import { formValueSelector, submit } from 'redux-form';
import { Button, Modal } from 'react-bootstrap';
import { connect } from 'react-redux';
import SaveQuoteModalForm from './components/SaveQuoteModalForm';
import { bindActionCreators } from 'redux';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { push } from 'react-router-redux';
import { actions as authActions, IAuthActions } from '../../../../services/auth/reducer';
import { IAuthState } from '../../../../services/auth/models';

interface ISaveQuoteModalProps {
  auth: IAuthState;
  quote: IQuoteState;
  password: string;
  passwordConfirmation: string;
  buttonText: string;
  actions: {
    submit(name: string);
    push(path: string);
    quote: IQuoteActions;
    auth: IAuthActions;
  };
  disabled?: boolean;
}

interface ISaveQuoteModalStates {
  showSaveQuoteModal: boolean;
  isQuoteSaved: boolean;
  submissionErrors: any;
}

class SaveQuoteModal extends React.Component<ISaveQuoteModalProps, ISaveQuoteModalStates> {
  public state = {
    showSaveQuoteModal: false,
    isQuoteSaved: false,
    submissionErrors: [],
  };

  public static defaultProps = {
    disabled: false,
  };

  private openSaveQuoteModal = () => {
    this.setState({
      showSaveQuoteModal: true,
    });
  }

  private handleSubmit = ({password, passwordConfirmation}) => {
    const { actions, change, dispatch, auth, quote } = this.props;

    dispatch(change('createAccount', true));
    dispatch(change('generateDocuments', true));
    dispatch(change('emailSend', true));

    actions.quote.setPassword(password, passwordConfirmation);

    if (quote.isRetrievingQuote) {
      actions.quote.saveQuote(true);

      this.setState({
        isQuoteSaved: true,
      });
    }

    if (!quote.isRetrievingQuote) {
      Promise.resolve(actions.quote.submitForm(null, true, true)).then((response) => {
        if (response && response.data && response.data.errors) {
          this.setState({
            submissionErrors: response.data.errors,
          });
        } else {
          this.setState({
            isQuoteSaved: true,
            submissionErrors: [],
          });
        }
      });
    }
  }

  private handleCloseModal = () => {
    const { dispatch, change } = this.props;

    this.setState({
      showSaveQuoteModal: false,
    });

    dispatch(change('generateDocuments', false));
    dispatch(change('emailSend', false));
  }

  private handleGoToMyAccount = () => {
    const { dispatch, change } = this.props;

    dispatch(change('generateDocuments', false));
    dispatch(change('emailSend', false));
    window.location.href = '/auth/login';
  }

  public render() {
    const { quote, password, passwordConfirmation, buttonText } = this.props;
    const { showSaveQuoteModal, isQuoteSaved } = this.state;
    const customer = quote.customer;

    const disabled = ! customer || this.props.disabled;

    return (
      <div>
        <Button
          bsStyle="primary"
          type="button"
          bsSize="lg"
          className="save-quote-btn"
          onClick={this.openSaveQuoteModal}
          disabled={disabled}
        >
          {buttonText ? buttonText : 'SAVE QUOTE'}
        </Button>

        <Modal show={showSaveQuoteModal} onHide={this.handleCloseModal} backdrop={'static'}>
          <Modal.Header closeButton={true}>
            <Modal.Title>Save Your Quote</Modal.Title>
          </Modal.Header>
          <SaveQuoteModalForm
            onSubmit={this.handleSubmit}
            initialValues={{ password, passwordConfirmation }}
            onCloseModal={this.handleCloseModal}
            onGoToMyAccount={this.handleGoToMyAccount}
            isQuoteSaved={isQuoteSaved}
            submissionErrors={this.state.submissionErrors}
          />
        </Modal>
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state) => {
      const password = selector(state, 'password');
      const passwordConfirmation =  selector(state, 'passwordConfirmation');

      return {
        auth: state.auth,
        quote: state.quote,
        password,
        passwordConfirmation,
      };
    },
    (dispatch) => ({
      actions: {
        push: bindActionCreators(push, dispatch),
        submit: bindActionCreators(submit, dispatch),
        auth: bindActionCreators({ ...authActions }, dispatch),
        quote: bindActionCreators({...quoteActions}, dispatch),
      },
    }),
  ),
])(SaveQuoteModal);
