import * as React from 'react';
import { Field, InjectedFormProps } from 'redux-form';
import { Col, Row } from 'react-bootstrap';
import FieldFormControl from '../../../../../../components/FieldFormControl';
import { isRequired } from '../../../../../../util/validators';

interface IYourRenewal extends InjectedFormProps {
  formPath: string;
  defaultSelected: string;
  allowAutoRenewal: boolean;
  dispatch: any;
  change: any;
  renewalStatus: string;
  className?: string;
  ignorePermissions?: boolean;

  renderDefinition(name?: string, useProvidedString?: string): any;
}

export enum renewalTypeSelectionEnum {
  AUTO = 'auto',
  MANUAL = 'manual'
}

interface IYourRenewalState {
  showRenewalOptions: boolean,
}

class YourRenewal extends React.Component<IYourRenewal, IYourRenewalState> {

  constructor(props) {
    super(props);
    const { dispatch, defaultSelected, formPath, change } = props;
    this.state = {
      showRenewalOptions: false,
    }

    if (defaultSelected) {
      dispatch(change(formPath, defaultSelected))
    }
  }

  private handleOnLinkClick = () => () => {
    this.setState({ showRenewalOptions: !this.state.showRenewalOptions })
  }

  public render() {
    const { formPath, allowAutoRenewal, renderDefinition, renewalStatus, className, ignorePermissions } = this.props;
    const { showRenewalOptions } = this.state;
    const isRenewalManual = renewalStatus && renewalStatus === renewalTypeSelectionEnum.MANUAL;

    return (
      <div>
        <div className={'section-group-content ' + className}>
          <p className={'normal'} style={{ fontSize: '15px'}}>
            Your Renewal: To keep you protected your policy is set to renew automatically using the payment details you
            provide. You can change your auto-renewal preference at anytime during your policy term by contacting us by
            phone, online or now by clicking <a onClick={this.handleOnLinkClick()}
                                                style={{ cursor: 'pointer' }}>here</a>.
          </p>
        </div>
        {showRenewalOptions && (
          <>
            <div className={'notes-your-renewal'}>
              <p>Before automatically renewing we will send you a renewal notice in advance so that you can review the
                new premium and cover details and have an opportunity to shop around. If you don't wish to accept the
                renewal offer at that time just let us know then and we can make sure no payment is taken.
              </p>
            </div>
            <Row className="selection-your-renewal">
              <Col sm={12} className={'send-documents-box'} style={{ paddingBottom: '15px' }}>
                <div className="section">
                  <Row className="billing">
                    <Col xs={6} className={'radio-group margin-top'}>
                      <div className={'option'}>
                        <div className="radio-button">
                          <Field
                            name={formPath}
                            type="radio"
                            value={'auto'}
                            complexValue={true}
                            component={FieldFormControl}
                            validate={[isRequired]}
                            disabled={!allowAutoRenewal && !ignorePermissions}
                          >
                            <div/>
                          </Field>
                        </div>
                        <div className="radio-text">
                          <span
                            style={!allowAutoRenewal && !ignorePermissions ? { opacity: '0.7' } : { opacity: '1' }}>{'Auto-renewal on'}</span>
                          {!allowAutoRenewal && !ignorePermissions ? renderDefinition('Renewal Type', 'We need to save your card details in order to auto-renew') : ''}
                        </div>
                      </div>
                    </Col>
                    <Col xs={6} className={'radio-group margin-top'}>
                      <div className={'option'}>
                        <div className="radio-button">
                          <Field
                            name={formPath}
                            type="radio"
                            value={'manual'}
                            complexValue={true}
                            component={FieldFormControl}
                            validate={[isRequired]}
                          >
                            <div/>
                          </Field>
                        </div>
                        <div className="radio-text">
                          {'Auto-renewal off'}
                        </div>
                      </div>
                    </Col>
                  </Row>
                  {isRenewalManual && (
                    <div style={{ padding: '0', fontSize: '15px' }}>
                      By removing the auto-renewal feature your cover will end on your renewal date, even if you are on
                      a trip. This could leave you without cover for emergency medical expenses or unable to make a
                      claim should you need to cancel or cut short your trip.
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </>
        )}
      </div>
    )
  }

}

export default YourRenewal;
