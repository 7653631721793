import * as React from 'react';
import { IBrandingState } from '../../services/branding/models';
import { connect } from 'react-redux';
import { flow } from 'lodash';

interface IOfflineProps {
  branding: IBrandingState;
}

class Offline extends React.Component<IOfflineProps> {
  public render() {
    const { branding } = this.props;

    return (
      <div className="container offline-message-container">
        <h1>{branding.offlineSchedule.message}</h1>
      </div>
    );
  }
}

export default flow([
  connect(
    (state) => {
      return {
        branding: state.branding,
      };
    },
  ),
])(Offline);
