import * as React from 'react';
import { Pulse } from 'better-react-spinkit';

class Loading extends React.Component<any, any> {
  public render() {
    return <Pulse className="loading-icon" style={{maxWidth: 25}} size={25} />;
  }
}

export default Loading;
