import * as React from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FieldFormControl from '../FieldFormControl';
import { IChannel, ITraveller } from '../../data/api/models';
import BillingAddress from '../BillingAddress';
import { getTravellerAddressString } from '../../helpers/Travellers';
import { II18nMessages } from '../../services/branding/models';
import SelectControl from '../SelectControl';
import { isRequired } from '../../util/validators';
import { IMtaActions, IMtaFormNames } from '../../services/mta/models';
import Loading from '../Loading';
import { get, head } from 'lodash';
import YourRenewal, { renewalTypeSelectionEnum } from '../../scenes/Quote/scenes/Payment/components/YourRenewal';

interface IConsentAndIntent {
  permissions: boolean;
  leadTraveller: ITraveller;
  travellers?: ITraveller[];
  i18n: II18nMessages;
  change: any;
  channel: IChannel;
  dispatch: any;
  reuseCustomerAddress: boolean;
  isMta: boolean;
  mtaIsLoading?: boolean;
  actions?: {
    mta: IMtaActions;
    change: (form, field, value) => void;
  };
  paymentMethod?: {
    maskedPAN: string,
    id: string
  },
  showRenewal?: boolean,
  renewalStatus?: string,
  hidePermissions?: boolean,
  ignorePermissions?: boolean

  renderDefinition?(name: string, useProvidedString?: string): any;

  showSectionTitle?: boolean;
}

class ConsentAndIntent extends React.Component<IConsentAndIntent, any> {

  constructor(props) {
    super(props);

    this.state = {
      customerPaymentCardSelection: [],
      isLoadingPaymentMethods: true,
      reusePaymentCard: null,
      showSaveCard: false,
    }
  }

  public static defaultProps = {
    showSectionTitle: true,
  };

  componentDidMount() {
    const { dispatch, change, permissions, paymentMethod } = this.props;
    dispatch(change('permissions', permissions));

    if (paymentMethod && !Array.isArray(paymentMethod) && !this.state.customerPaymentCardSelection.length) {
      this.setPaymentMethod(paymentMethod);
    }
  }

  componentDidUpdate(prevProps: Readonly<IConsentAndIntent>, prevState: Readonly<any>, snapshot?: any) {
    const { paymentMethod } = this.props;
    if (prevProps.paymentMethod !== paymentMethod) {
      if (paymentMethod && !Array.isArray(paymentMethod)) {
        this.setPaymentMethod(paymentMethod);
      }
    }
  }

  public renderBillingAddress = (leadTraveller: ITraveller) => {
    const {
      i18n,
      change,
      channel,
      dispatch,
      reuseCustomerAddress,
    } = this.props;

    return (
      <BillingAddress
        i18n={i18n}
        channel={channel}
        change={change}
        dispatch={dispatch}
        formBasePath={'billing'}
        reuseCustomerAddress={reuseCustomerAddress}
        travellerAddress={getTravellerAddressString(leadTraveller)}
        leadTraveller={leadTraveller}
      />
    );
  }

  private handleYesNoQuestion = (answer: string) => () => {
    const { actions } = this.props;
    if (answer === 'false') {
      actions.change(IMtaFormNames.COVER_DETAILS, 'existing_payment_method_id', null);
    }
    this.setState({ reusePaymentCard: answer });
  }

  public renderButtons = () => {
    return (
      <div style={{ marginBottom: '10px' }}>
        <h4 style={{ marginTop: '0px' }}><strong>Do you want to reuse a card</strong></h4>
        <div className="btn-bar question-buttons">
          <Button
            bsStyle="primary"
            bsSize="medium"
            className="pull-left"
            onClick={this.handleYesNoQuestion('true')}
            disabled={false}
          >
            YES
          </Button>
          <Button
            bsStyle="primary"
            bsSize="medium"
            id="no-button"
            className="pull-left"
            onClick={this.handleYesNoQuestion('false')}
            disabled={false}
          >
            NO
          </Button>
        </div>
      </div>
    )
  }

  private setPaymentMethod = (paymentMethod) => {
    const transformed = [];
    transformed.push({ label: paymentMethod.maskedPAN, value: paymentMethod.id })
    this.setState({ customerPaymentCardSelection: transformed })
  }

  private handleOnLinkClick = () => () => {
    this.setState({ showSaveCard: !this.state.showSaveCard })
  }

  private renderAddNewCard = () => {
    const {
      leadTraveller,
      travellers,
      showRenewal = false,
      renderDefinition,
      permissions,
      hidePermissions,
      change,
      dispatch,
      renewalStatus,
      showSectionTitle,
      ignorePermissions = false
    } = this.props;

    const leadTravellerData = get(leadTraveller, 'address') ? leadTraveller : travellers && travellers.length ? head(travellers) : null;

    return (
      <>
        {showSectionTitle && (
          <div className="section-title"/>
        )}

        <div className="section-group">
          {!hidePermissions && (
            <>
              <p style={{ padding: '20px 20px 0 20px', fontSize: '15px', fontWeight: 'normal' }}>
                Payments: We will securely save your payment card so payments on your policy can be handled quicker and
                easier– this
                includes any future changes including policy renewal. You can cancel this by contacting us at any time
                or
                clicking <a onClick={this.handleOnLinkClick()} style={{ cursor: 'pointer' }}>here</a>.
              </p>
              {this.state.showSaveCard && (
                <>
                  <p style={{ padding: '20px 20px 0 20px', fontSize: '15px' }}>We store card details securely and they
                    are
                    encrypted against your policy allowing us to take any future payments quickly and easily</p>
                  <Row className="billing">
                    <Col sm={12} className={'send-documents-box'} style={{ paddingBottom: '5px' }}>
                      <div className="section">
                        <Row className="billing">
                          <Col xs={6} className={'radio-group margin-top'}>
                            <div className={'option'}>
                              <div className="radio-button">
                                <Field
                                  name="permissions"
                                  type="radio"
                                  value={true}
                                  complexValue={true}
                                  component={FieldFormControl}
                                  validate={[isRequired]}
                                >
                                  <div/>
                                </Field>
                              </div>
                              <div className="radio-text">
                                {'Save the card details'}
                              </div>
                            </div>
                          </Col>
                          <Col xs={6} className={'radio-group margin-top'}>
                            <div className={'option'}>
                              <div className="radio-button">
                                <Field
                                  name="permissions"
                                  type="radio"
                                  value={false}
                                  complexValue={true}
                                  component={FieldFormControl}
                                  validate={[isRequired]}
                                >
                                  <div/>
                                </Field>
                              </div>
                              <div className="radio-text">
                                {'Do not save the card details'}
                              </div>
                            </div>
                          </Col>
                          {!permissions && (
                            <>
                              <div style={{ padding: '0 20px 0 20px' }}>You will need to give us your payment details
                                every time you make a change or when you’d like to renew with us
                              </div>
                            </>
                          )}
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </>
              )}
            </>
          )}
          {showRenewal && (
            <YourRenewal
              formPath={'renewalStatus'}
              renderDefinition={renderDefinition}
              ignorePermissions={ignorePermissions}
              allowAutoRenewal={permissions}
              defaultSelected={renewalTypeSelectionEnum.AUTO}
              change={change}
              renewalStatus={renewalStatus}
              dispatch={dispatch}/>
          )}
          <div>
            {leadTravellerData && (
              <>
                {this.renderBillingAddress(leadTravellerData)}
              </>
            )}
          </div>
        </div>
      </>
    )
  }

  private renderReuseCardSegment = () => {
    return (
      <>
        <h4>Select a Card</h4>
        <Row className="dynamic-row">
          <Col xs={12}>
            <Field
              name={'existing_payment_method_id'}
              component={SelectControl}
              clearable={false}
              options={this.state.customerPaymentCardSelection}
              validate={[isRequired]}
            />
          </Col>
        </Row>
      </>
    )
  }

  public render() {
    const { isMta, mtaIsLoading } = this.props;

    if (isMta && mtaIsLoading) {
      return (<Loading/>)
    }

    return (
      <>
        {this.state.customerPaymentCardSelection.length > 0 && isMta && (
          <>
            {this.renderButtons()}
            {this.state.reusePaymentCard === 'false' && (
              <>
                {this.renderAddNewCard()}
              </>
            )}
            {this.state.reusePaymentCard === 'true' && (
              <>
                {this.renderReuseCardSegment()}
              </>
            )}
          </>
        )}
        {(!isMta || this.state.customerPaymentCardSelection.length === 0) && (
          <>
            {this.renderAddNewCard()}
          </>
        )}
      </>
    );
  }

}

export default (ConsentAndIntent);
