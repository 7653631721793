import * as React from 'react';
import { Checkbox, FormControl, HelpBlock, Radio } from 'react-bootstrap';
import * as Cleave from 'cleave.js/react';
import { CleaveOptions } from 'cleave.js/options';
// Work around the module being used in CJS and ES6 module formats.
// One module exports with a default, the other doesn't...
let MaskedInput = require('react-text-mask');

if (MaskedInput.default) {
  MaskedInput = MaskedInput.default;
}

const FieldFormControl = ({
                            placeholder,
                            type,
                            input,
                            componentClass,
                            className = '',
                            style,
                            meta,
                            mask,
                            value,
                            validationMessage,
                            complexValue = false,
                            simpleValue = null,
                            cleaveOptions = null,
                            label = '',
                            ...props
                          }) => {
  const classes = className + (meta.dirty ? ' dirty-field' : '') + ' sessioncamhidetext';
  let component = null;
  let onBlur = input.onBlur;
  let onChange = input.onChange;

  if (complexValue) {
    onBlur = () => input.onBlur();
    onChange = () => input.onChange(input.value);
  }

  const complexProps = { onBlur, onChange, value: input.value };

  // if (complexValue) {
  //   complexProps.value = simpleValue;
  // }

  let options: CleaveOptions = null;

  switch (type) {
    case 'masked-date':
      options = {
        date: true,
        datePattern: ['d', 'm', 'Y'],
      };

      component = (
        <Cleave
          style={style}
          className={classes + ' form-control'}
          placeholder={placeholder}
          options={options}
          {...input}
          {...props}
        />
      );
      break;
    case 'expiry-date':
      options = {
        date: true,
        datePattern: ['m', 'y'],
      };

      component = (
        <Cleave
          style={style}
          className={classes + ' form-control'}
          placeholder={placeholder}
          options={options}
          {...input}
          {...props}
        />
      );
      break;
    case 'cleave':
      component = (
        <Cleave
          style={style}
          className={classes + ' form-control'}
          placeholder={placeholder}
          options={cleaveOptions}
          {...input}
          {...props}
        />
      );
      break;
    case 'checkbox':
      component = (
        <>
          <Checkbox
            style={style}
            className={classes}
            {...input} {...props} {...complexProps}
          />
        </>
      );
      break;
    case 'radio':
      component =
        // tslint:disable-next-line:jsx-wrap-multiline
        <Radio
          style={style}
          className={classes}
          value={value}
          {...input} {...props}
          {...complexProps}
        />;
      break;
    default:
      component = (
        <FormControl
          style={style}
          type={type}
          placeholder={placeholder}
          componentClass={componentClass}
          className={classes}
          {...input}
          {...props}
          {...complexProps}
        />
      );
      break;
  }

  // meta.error = 'Error test';

  return (
    <div className={meta.touched && meta.error ? 'has-error' : ''}>
      {component}
      {validationMessage !== 'single' && (
        <>
          {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
        </>
      )}
    </div>
  );
};

export default FieldFormControl;
