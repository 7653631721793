import React = require('react');
import { map } from 'lodash';

export const OptionsYesSelectionText = ({ text }) => {
  const icon = require('../../../AdditionalCover/img/blue-tick.svg');
  return (
    <div>
      <div style={{ paddingLeft: '35px' }}>This will provide cover for:</div>
      {map(text, (data) => {
        return (
          <div>
              <span style={{ paddingRight: '10px' }}>
                <img src={icon} alt={''}/>
              </span>{data}
          </div>
        )
      })}
    </div>
  )
}
