import * as React from 'react';
import { setAnswer } from '../../../../../../services/verisk/reducer';
import { getIncludedResource } from '../../../../../../data/api/util';
import { Radio } from 'react-bootstrap';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit';

export default class BooleanQuestion extends React.Component<any, any> {
  public state = {
    answer: '',
  };

  public componentWillReceiveProps(props) {
    const options = getIncludedResource(props.question, props.screening.declaration.included, 'options', true);

    options.map((option) => {
      if (option.attributes.isSelected && this.state.answer !== option.attributes.number) {
        this.setState({ answer: option.attributes.number });
      }
    });
  }

  componentDidUpdate() {
    const element = document.getElementById('active-boolean-question');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private handleRadioChange = (e) => {
    const { condition, question, dispatch } = this.props;
    if (e.target === undefined) {
      return;
    }
    const answer = e.target.value;
    this.setState({ answer });
    dispatch(setAnswer(condition, question, { value: answer }));
  }

  public render() {
    const { question, screening, dispatch, condition } = this.props;
    const { answer } = this.state;
    const optionsResource = getIncludedResource(question, screening.declaration.included, 'options', true);
    const selectedAnswers = getIncludedResource(question, screening.declaration.included, 'selected', true);
    const isActiveQuestion = question?.attributes?.isActive || false;

    return (
      <>
        <div className="row form-horizontal question dynamic-row">
          <div className="col-xs-12">
            <label className="control-label">{question.attributes.text}</label>
            {question.attributes.info ? (
              <p className="text-info" style={{ fontSize: '14px' }}>
                {question.attributes.info}
                <br/>
              </p>
            ) : ''}
            {question.attributes.warning ? (
              <p className="text-danger" style={{ fontSize: '14px' }}>
                {question.attributes.warning}
                <br/>
              </p>
            ) : ''}

            {!isActiveQuestion && (
              <ConditionQuestionEdit
                selectedAnswers={selectedAnswers}
                screening={screening}
                dispatch={dispatch}
                condition={condition}
                question={question}
              />
            )}

            {isActiveQuestion && optionsResource.length > 0 && (
              <div className="screening-radio-question" id="active-boolean-question">
                {optionsResource.map((option, i) => {
                  const inputId = option.id;
                  const optionAnswer = question.attributes.warning ? '' : answer;

                  return (
                    <div key={i}>
                      <Radio
                        id={inputId}
                        value={option.attributes.number}
                        onChange={this.handleRadioChange}
                        checked={optionAnswer === option.attributes.number}
                      >
                        <div className="btn-quote-option"/>
                        {option.attributes.text}
                      </Radio>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </div>
        <div className={'screening-border-bottom'}/>
      </>
    );
  }
}
