import * as React from 'react';
import { Field } from 'redux-form';
import { II18nMessages } from '../../../../../../services/branding/models';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import SelectControl from '../../../../../../components/SelectControl';
import { FaTrashAlt } from 'react-icons/fa';
import { IDestination } from '../../../../../../data/api/models';
import { isRequired } from '../../../../../../util/validators';
import { filter } from 'lodash';

interface ITripDetailsForm {
  i18n: II18nMessages;
  destinations: IDestination[];
  destinationsValue: string[];
  renderDefinition(name: string): any;
  placeholder?: string;
  pristine: boolean;
  fields: any;
  mta?: boolean;
  isLoading?: boolean;
}

class Destination extends React.Component<ITripDetailsForm> {
  private addField = () => {
    const { fields } = this.props;

    fields.push('');
  }

  private deleteField = (index) => () => {
    const { fields } = this.props;

    fields.remove(index);
  }

  private renderFields() {
    const { fields, destinations, destinationsValue, mta = false, isLoading = false, placeholder = 'Select...' } = this.props;

    const destinationsListFiltered = filter(destinations, (destination) => destination.included === true);

    const destinationsList = destinationsListFiltered.map((destination) => ({
      label: destination.name,
      value: destination.name,
      disabled: destinationsValue.indexOf(destination.name) > -1,
    }));

    return fields.map((destination, index) => {
      const columns = {
        first: {
          field: {
            xs: 12,
          },
          btn: {
            xs: 0,
            className: 'hidden',
          },
        },
        normal: {
          field: {
            xs: 9,
            sm: 10,
            md: 10,
            lg: 10,
          },
          btn: {
            xs: 3,
            sm: 2,
            md: 2,
            lg: 2,
          },
        },
      };
      const colConfig = columns[index === 0 ? 'first' : 'normal'];

      return (
        <Row key={index} className={mta ? 'dynamic-row' : ''}>
          <Col {...colConfig.field}>
            <Field
              name={destination}
              options={destinationsList}
              placeholder={placeholder}
              component={SelectControl}
              forceFilter={true}
              validate={[isRequired]}
              disabled={isLoading}
            />
          </Col>
          <Col {...colConfig.btn}>
            <Button disabled={isLoading} bsStyle="link" onClick={this.deleteField(index)}>
              <FaTrashAlt />
            </Button>
          </Col>
        </Row>
      );
    });
  }

  public componentWillMount() {
    if (this.props.fields.length === 0) {
      this.addField();
    }
  }

  public render() {
    const { i18n, renderDefinition, mta = false, isLoading = false } = this.props;

    return (
      <div className={mta ? '' : 'section'}>
        {'Please select or type your updated destination below'} {renderDefinition('destinations')}
        <Row className={mta ? 'dynamic-row margin-top' :  'margin-top'}>
          <Col sm={12}>
            <FormGroup controlId="destination">
              <Col sm={12} className="destinations-inputs">
                {this.renderFields()}
              </Col>
            </FormGroup>
          </Col>
        </Row>
        <Button disabled={isLoading} bsStyle="primary" onClick={this.addField}>
          {i18n.quote.tripDetails.addCountry}
        </Button>
      </div>
    );
  }
}

export default Destination;
