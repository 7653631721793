import * as React from 'react';
import Select from 'react-select';
import { setAnswer } from '../../../../../../services/verisk/reducer';
import { getIncludedResource } from '../../../../../../data/api/util';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit';

export default class SingleChoiceQuestion extends React.Component<any, any> {
  public state = {
    answer: '',
  };

  public componentWillReceiveProps(props) {
    const options = getIncludedResource(props.question, props.screening.declaration.included, 'options', true);

    options.map((option) => {

      if (option.attributes.isSelected && this.state.answer !== option.attributes.number) {
        this.setState({ answer: option.attributes.number });
      }
    });
  }

  componentDidUpdate() {
    const element = document.getElementById('active-single-question');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private handleSetAnswer = (answer) => {
    const { condition, question, dispatch } = this.props;

    dispatch(setAnswer(condition, question, answer));

    this.setState({ answer });
  }

  public render() {
    const { index, screening, question, dispatch, condition } = this.props;
    const { answer } = this.state;
    const optionsResource = getIncludedResource(question, screening.declaration.included, 'options', true);
    const selectedAnswers = getIncludedResource(question, screening.declaration.included, 'selected', true);
    const isActiveQuestion = question?.attributes?.isActive || false;

    const options = optionsResource.map((option) => {
      return {
        value: option.attributes.number,
        label: option.attributes.text,
      };
    });

    return (
      <>
        <div className="single-choice-question">
          <div className="form-group">
            <label className="control-label">{question.attributes.text}</label>
            {question.attributes.info ? (
              <p className="text-info" style={{ fontSize: '14px' }}>
                {question.attributes.info}
                <br/>
              </p>
            ) : ''}
            {question.attributes.warning ? (
              <p className="text-danger" style={{ fontSize: '14px' }}>
                {question.attributes.warning}
                <br/>
              </p>
            ) : ''}

            {!isActiveQuestion && (
              <ConditionQuestionEdit
                selectedAnswers={selectedAnswers}
                screening={screening}
                dispatch={dispatch}
                condition={condition}
                question={question}
              />
            )}

            {isActiveQuestion && (
              <Select
                name={'single-choice-question-' + index}
                id={'active-single-question'}
                options={options}
                value={answer}
                clearable={false}
                onChange={this.handleSetAnswer}
              />
            )}
          </div>
        </div>
        <div className={'screening-border-bottom'}/>
      </>
    );
  }
}
