import * as React from 'react';
import * as Commonmark from 'react-commonmark';

export const WarningMessages = ({ warningMessage }) => {

  if (!warningMessage?.warning) {
    return null;
  }

  return (
    <div>
      <div style={{
        backgroundColor: '#FDE5E5FF',
        padding: '10px',
        margin: '10px 0 10px 0',
      }}>
        <div>There has been an issue with a condition below, please review the declaration again:
        </div>
        <div style={{ color: 'red', fontSize: '12px' }}>
          <Commonmark source={warningMessage.warning}/>
        </div>
      </div>
    </div>
  )
}
