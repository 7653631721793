import React = require('react');
import { CoverForCovidPanel } from '../CoverForCovidPanel';
import { MentalHealthPanel } from '../MentalHealthPanel';
import { CancellationPanel } from '../CancellationPanel';
import { FlightCancelledPanel } from '../FlightCancelledPanel';

export const PaymentYourCover = ({ premium, shouldHideCancellation }) => {

  return (
    <div>
      <div className="section-title">
        <h2>{'Your Cover'}</h2>
      </div>

      <div style={{ margin: '10px 0 10px 0' }}>
        <CoverForCovidPanel/>
      </div>

      <div style={{ margin: '0 0 10px 0' }}>
        <MentalHealthPanel/>
      </div>

      <div style={{ margin: '0 0 10px 0' }}>
        <FlightCancelledPanel premium={premium}/>
      </div>

      <div style={{ margin: '0 0 10px 0' }}>
        {!shouldHideCancellation && (
          <CancellationPanel premium={premium}/>
        )}
      </div>
    </div>

  )
}
