import { IHeaderAction, IHeaderActions, IHeaderState } from './models';

const OPEN_DRAWER = 'OPEN_DRAWER';
const CLOSE_DRAWER = 'CLOSE_DRAWER';
const OPEN_CONTACT_US_MODAL = 'OPEN_CONTACT_US_MODAL';
const CLOSE_CONTACT_US_MODAL = 'CLOSE_CONTACT_US_MODAL';

const initialState: IHeaderState = {
  isDrawerOpened: false,
  isContactUsModalOpened: false,
};

export const reducer = (state: IHeaderState = initialState, action: IHeaderAction) => {
  switch (action.type) {
    case OPEN_DRAWER:
      return {
        ...state,
        isDrawerOpened: true,
      };

    case CLOSE_DRAWER:
      return {
        ...state,
        isDrawerOpened: false,
      };

    case OPEN_CONTACT_US_MODAL:
      return {
        ...state,
        isContactUsModalOpened: true,
      };
    case CLOSE_CONTACT_US_MODAL:
      return {
        ...state,
        isContactUsModalOpened: false,
      };
    default:
      return state;
  }
};

const openDrawer = (): IHeaderAction => ({ type: OPEN_DRAWER });
const closeDrawer = (): IHeaderAction => ({ type: CLOSE_DRAWER });

const openContactUsModal = (): IHeaderAction => ({ type: OPEN_CONTACT_US_MODAL });
const closeContactUsModal = (): IHeaderAction => ({ type: CLOSE_CONTACT_US_MODAL });

export const actions: IHeaderActions = {
  openDrawer,
  closeDrawer,
  openContactUsModal,
  closeContactUsModal,
};
