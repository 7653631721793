import * as React from 'react';
import { withRouter } from 'react-router';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { IStore } from '../../redux/IStore';
import { bindActionCreators } from 'redux';
import { errorActions } from '../../services/error/actions';
import { IAuthState } from '../../services/auth/models';
import { IErrorActions } from '../../services/error/models';

interface IErrorBoundaryProps {
  auth: IAuthState;
  location: any;
  actions: {
    error: IErrorActions;
  };
}

interface IErrorBoundaryState {
  hasError: boolean;
}

class ErrorBoundary extends React.Component<IErrorBoundaryProps, IErrorBoundaryState> {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  public componentDidUpdate(prevProps: Readonly<any>, prevState: Readonly<any>, snapshot?: any) {
    if (prevProps.location.pathname !== this.props.location.pathname) {
      this.setState({ hasError: false });
    }
  }

  public componentDidCatch(error, info) {
    // DISABLE STORING ERRORS
    // const {actions} = this.props;
    // const locationPathName = get(this.props, 'location.pathname', null);
    // const stack = get(info, 'componentStack', null);
    // actions.error.storeClientError(error, stack, locationPathName);
    this.setState({ hasError: true });
  }

  public render() {
    if (this.state.hasError) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1>Sorry, something went wrong</h1>
        </div>
      );
    }
    return this.props.children;
  }
}

export default flow([
  withRouter,
  connect(
    (state: IStore) => {
      return {
        auth: state.auth,
      };
    },
    (dispatch) => ({
      actions: {
        error: bindActionCreators({ ...errorActions }, dispatch),
      },
    }),
  ),
])(ErrorBoundary);
