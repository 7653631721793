import * as React from 'react';
import {Col, Row} from 'react-bootstrap';
import DefinitionPopup from '../../../../../../components/DefinitionPopup';
import {get, head, isEmpty, map} from 'lodash';
import {getBenefit} from '../../../../../../helpers/Quote';

interface IBenefit {
  title: string;
  selectedTripTypeQuotes: any;
  definition: string;
  covered?: any
}

class Benefit extends React.Component<IBenefit> {
  public render() {
    const {covered, selectedTripTypeQuotes, title, definition = ''} = this.props;
    let description = '';

    const getAllBenefits = (quote) => {
      let allBenefits = get(quote, 'scheme.allBenefits', []);

      if (!isEmpty(get(quote, 'information.all_benefits', []))) {
        allBenefits = get(quote, 'information.all_benefits', [])
      }

      return allBenefits;
    }

    const getSomeBenefits = (quote) => {
      let someBenefits = get(quote, 'benefits', []);

      if (!isEmpty(get(quote, 'information.all_benefits', []))) {
        const allBenefits = get(quote, 'information.all_benefits', [])
        someBenefits = [...allBenefits, ...someBenefits]
      }
      return someBenefits;

    }

    const benefitValue = (value) => {
      const {title} = this.props;

      // Gadget cover and golf cover return different values when not enabled for a tier. Gadget cover returns 'nil',
      // while golf cover returns 'n/a' when not enabled. Golf cover returns 'nil' when enabled.

      if ((title === 'Gadget cover')) {
        // Gadget cover is 'nil' when it is not available for this tier.
        // However if they select a tier where it is available on a latter screen and then go back,
        // it must check for that tier if it is available and that it is also covered.
        if (covered && value !== 'nil') {
          return 'Covered';
        }
        return 'Optional Extra';
      }

      if ((title === 'Golf cover')) {
        // Golf cover is 'n/a' when it is not available for this tier.
        // However if they select a tier where it is available on a latter screen and then go back,
        // it must check for that tier if it is available and that it is also covered.
        if (covered && value !== 'n/a') {
          return 'Covered';
        }
        return 'Not Covered';
      }

      if (title === 'Winter sports' || title === 'Cruise cover') {
        return covered ? 'Covered' : 'Not Covered';
      }

      return value === 'n/a' || value === 'nil' ? 'x' : value;
    };

    const benefitsALl = map(selectedTripTypeQuotes, (quotes, key) => {
      const someBenefits = getSomeBenefits(quotes);
      const allBenefits = getAllBenefits(quotes);

      const benefitsWithoutWinterAndCruise = someBenefits.filter((el) => {
        return el.name !== 'Winter sports' && el.name !== 'Cruise cover';
      });

      const winterAndCruiseBenefits = allBenefits.filter((el) => {
        return el.name === 'Winter sports' || el.name === 'Cruise cover';
      });

      return [...benefitsWithoutWinterAndCruise, ...winterAndCruiseBenefits];
    })

    const benefitsForDescription = head(benefitsALl);

    switch (title) {
      case 'Policy Excess':
        description = definition;
        break;
      case 'Winter sports':
        description = definition;
        break;
      case 'Cruise cover':
        description = definition;
        break;
      case 'Single item limit':
        description = getBenefit(benefitsForDescription, 'Baggage', 'subBenefitDescription', definition)
        break;
      case 'Overall valuables limit':
        description = getBenefit(benefitsForDescription, 'Baggage', 'subBenefitDescription', definition)
        break;
      default:
        description = getBenefit(benefitsForDescription, definition, 'description')
        break;
    }

    return (
      <>
        <Row>
          <Col xs={12} className="benefit-header">
            {title}
            <DefinitionPopup
              name="benefit-info"
              definition={description}
              iconType="info"
            />
          </Col>
          {map(selectedTripTypeQuotes, (quote, index) => {
            let value = null;

            const someBenefits = getSomeBenefits(quote);
            const allBenefits = getAllBenefits(quote);

            const benefitsWithoutWinterAndCruise = someBenefits.filter((el) => {
              return el.name !== 'Winter sports' && el.name !== 'Cruise cover';
            });

            const winterAndCruiseBenefits = allBenefits.filter((el) => {
              return el.name === 'Winter sports' || el.name === 'Cruise cover';
            });

            const benefits = [...benefitsWithoutWinterAndCruise, ...winterAndCruiseBenefits];

            switch (title) {
              case 'Policy Excess':
                value = get(quote, 'information.standardExcess') || get(quote, 'information.standard_excess')
                break;
              case 'Winter sports':
                value = getBenefit(benefits, 'Winter sports')
                break;
              case 'Cruise cover':
                value = getBenefit(benefits, 'Cruise cover')
                break;
              case 'Excess Waiver':
                value = 'NA'
                break;
              case 'Personal accident':
                value = getBenefit(benefits, 'Personal Accident', 'subBenefit', 'Death benefit')
                break;
              case 'Hijack':
                value = getBenefit(benefits,
                  'Hijack', 'subBenefit', 'Hijack benefit (£100 per day - over 24hrs)')
                break;
              case 'Single item limit':
                value = getBenefit(benefits, 'Baggage', 'subBenefit', 'single item limit')
                break;
              case 'Overall valuables limit':
                value = getBenefit(benefits, 'Baggage', 'subBenefit', 'valuables total limit')
                break;
              default:
                value = getBenefit(benefits, definition)
                break;
            }

            return (
              <Col xs={4} key={index} className={`benefit-body benefit-body-wrapper-${index}`}>
                <div>{benefitValue(value)}</div>
              </Col>
            )
          })}
        </Row>
      </>
    );
  }
}

export default Benefit;
