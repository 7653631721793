import { useEffect } from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { IStore } from '../../../../redux/IStore';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { RouteComponentProps, withRouter } from 'react-router';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import { IBrandingState } from '../../../../services/branding/models';
import steps from '../../steps';

interface IInitCustomerQuote extends RouteComponentProps<any> {
  quote: IQuoteState;
  branding: IBrandingState;
  actions: {
    quote: IQuoteActions,
    push: (path: string) => any;
  };
}

const InitCustomerQuote = ({ branding, match, actions }: IInitCustomerQuote) => {

  const initQuote = () => {
    const quoteSteps = steps(branding.channel);
    actions.quote.initQuoteFromCustomer(match.params.id).then(() => {
      actions.quote.isRetrievingQuote(false)
      actions.push(quoteSteps[0].route);
    }).catch(() => {
      actions.push(quoteSteps[0].route);
    });
  }

  useEffect(() => {
    initQuote()
  }, [])

  return null;

}

export default flow([
  connect(
    (state: IStore) => ({
      branding: state.branding,
      quote: state.quote,
    }),
    (dispatch) => ({
      actions: {
        push: bindActionCreators(push, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(InitCustomerQuote);
