import { get, union, filter, concat, forEach } from 'lodash';
import { setSignPost } from '../services/mta/actions';
import axios from 'axios';

export default function isSignPosted(selection, dispatch, change, mta, formValues) {

  if (!selection) {
    return;
  }

  const hasAdjustment = get(selection, 'premium_before_adjustments.gross')
    !== get(selection, 'premium.gross');
  const premiumSignposted = get(selection, 'premium.is_signposted', false);
  const declineRules = getMedicalDeclines(selection);
  const adjustedPremium = get(selection, 'mta.adj_premium.gross', 0);
  const hasMedical = quoteHasMedical(formValues);
  const isPreSignpostScheme = get(selection, 'information.is_pre_signpost_scheme', false);
  const policyId = get(formValues, 'id', null);
  let isSignPosted: boolean;

  // handle pre signpost schemes MTA
  // tslint:disable-next-line:prefer-conditional-expression
  if (isPreSignpostScheme) {
    isSignPosted = adjustedPremium && hasMedical;
  } else {
    isSignPosted = premiumSignposted && hasMedical;
  }
  // if NB quote and override is present with medical
  if (hasAdjustment) {
    isSignPosted = hasMedical;
  }

  if (isSignPosted && !declineRules.length) {
    dispatch(change('data.attributes.metadata.signposted', {
      medical_load: true,
      medical_decline: false,
    }));
    dispatch(addSignpostNote('Medical load over threshold – directory signposted', policyId));
    dispatch(setSignPost({ isSignposted: true, isMedical: false }));
  } else if (declineRules.length > 0 && isSignPosted) {
    dispatch(change('data.attributes.metadata.signposted', {
      medical_decline: true,
      medical_load: true,
    }));
    dispatch(addSignpostNote('Medical decline - directory signposting required', policyId));
    dispatch(setSignPost({ isSignposted: true, isMedical: true }));
  } else if (declineRules.length > 0 && !isSignPosted) {
    dispatch(change('data.attributes.metadata.signposted', {
      medical_decline: true,
      medical_load: false,
    }));
    dispatch(addSignpostNote('Medical decline - directory signposting required', policyId));
    dispatch(setSignPost({ isSignposted: true, isMedical: true }));
  } else {
    dispatch(change('data.attributes.metadata.signposted', null));
    dispatch(setSignPost({ isSignposted: false, isMedical: false }));
  }
}

export const getMedicalDeclines = (selection) => {
  return filter(union(get(selection, 'declines', []), get(selection, 'referrals', [])),
    (rules) => {
      return get(rules, 'is_medical', false);
    });
};

const addSignpostNote = (text, policyId) => {
  if (!policyId) {
    return;
  }
  return (dispatch) => {
    return axios.post('customers/mta/policies/' + policyId + '/notes',
      { data: { attributes: { content: text } } },
      { headers: { 'Content-Type': 'application/vnd.api+json' } }).catch((error) => {
      console.error(error);
    });
  };
};

export const quoteHasMedical = (formValues, travellers = []) => {
  let quoteTravellers = [];
  let hasScreening = false;
  if (formValues) {
    quoteTravellers = concat(get(formValues, 'metadata.adults', []), get(formValues, 'metadata.children', []));
  }

  if (travellers.length > 0) {
    quoteTravellers = travellers;
  }

  forEach(quoteTravellers, (traveller) => {
    if (traveller.screening) {
      hasScreening = true;
    }
  });
  return hasScreening;
};
