import * as React from 'react';
import Select from 'react-select';
import { isNull, isArray, isObject, isString } from 'lodash';
import { HelpBlock } from 'react-bootstrap';
import { OptionComponent, valueComponent } from '../../helpers/SessionCam';
import { windowSize } from '../../hooks/windowSize';

export const parseValue = (input) => {
  if (isNull(input)) {
    return '';
  }

  if (isArray(input) && isObject(input[0])) {
    return input.map((item) => item.value);
  }

  return isString(input) || isArray(input) ? input : input.value;
};

export default ({input, options, forceFilter, placeholder, className, meta, enableClear = true, ...props}) => {
  const onBlur = () => input.onBlur(parseValue(input.value));
  const onChange = (value) => input.onChange(parseValue(value));
  const isMobile = windowSize().isMobile;
  const isEnabled = !isMobile || isMobile && forceFilter;

  return (
    <div className={meta.touched && meta.error ? 'has-error' : ''}>
      <Select
        value={input.value}
        valueComponent={valueComponent}
        className={meta.dirty ? className + ' dirty-field' : className}
        onChange={onChange}
        onBlur={onBlur}
        placeholder={placeholder}
        searchable={isEnabled}
        clearable={isEnabled && enableClear}
        options={options}
        optionComponent={OptionComponent}
        disabled={props.disabled}
      />
      {meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
    </div>
  );
};
