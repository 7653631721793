import { Quote, Shell, Offline } from './scenes';
import * as React from 'react';
import steps from './scenes/Quote/steps';
import { IBrandingState } from './services/branding/models';
import { ICustomer } from './data/api/models';
import { Route, Switch } from 'react-router';
import RetrieveExpiredPolicy from './scenes/Quote/components/RetrieveExpiredPolicy';
import RetrieveQuote from './scenes/Quote/components/RetrieveQuote';
import InitCustomerQuote from './scenes/Quote/components/InitCustomerQuote';

interface IAppProps {
  branding: IBrandingState;
  customer?: ICustomer;
}

export default ({ branding, customer }: IAppProps) => {
  const quoteSteps = steps(branding.channel);
  const quote = <Quote key={2} steps={quoteSteps} channel={branding.channel} customer={customer} />;
  const routes = (
    <Switch>
      <Route exact={true} path="/retrieve/policy/:id" component={RetrieveExpiredPolicy}/>
      <Route exact={true} path="/retrieve/quote/:id" component={RetrieveQuote}/>
      <Route exact={true} path="/init/customer-quote/:id" component={InitCustomerQuote}/>
      <Route>{quote}</Route>
    </Switch>
  );

  return (
    <Shell steps={quoteSteps} channel={branding.channel}>
      {branding.isOffline ? <Offline /> : routes}
    </Shell>
  );
};
