import * as React from 'react';
import { setAnswer } from '../../../../../../services/verisk/reducer';
import { noop, intersection } from 'lodash';
import { getIncludedResource } from '../../../../../../data/api/util';
import { Button, Checkbox } from 'react-bootstrap';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit';

export default class MultipleChoiceQuestion extends React.Component<any, any> {
  public state = {
    answers: [],
    radio: [], // Elements that will cause the question to submit automatically and clear other choices
  };

  public componentWillReceiveProps({ condition, question, dispatch, screening }) {
    const answers = [];
    const radio = [];
    const options = getIncludedResource(question, screening.declaration.included, 'options', true);

    if (screening.isSubmitting) {
      return;
    }

    options.map((option) => {
      // const option = options[id];

      if (option.attributes.isSelected) {
        answers.push(option.attributes.number);
      }

      if (option.attributes.isForward === false) {
        radio.push(option.attributes.number);
      }

      if (option.attributes.isDisabled && option.attributes.isForward) {
        answers.push(option.attributes.number);
      }
    });

    this.setState({
      answers,
      radio,
    });
  }

  componentDidUpdate() {
    const element = document.getElementById('active-multi-question');
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }
  }

  private handleCheckboxTick = (e) => {
    const { answers, radio } = this.state;
    const answer = Number.parseInt(e.target.value, 10);

    const position = answers.indexOf(answer);

    if (position > -1) {
      this.setState({
        answers: answers.filter((val) => {
          return val !== answer;
        }),
      });
    } else {
      if (radio.indexOf(answer) > -1) {
        this.setState({
          answers: [
            answer,
          ],
        }, this.handleSubmit);

        return;
      }

      let newAnswers = [
        ...answers,
        answer,
      ];

      if (intersection(answers, radio).length > 0) {
        newAnswers = [answer];
      }

      this.setState({
        answers: newAnswers,
      });
    }
  }

  private handleSubmit = () => {
    const { dispatch, condition, question } = this.props;
    const { answers } = this.state;

    dispatch(setAnswer(condition, question, {
      id: answers.join(';'),
    }, true));
  }

  public render() {
    const { question, screening, dispatch, condition } = this.props;
    const { answers, radio } = this.state;
    const optionsResource = getIncludedResource(question, screening.declaration.included, 'options', true);
    const selectedAnswers = getIncludedResource(question, screening.declaration.included, 'selected', true);
    const isActiveQuestion = question?.attributes?.isActive || false;
    let disableRadios = false;

    return (
      <>
        <div className="row form-horizontal question dynamic-row">
          <div className="col-xs-12">
            <label className="control-label">{question.attributes.text}</label>
            {question.info ? (
              <p className="text-info" style={{ fontSize: '14px' }}>
                {question.info}
                <br/>
              </p>
            ) : ''}

            {!isActiveQuestion && (
              <ConditionQuestionEdit
                selectedAnswers={selectedAnswers}
                screening={screening}
                dispatch={dispatch}
                condition={condition}
                question={question}
              />
            )}

            {isActiveQuestion && optionsResource.length > 0 && (
              <>
                <div className="screening-radio-question " id={'active-multi-question'}>
                  {optionsResource.map((option, i) => {
                    const inputId = option.id;
                    const isForwardConditionSelected = option.attributes.isDisabled && option.attributes.isForward;
                    let disabled = option.attributes.isDisabled;

                    if (isForwardConditionSelected) {
                      disableRadios = true;
                    }

                    if (disableRadios && radio.indexOf(Number.parseInt(option.attributes.number, 10)) > -1) {
                      disabled = true;
                    }

                    return (
                      <div key={i}>
                        <Checkbox
                          id={inputId}
                          value={option.attributes.number}
                          checked={answers.indexOf(option.attributes.number) > -1 || isForwardConditionSelected}
                          onChange={disabled ? noop : this.handleCheckboxTick}
                          disabled={disabled}
                        >
                          <div className="btn-quote-option"/>
                          {option.attributes.text}
                        </Checkbox>
                      </div>
                    );
                  })}
                </div>

                <Button
                  bsStyle="primary"
                  className="margin-bottom"
                  disabled={screening.isSubmitting}
                  onClick={this.handleSubmit}
                >
                  Submit
                </Button>
              </>
            )}
          </div>
        </div>
        <div className={'screening-border-bottom'}/>
      </>
    );
  }
}
