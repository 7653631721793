import * as React from 'react';

export const Compliance = () => {
  return (
    <div style={{padding: '20px 15px'}}>Your quotation and our offer of insurance are based on the answers to all the questions we have asked. In
      addition to your Insurance Product Information document(s) noted below, you have had access to our Travel Policy
      Books within our <a href={'https://www.admiral.com/existing-customers/policy-documents.php'} target="_blank">Policy Books</a> section. This section also contains the 'Your Agreement with EUI Limited' document
      which sets out our services, fees and charges. You will check your Proposal Confirmation and tell us immediately
      if any details are incorrect.</div>
  )
}
