import { Col, HelpBlock, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import * as Commonmark from 'react-commonmark';
import { Field } from 'redux-form';
import FieldFormControl from '../../../../../../../components/FieldFormControl';
import { isRequired } from '../../../../../../../util/validators';
import * as React from 'react';
import { FormattedNumber } from 'react-intl';
import { FaInfoCircle, FaSpinner } from 'react-icons/fa';
import { get } from 'lodash';
import { windowSize } from '../../../../../../../hooks/windowSize';
import { gadgetDeprecation, replaceBenefitTextPlaceHolder } from '../../../../../../../helpers/Quote';
import { GadgetMoreInfo } from '../../GadgetMoreInfo';

export function DynamicTextWithAdd(
  {
    title,
    dynamicText,
    name,
    bodyText = null,
    onOptionClick,
    visibilityOfDynamicText,
    currency,
    boltOnTotal,
    visibilityOfAddButton,
    visibilityName,
    unselectAdd,
    disabledCase = null,
    validationMessage = 'both',
    props = null,
    optionKeyName = null,
    popOverTitle = null,
    popOverText = null,
    benefits = [],
    isMta = false,
    coverLevel = null
  }) {

  const popover = (
    <Popover id={`popover-trigger-${name}`}>
      <Commonmark source={popOverTitle}/>
      <Commonmark source={popOverText}/>
    </Popover>
  );

  const isMobile = windowSize(600).isMobile;
  const error = get(props, `synchronousError.visibility.options.${optionKeyName}`, null);
  let hasIcon = false;
  let source = '';
  let moreInfoComponent = null;

  switch (optionKeyName) {
    case 'excess_waiver':
      hasIcon = true;
      source = get(props, `options.${optionKeyName}`, false) ? require('../../../img/excess_waiver_color.svg') : require('../../../img/excess_waiver_grey.svg');
      break;
    case 'golf_cover':
      hasIcon = true;
      source = get(props, `options.${optionKeyName}`, false) ? require('../../../img/golf_cover_color.svg') : require('../../../img/golf_cover_grey.svg');
      break;
    case 'gadget_cover':
      hasIcon = true;
      const deprecation = gadgetDeprecation(benefits, isMta)
      moreInfoComponent = (<GadgetMoreInfo deprecation={deprecation}/>)
      source = get(props, `options.${optionKeyName}`, false) ? require('../../../img/gadget_cover_color.svg') : require('../../../img/gadget_cover_grey.svg');
      dynamicText = replaceBenefitTextPlaceHolder(dynamicText, benefits, optionKeyName, isMta, coverLevel)
      break;
  }

  return (
    <div className="options-section medical-question medical-section">

      <Row className="dynamic-row">
        {hasIcon && (
          <Col sm={7}>
            <div className="question-text">
              {!isMobile && (
                <>
                  <img src={source} alt="Admiral" className="additional-cover-icon-desktop"/>
                  <div className="title">
                    {title}
                    {popOverText && (
                      <OverlayTrigger
                        trigger="click"
                        rootClose={true}
                        placement="right"
                        overlay={popover}
                      >
                        <FaInfoCircle className="info-btn"/>
                      </OverlayTrigger>
                    )}
                  </div>
                  <Commonmark
                    source={bodyText}
                  />
                </>
              )}
              {isMobile && (
                <>
                  <div style={{ display: 'flex' }}>
                    <img src={source} alt="Admiral" className="additional-cover-icon-mobile"/>
                    <div className="title" style={{ paddingTop: '15px' }}>
                      {title}
                      {popOverText && (
                        <OverlayTrigger
                          trigger="click"
                          rootClose={true}
                          placement="right"
                          overlay={popover}
                        >
                          <FaInfoCircle className="info-btn"/>
                        </OverlayTrigger>
                      )}
                    </div>
                  </div>
                  <Commonmark
                    source={bodyText}
                  />
                </>
              )}
            </div>
            {visibilityOfDynamicText &&
              <Commonmark
                source={dynamicText}
              />}
            {moreInfoComponent && (
              <>
                {moreInfoComponent}
              </>
            )}
          </Col>
        )}

        {!hasIcon && (
          <Col sm={7}>
            <div className="question-text">
              <div className="title">
                {title}
                {popOverText && (
                  <OverlayTrigger
                    trigger="click"
                    rootClose={true}
                    placement="right"
                    overlay={popover}
                  >
                    <FaInfoCircle className="info-btn"/>
                  </OverlayTrigger>
                )}
              </div>
              <Commonmark
                source={bodyText}
              />
            </div>
            {visibilityOfDynamicText &&
              <Commonmark
                source={dynamicText}
              />}
          </Col>
        )}

        <Col sm={5}>
          <Row className="dynamic-row">
            <Col xs={12}>
              {/*Beware, some CSS is stripping padding from the column. Should probably use negative margins
              instead to avoid doing this.*/}
              <Col xs={6} className="radio-button-yes">
                <Field
                  name={visibilityName ? `visibility.${visibilityName}` : `visibility.${name}`}
                  type="radio"
                  format={null}
                  value={true}
                  complexValue={true}
                  className="answer"
                  validationMessage={validationMessage}
                  component={FieldFormControl}
                  validate={[isRequired]}
                  disabled={disabledCase}
                >
                  <div className="medical-btn yes">
                    Yes
                  </div>
                </Field>
              </Col>
              <Col xs={6} className="radio-button-no">
                <Field
                  name={visibilityName ? `visibility.${visibilityName}` : `visibility.${name}`}
                  type="radio"
                  format={null}
                  value={false}
                  complexValue={true}
                  className="answer"
                  validationMessage={validationMessage}
                  component={FieldFormControl}
                  validate={[isRequired]}
                  onClick={unselectAdd}
                  disabled={disabledCase}
                >
                  <div className="medical-btn no">
                    No
                  </div>
                </Field>
              </Col>
            </Col>
            {error && validationMessage !== 'both' && props.submitFailed && (
              <Row style={{ textAlign: 'center' }}>
                <Col>
                  <div className={error ? 'has-error' : ''}>
                    {<HelpBlock>{error}</HelpBlock>}
                  </div>
                </Col>
              </Row>
            )}
          </Row>
          {visibilityOfAddButton &&
            <Row className="option-question-container dynamic-row">
              <Col sm={12} className="option-question">
                <Field
                  name={name}
                  type="checkbox"
                  value={true}
                  complexValue={false}
                  className="answer"
                  component={FieldFormControl}
                  onClick={onOptionClick}
                  validate={[isRequired]}
                  disabled={disabledCase}
                >
                  <div className="option-btn">
                    <div className="option-content">
                      <span className="add">ADD  </span>
                      <span className="remove">REMOVE  </span>
                      {boltOnTotal ? (
                        <FormattedNumber
                          value={boltOnTotal}
                          style="currency"
                          currency={currency}
                        />
                      ) : (
                        <FaSpinner className="fa-spin"/>
                      )}
                    </div>
                  </div>
                </Field>
              </Col>
            </Row>}
        </Col>
      </Row>
    </div>
  );

}
