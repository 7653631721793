import * as React from 'react';
import { IChannel } from '../../../../data/api/models';
import * as Commonmark from 'react-commonmark';

interface IFooterProps {
  channel: IChannel;
}

export default class Footer extends React.Component<IFooterProps, any> {
  public render() {
    const { channel } = this.props;

    return (
      <footer>
        <div className="container">
          <Commonmark source={channel.footer} linkTarget="_blank"/>
        </div>
      </footer>
    );
  }
}
