import { IIndustry } from '../models';

export function fromJsonApi(data: any[]): IIndustry[] {
  return data.map((item) => {
    const { id, attributes: { name } } = item;

    return {
      id,
      name,
    };
  });
}
