import React = require('react');
import { Col, HelpBlock, Modal, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FieldFormControl from '../../../../../components/FieldFormControl';
import { isRequired } from '../../../../../util/validators';
import { useEffect, useState } from 'react';
import { IPaymentFormProps } from '../../Payment/common';

interface IMedicalImportantSection {
  hasTravellerDeclaredCondition: boolean;
  isAggregator?: boolean;
  props: IPaymentFormProps;
}

const MedicalImportantSection = ({
                                   hasTravellerDeclaredCondition,
                                   isAggregator = false,
                                   ...props
                                 }: IMedicalImportantSection) => {

  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    if (props.medicalsDeclared === false) {
      setShowModal(true);
    } else {
      setShowModal(false)
    }
  }, [props.medicalsDeclared]);

  const closeModal = () => {
    setShowModal(false)
  }

  let modalContent = <span>If there are <b>any</b> medical conditions that you've
  forgotten to tell us please go back and edit your details or
    <a className="pointer" href={'https://www.admiral.com/contact-us/travel'} target={'_blank'}> contact us </a>
    before purchasing.
  </span>

  if (isAggregator) {
    modalContent = <span>If there are <b>any</b> medical conditions that you've
  forgotten to tell us about you will need to return to the price comparison site to edit your details
    or <a className="pointer" href={'https://www.admiral.com/contact-us/travel'} target={'_blank'}>contact us</a> before purchasing.
  </span>
  }

  const renderModal = () => {
    return (
      <Modal
        show={showModal}
        onHide={closeModal}
        keyboard={false}
        backdrop="static"
        className={'medical-important-modal'}
      >
        <Modal.Header closeButton={true}>
          <Modal.Title>
            <div style={{ textAlign: 'center', color: '#ffffff' }}>UNABLE TO PROCEED</div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div style={{ textAlign: 'center', fontSize: '1.2em' }}>{modalContent}</div>
        </Modal.Body>
      </Modal>
    )
  }

  const renderQuestion = () => {

    const component = [
      (
        <Col xs={6} key={1}>
          <Field
            name={`medicalsDeclared`}
            type="radio"
            format={null}
            value={true}
            complexValue={true}
            className="answer"
            validationMessage={'single'}
            component={FieldFormControl}
            validate={[isRequired]}
          >
            <div className="medical-btn yes">
              Yes
            </div>
          </Field>
        </Col>
      ),
      (
        <Col xs={6} key={2}>
          <Field
            name={`medicalsDeclared`}
            type="radio"
            format={null}
            value={false}
            complexValue={true}
            className="answer"
            validationMessage={'single'}
            component={FieldFormControl}
            validate={[isRequired]}
          >
            <div className="medical-btn no">
              No
            </div>
          </Field>
        </Col>
      ),
      (
        <>
          {props.error && props.submitFailed && (
            <Row style={{ textAlign: 'center' }}>
              <Col>
                <div className={props.error ? 'has-error' : ''}>
                  {<HelpBlock>{props.error}</HelpBlock>}
                </div>
              </Col>
            </Row>
          )}
        </>
      ),
    ];

    return (
      <div className="medical-question medical-section">
        <Row>
          <Col sm={3} style={{ paddingTop: '15px' }}>
            Is this correct?
          </Col>
          <Col sm={5}>
            {component}
          </Col>
          <Col sm={4}/>
        </Row>
      </div>
    )
  }

  return (
    <div className="medical-important-container">

      <div className={'important-title'}>
        IMPORTANT:
      </div>

      <div className={'important-information'}>If you don't tell us about all the medical conditions for everyone
        on this policy it might stop your
        claims being paid out.
      </div>

      {!hasTravellerDeclaredCondition && (
        <div className={'important-title'}>
          You have confirmed that NO traveller covered by this policy:
        </div>
      )}
      {hasTravellerDeclaredCondition && (
        <div className={'important-title'}>
          Aside from the conditions declared above, you have confirmed that no traveller covered by this
          policy:
        </div>
      )}
      <ul>
        <li>Has been prescribed medication, received treatment, visited a doctor’s surgery or hospital about any
          medical
          conditions in the past <b>2 years</b>.
        </li>
        <li>Has <b>EVER</b> been diagnosed with any respiratory, circulatory, liver, terminal, cancerous, or mental
          health conditions.
        </li>
        <li>Is on a waiting list for treatment or investigation. e.g. awaiting a scan, surgery etc</li>
      </ul>

      <div className={'important-title'}>
        REMEMBER:
      </div>
      <ul style={{ marginBottom: '0' }}>
        <li>You <b>DO</b> need to tell us about anything you feel is no longer a medical condition because you
          take regular medication to control it e.g. high blood pressure, or is controlled by diet e.g.
          diabetes or other means.
        </li>
        <li>You <b>DO</b> need to declare conditions where <b>YOU</b> have decided to stop taking medication or haven't
          followed up with your GP.
        </li>
      </ul>

      {renderQuestion()}
      {renderModal()}

      <div style={{ marginTop: '10px' }}>
        <b>Changes in health:</b> You must tell us if any traveller named on this policy is diagnosed with a new
        medical
        condition or awaiting a new medical diagnosis, or if their health changes as soon as possible. This
        includes:
        changes in medication, being placed on a waiting list for tests and/or investigations and treatment or
        admission
        to hospital.
      </div>

      <div style={{ marginTop: '10px' }}>
        <b>Pre-existing medical conditions of non-travelling relatives</b> (or of those whose health may affect
        your
        decision
        to travel or remain overseas) are <b>not</b> covered. This applies regardless of whether you were aware
        of the
        condition or not.
      </div>

    </div>
  )
}

export default MedicalImportantSection;
