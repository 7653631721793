import * as React from 'react';
import { connect } from 'react-redux';
import { flow, map, includes, get } from 'lodash';
import { Col, Row } from 'react-bootstrap';
import { II18nMessages } from '../../../../../../services/branding/models';
import { IDestination, IPolicyApplication, IProduct, IQuotes } from '../../../../../../data/api/models';
import { formValueSelector, submit } from 'redux-form';
import { IStore } from '../../../../../../redux/IStore';
import { IQuoteActions } from '../../../../../../services/quote/models';
import { FormattedNumber } from 'react-intl';
import './style.scss';
import { bindActionCreators } from 'redux';
import { push } from 'react-router-redux';
import { FaSpinner } from 'react-icons/fa';
import { StickyContainer, Sticky } from 'react-sticky'

interface ICoverSummaryProps {
  i18n: II18nMessages;
  policyApplication: IPolicyApplication;
  destinations: IDestination[];
  values: IPolicyApplication;
  product: IProduct;
  quote: IQuotes;
  showOnlyTotal?: boolean
  actions: {
    submit(name: string);
    push: (route: string) => void;
    calculate: any;
    quote: IQuoteActions;
  };
}

class CoverSummary extends React.Component<ICoverSummaryProps, any> {
  private renderQuoteReference = (values) => {
    return (
      <div className="section">
        <Row>
          <Col xs={6} className="bold">
            Quote Reference
          </Col>
          <Col xs={6}>
            <div className="quote-info">
              {values.quoteReference}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  private renderTripType = (tripType, coverLevel, quote, quotedCover) => {

    if (!quotedCover.information) {
      return null;
    }

    const includedOptions = quotedCover.scheme.includedOptions;
    const boltOns = quotedCover.information ? quotedCover.information.boltOns : null;

    const includedOptionsValue = includedOptions.reduce((acc, current) => {
      return acc + boltOns[current].total;
    }, 0.00);

    let optionsDiff = 0;
    if (quote.application.options) {
      // This is a work around because the API is calculating the grossExAdditional like this
      // gross(discounted) - gross_options(not discounted).
      // As a workaround, we calculate below an amount which we add back to grossExAdditional (optionsDiff).
      const selectedOptions = Object.entries(quote.application.options)
        .filter((option) => option[1])
        .map((option) => option[0]);

      optionsDiff = selectedOptions.reduce((acc, option) => {
        return acc + (boltOns[option].total_before_discount - boltOns[option].total);
      }, 0);
    }

    const grossExAdditional = quotedCover.grossExAdditional + includedOptionsValue + optionsDiff;

    return (
      <div className="section">
        <Row>
          <Col xs={8} className="bold">
            {tripType} - <span className="text-capitalize">{coverLevel}</span>
          </Col>
          <Col xs={4}>
            <div className="quote-info">
              {!quote.isCalculating ? (
                <FormattedNumber
                  value={grossExAdditional}
                  style="currency"
                  currency={quotedCover.currency}
                />
              ) : (
                <FaSpinner className="fa-spin"/>
              )}
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  private renderAdditionalCover = () => {
    const { product, quote, values: { options } } = this.props;

    const quotes = quote.quotes;
    const coverLevel = Object.keys(quotes)[0];
    const tripType = Object.keys(quotes[coverLevel])[0];
    const quotedCover = quotes[coverLevel][tripType];
    const includedOptions = quotedCover.scheme.includedOptions;

    if (!quotedCover.information) {
      return <div/>;
    }

    const boltOns = quotedCover.information.boltOns;

    const optionElements = map(options, (value: any, key) => {
      const productOption = product.metadata.options[key];
      const boltOn = boltOns && get(boltOns, `${key}.total`, 0);
      if (productOption && productOption.type === 'boolean' || 'question' && value === true) {
        return (
          <div className="section" key={key}>
            <Row>
              <Col xs={8} className="bold">
                {productOption.name}
              </Col>
              <Col xs={4}>
                <div className="quote-info">
                  {!quote.isCalculating ? (
                    <FormattedNumber
                      value={boltOn}
                      style="currency"
                      currency={quotedCover.currency}
                    />
                  ) : (
                    <FaSpinner className="fa-spin"/>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        );
      }

      if (boltOns && includes(includedOptions, key)) {
        return (
          <div className="section" key={key}>
            <Row>
              <Col xs={5}>
                {productOption.name}
              </Col>
              <Col xs={7}>
                <div className="quote-info">
                  Included as standard
                </div>
              </Col>
            </Row>
          </div>
        );
      }
    }).filter((option) => !!option);

    return (
      <div>
        {optionElements.length > 0 && (
          <div className="section-border-top">
            {optionElements}
          </div>
        )}
      </div>
    );
  }

  private renderTotalPriceToPay = (quotedCover, quote, showOnlyTotal = false) => {
    const discountAmount = Math.abs(quotedCover.discount * (1 + (quotedCover.taxRate / 100)));
    return (
      <div className={showOnlyTotal ? 'white-section no-divider' : 'white-section'}>
        <Row>
          <Col xs={7}>
            <p>Total price to pay</p>
          </Col>
          <Col xs={5}>
            <span className="quote-info">
              <div>
                {!quote.isCalculating ? (
                  <div className="quote-info__final-price">
                    <FormattedNumber
                      value={quotedCover.gross}
                      style="currency"
                      currency={quotedCover.currency}
                    />
                    {discountAmount > 0 &&
                    <div className="quote-info__discount info-note">
                      Including a discount of{' '}
                      <FormattedNumber
                        value={discountAmount}
                        style="currency"
                        currency={quotedCover.currency}
                      />
                    </div>
                    }
                  </div>
                ) : (
                  <FaSpinner className="fa-spin"/>
                )}
              </div>
            </span>
          </Col>
        </Row>
        <Row>
          <Col sm={12}>
            <div className="info-note">
              This price includes IPT of 20% (where applicable).
            </div>
          </Col>
        </Row>
      </div>
    );
  }

  public render() {
    const { values, product, quote, showOnlyTotal = false } = this.props;

    const quotes = quote.quotes;
    const coverLevel = Object.keys(quotes)[0];
    const tripType = Object.keys(quotes[coverLevel])[0];
    const quotedCover = quotes[coverLevel][tripType];

    if (!values || !product) {
      return <div/>;
    }

    if (showOnlyTotal) {
      return (
        <StickyContainer className="steps-container sticky-container">
          <Sticky>
            {({
                style,
              }) => (
              <div style={style}>
                <Row>
                  <Col sm={12}>
                    <div className="section-group">
                      {this.renderTotalPriceToPay(quotedCover, quote, showOnlyTotal)}
                    </div>
                  </Col>
                </Row>
              </div>
            )}
          </Sticky>
        </StickyContainer>
      )
    } else {
      return (
        <div>
          <Row>
            <Col sm={12}>
              <div className="section-title">
                <h2>Your Cover Summary</h2>
              </div>
              <div className="section-group">
                {this.renderQuoteReference(values)}
                {this.renderTripType(tripType, coverLevel, quote, quotedCover)}
                {this.renderAdditionalCover()}
                {this.renderTotalPriceToPay(quotedCover, quote)}
              </div>
            </Col>
          </Row>
        </div>
      );
    }
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const quote = state.quote;
      const values = selector(
        state,
        'destinations',
        'startDate',
        'endDate',
        'region',
        'tripType',
        'options',
        'travellers',
        'groupType',
        'schemeId',
        'boltOns',
        'quoteReference',
      );

      return {
        i18n: state.branding.i18n,
        policyApplication: state.quote.application,
        destinations: state.quote.destinations,
        values,
        quote,
        // complete,
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        submit: bindActionCreators(submit, dispatch),
        push: bindActionCreators(push, dispatch),
      },
    }),
  ),
])(CoverSummary);
