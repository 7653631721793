import * as React from 'react';
import { Field, FieldArray, InjectedFormProps } from 'redux-form';
import { IBrandingState, II18nMessages } from '../../../../../services/branding/models';
import {
    IChannel,
    ICustomer,
    IOptionMap,
    IPaymentInfo,
    IPremium,
    IProduct,
    IQuotes,
    ITraveller
} from '../../../../../data/api/models';
import { IQuoteActions, IQuoteState } from '../../../../../services/quote/models';
import { get } from 'lodash';
import { FormattedNumber } from 'react-intl';
import Travellers from '../../TravellersDetails/components/Travellers';
import { Col, Row } from 'react-bootstrap';
import FieldFormControl from '../../../../../components/FieldFormControl';
import { isRequired } from '../../../../../util/validators';
import { Compliance } from '../components/Compliance';
import { PaymentYourCover } from '../../../components/PaymentYourCover';

export interface IPaymentFormProps extends InjectedFormProps {
    i18n: II18nMessages;
    product: IProduct;
    onBack: () => void;
    premium: IPremium;

    renderDefinition(name: string, useProvidedString?: string): any;

    channel: IChannel;
    customer: ICustomer;
    purchaseDeclaration: boolean;
    quote: IQuoteState;
    password: string;
    passwordConfirmation: string;
    policyDeclaration: boolean;
    branding: IBrandingState;
    errorMessage: string;
    schemeId: string;
    paymentInfo: IPaymentInfo;
    startDate: string;
    endDate: string;
    travellers: ITraveller[];
    groupType: string;
    occupations: object;
    industries: object;
    industryValue: string;
    applicationId: string;
    renewalStatus: string;
    documents: [];
    actions: {
        submit(name: string);
        push: (route: string) => void;
        calculate: any;
        change: (form, field, value) => void;
        quote: IQuoteActions;
    };
    post: boolean;
    options?: IOptionMap;
    visibility?: { options: any };
    synchronousError: any;
    payment: IPaymentInfo;
    dispatch: any;
    quoteReference: string;
    reuseCustomerAddress: boolean;
    permissions: boolean;
    quoteType: string;
    quotes: IQuotes;
    medicalsDeclared?: boolean
}

export class CommonPayment extends React.Component<IPaymentFormProps> {

    constructor(props) {
        super(props);
        const { actions } = props;
        actions.change('quote', 'reuseCustomerAddress', true);
    }

    public componentDidUpdate(prevProps: Readonly<IPaymentFormProps>, prevState: Readonly<{}>, snapshot?: any) {
        const { channel, premium, applicationId, actions } = this.props;
        if (prevProps.premium === null && this.props.premium) {
            const isAggregator = channel.channelType === 'AGG';
            const isSignposted = get(premium, 'isSignposted', false);
            if (isAggregator && isSignposted && applicationId) {
                actions.quote.addNotes('Medical load over threshold – directory signposted', applicationId);
            }
        }
    }

    public componentDidMount() {
        if (!window) {
            return;
        }
    }

    public componentWillMount() {
        const { branding, actions } = this.props;

        if (branding.channel.channelType === 'AGG') {
            actions.change('quote', 'communications.post', false);
        }

        actions.change('quote', 'permissions', true);
    }

    protected checkForErrors = () => {
        const { actions, travellers, medicalsDeclared } = this.props;

        Promise.resolve(actions.submit('quote')).then(() => {

            if (!get(travellers, '[0].address.line1', null)) {
                document.getElementById('form-errors').scrollIntoView({ block: 'center', behavior: 'smooth' });
            }

            if (medicalsDeclared === undefined) {
                document.getElementById('form-errors').scrollIntoView({ block: 'center', behavior: 'smooth' });
            }
        });
    }

    private handleOnclickNavigate = () => {
        const element = document.getElementById('terms-and-conditions');
        element.scrollIntoView({ block: 'center', behavior: 'smooth' });
    }

    protected renderPremium = (isAggregator: boolean = false) => {
        const { premium } = this.props;

        if (!premium) {
            return null;
        }

        const discountAmount = Math.abs(premium.discount * (1 + (premium.taxRate / 100)));
        const paymentText = isAggregator ?
            <div>You are also agreeing to our <a
                href="https://www.admiral.com/insurance/your-policy/conditions-of-use.php?&contentOnly=1&_ga=2.7793211.828839540.1508235797-2125578306.1459951786&_gac=1.217769634.1505922935.EAIaIQobChMI_rW5lpC01gIVzrztCh1dwA5aEAAYASAAEgJX9PD_BwE"
                target="_blank">conditions of use </a> and <a
                href="https://www.admiral.com/your-privacy-and-security/?&contentOnly=1&_ga=2.7793211.828839540.1508235797-2125578306.1459951786&_gac=1.217769634.1505922935.EAIaIQobChMI_rW5lpC01gIVzrztCh1dwA5aEAAYASAAEgJX9PD_BwE"
                target="_blank">privacy statement</a>.</div> : '';

        return (
            <div className="align-left payment-total-price">
                <div className="total-pay-text"><b>Total to pay by card</b></div>
                <FormattedNumber
                    value={premium.gross}
                    style="currency"
                    currency={premium.currency}
                />
                {discountAmount > 0 &&
                    <div className="quote-info__discount info-note">
                        Including a discount of{' '}
                        <FormattedNumber
                            value={discountAmount}
                            style="currency"
                            currency={premium.currency}
                        />
                    </div>
                }
                <div className={'payment-price-info'}>
                    <div>
                        By continuing you are agreeing to the <a
                        onClick={this.handleOnclickNavigate}>Terms and Conditions</a> shown above and that the summary
                        of cover
                        meets
                        your needs.
                    </div>
                    <div>
                        {paymentText}
                    </div>
                    <div>
                        This price includes IPT of 20% (Where applicable)
                    </div>
                </div>
            </div>
        );
    }

    protected renderTrackingItems = () => {
        const { branding, premium } = this.props;

        const items = [];

        if (branding.aggregatorTracking.type === 'cyti' && premium) {
            const params = branding.aggregatorTracking.cyti;
            const cytiUrl = params.url + '/track/click';

            items.push(
                <img
                    key={0}
                    src={`${cytiUrl}?cref=${params.cref}&premium=${premium.gross}&t=${params.token}`}
                    height="0"
                    width="0"
                />,
            );
        }

        return items;
    }

    protected renderTravellersForInput = (sectionTitle: string) => {
        const {
            i18n,
            product,
            groupType,
            occupations,
            industries,
            industryValue,
            renderDefinition,
            change,
        } = this.props;

        return (
            <FieldArray
                name="travellers"
                i18n={i18n}
                product={product}
                component={Travellers}
                groupType={groupType}
                sectionTitle={sectionTitle}
                occupations={occupations}
                industries={industries}
                industryValue={industryValue}
                renderDefinition={renderDefinition}
                change={change}
            />
        );
    }

    protected hasTravellerMedical = () => {
        const { travellers } = this.props;
        if (travellers) {
            return travellers.some((traveller) => {
                return traveller.screening;
            });
        }
        return false;
    }

    protected renderCompliance = () => {
        return (
            <div className={'section-group'}>
                <Compliance/>
            </div>
        )
    }

    protected renderYourCover = () => {
        const { premium } = this.props;

        const controlGroup = parseInt(get(premium, 'information.controlGroup'), 10);
        const shouldHideCancellation = [0, 1, 2, 3, 4].includes(controlGroup);

        return (
            <PaymentYourCover
                premium={premium}
                shouldHideCancellation={shouldHideCancellation}
            />
        )
    }

    protected renderPrintOptions = () => {
        const { post } = this.props;

        return (
            <div>
                <div className={'section-group'}>
                    <Row>
                        <Col sm={12} className={'send-documents-box no-padding'}>
                            <div className="section">
                                <Row>
                                    <Col xs={12} style={{ fontSize: '1.2em' }}>
                                        <p><b>The documents can be accessed immediately online for your convenience or
                                            sent by post (please
                                            allow
                                            5-7 working days). Please select your preference</b></p>
                                    </Col>
                                    <Col xs={12} className={'radio-group margin-top'}>
                                        <div className={'option no-top-border no-top-padding no-bottom-padding'}>
                                            <div className="radio-button">
                                                <Field
                                                    name="communications.post"
                                                    type="radio"
                                                    value={false}
                                                    complexValue={true}
                                                    component={FieldFormControl}
                                                    validate={[isRequired]}
                                                >
                                                    <div/>
                                                </Field>
                                            </div>
                                            <div className="radio-text">
                                                <div>{'Online'}</div>
                                            </div>
                                        </div>
                                        <div className={'option no-top-border no-top-padding'}>
                                            <div className="radio-button">
                                                <Field
                                                    name="communications.post"
                                                    type="radio"
                                                    value={true}
                                                    complexValue={true}
                                                    component={FieldFormControl}
                                                    validate={[isRequired]}
                                                >
                                                    <div/>
                                                </Field>
                                            </div>
                                            <div className="radio-text">
                                                {'Post'}
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {post && (
                                <div className="section">
                                    <Row>
                                        <Col xs={12} className={'radio-group margin-top'}>
                                            <div className={'select-option'} style={{ padding: '0 0 15px 15px' }}>Please
                                                select which option
                                                you
                                                would prefer?
                                            </div>
                                            <div className={'option'}>
                                                <div className="radio-button">
                                                    <Field
                                                        name="documentPack"
                                                        type="radio"
                                                        value={'personal'}
                                                        complexValue={true}
                                                        component={FieldFormControl}
                                                        validate={[isRequired]}
                                                    >
                                                        <div/>
                                                    </Field>
                                                </div>
                                                <div className="radio-text">
                                                    {' I’d like to receive all of my policy documents in the post but I am happy to access the policy book (76 page booklet) online.'}
                                                </div>
                                            </div>
                                            <div className={'option'}>
                                                <div className="radio-button">
                                                    <Field
                                                        name="documentPack"
                                                        type="radio"
                                                        value={'full'}
                                                        complexValue={true}
                                                        component={FieldFormControl}
                                                        validate={[isRequired]}
                                                    >
                                                        <div/>
                                                    </Field>
                                                </div>
                                                <div className="radio-text">
                                                    {'I’d like to receive all my policy documents in the post including the policy booklet (76 page booklet).'}
                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            )}
                        </Col>
                    </Row>
                </div>
            </div>
        );
    }

}

export const validate = (values) => {
    const errors = {
        passwordConfirmation: null,
        policyDeclaration: null,
        travellers: [
            {
                address: {
                    postcode: null
                }
            }
        ]
    };
    if (values.passwordConfirmation !== values.password) {
        errors.passwordConfirmation = 'Your confirmed password doesn\'t match the first field, please enter them again.';
    }
    if (!values.policyDeclaration) {
        errors.policyDeclaration = 'Please accept the policy declaration';
    }
    if (!get(values, 'travellers[0].address.line1', null)) {
        errors.travellers[0].address.postcode = 'Please use the Lookup button to select an address.';
    }
    return errors;
};


