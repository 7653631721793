export interface IFilter {
  name: string;
  value: string;
}

/**
 * Apply filters to endpoint
 * @param endpoint
 * @param filters
 * @returns {Array}
 */
export function applyFilters(endpoint: string, filters: IFilter[]): string {
  const queryFilters = [];

  filters.forEach((filter) => {
    queryFilters.push('filter[' + filter.name + ']=' + filter.value);
  });

  return queryString(endpoint, queryFilters);
}

/**
 * Apply includes to endpoint
 * @param endpoint
 * @param includes
 * @returns {any}
 */
export function applyIncludes(endpoint: string, includes): string {
  let queryIncludes = 'include';
  let joinString = '=';

  includes.forEach((include) => {
    queryIncludes += joinString + include;
    joinString = ',';
  });

  return queryString(endpoint, [queryIncludes]);
}

/**
 * Apply sorts to endpoint
 * @param endpoint
 * @param sorts
 * @returns {any}
 */
export function applySorts(endpoint: string, sorts): string {
  let querySorts = 'sort';
  let joinString = '=';

  sorts.forEach((sort) => {
    querySorts += joinString + sort;
    joinString = ',';
  });

  return queryString(endpoint, [querySorts]);
}

/**
 * Construct query string
 * @param url
 * @param queries
 * @returns {any}
 */
export function queryString(url, queries): string {
  let joinString = url.indexOf('?') === -1 ? '?' : '&';

  queries.forEach((query) => {
    url += joinString + query;
    joinString = '&';
  });

  return url;
}
