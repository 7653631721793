import {find, forEach, get, indexOf, isEmpty, startCase} from 'lodash';
import * as React from 'react';

export const getBenefit = (benefits: any[], displayName, type?, subBenefitName?) => {
  let displayBenefit = '';
  let displayBenefitDescription = '';
  let subBenefit = [];
  let displaySubBenefit = '';
  let displaySubBenefitDescription = '';

  if (!benefits) {
    return
  }

  benefits.forEach((benefit) => {
    if (benefit.displayName === displayName || benefit.name === displayName || benefit.display_name === displayName) {
      subBenefit = benefit.subBenefits || benefit.sub_benefits;
      displayBenefit = benefit.text;
      displayBenefitDescription = benefit.description;
    }
    forEach(subBenefit, (item) => {
      if (item.name === subBenefitName) {
        displaySubBenefit = item.text;
        displaySubBenefitDescription = item.description;
      }
    });
  });

  switch (type) {
    case 'subBenefit':
      return displaySubBenefit;
    case 'subBenefitDescription':
      return displaySubBenefitDescription;
    case 'description':
      return displayBenefitDescription;
    default:
      return displayBenefit;
  }
}

export const standardiseTheName = (name) => startCase(name.replace('-', ' '));

export const mentalHealthLinkRenderer = (): any => {
  return (
    <a href={'https://www.admiral.com/magazine/guides/travel/travel-and-mental-health-guide'}
       target="_blank">here
    </a>
  );
}

export const replaceBenefitTextPlaceHolder = (
  text: string,
  benefits: any[],
  benefitOptionName: string,
  isMta: boolean,
  coverLevel: string
) => {
  switch (benefitOptionName) {
    case 'gadget_cover':
      const toReplace = '{{single_item_value}}'
      let gadgetBenefit;
      let singleItemLimit;

      if (isMta) {
        gadgetBenefit = find(benefits, ['short_name', 'Gadget Cover'])
        singleItemLimit = gadgetBenefit ? find(gadgetBenefit.sub_benefits, ['name', 'Single item limit']) : null;
        if (coverLevel) {
          singleItemLimit.text = get(singleItemLimit, `cover_level.${coverLevel}`)
        }
      } else {
        gadgetBenefit = find(benefits, ['shortName', 'Gadget Cover'])
        singleItemLimit = gadgetBenefit ? find(gadgetBenefit.subBenefits, ['name', 'Single item limit']) : null;
      }

      text = singleItemLimit ? text.replace(toReplace, singleItemLimit.text) : text;
      return text;
    default:
      return text;
  }
}

export const gadgetDeprecation = (benefits: any[], isMta: boolean) => {
  let gadgetBenefit;
  if (isMta) {
    gadgetBenefit = find(benefits, ['short_name', 'Gadget Cover'])
    return get(gadgetBenefit, 'deprecation', null)
  } else {
    gadgetBenefit = find(benefits, ['shortName', 'Gadget Cover'])
    return get(gadgetBenefit, 'deprecation', null)
  }

}

export const shouldIgnoreCardPermissions = (): boolean => {
  return true;
}

export const getTiersByType = (tier: string, type: string, delimitter: string): string => {
  const tiersByType = [];
  const allTiers = [
    'admiral',
    'gold',
    'platinum'
  ]

  const selectedTierKey = indexOf(allTiers, tier);

  if (selectedTierKey !== -1) {
    forEach(allTiers, (tier, index) => {
      if (type === 'lower' && index < selectedTierKey) {
        tiersByType.push(startCase(tier));
      }

      if (type === 'higher' && index > selectedTierKey) {
        tiersByType.push(startCase(tier));
      }
    })
  }

  if (type === 'lower') {
    tiersByType.reverse().join(delimitter)
  }

  return tiersByType.join(delimitter)

}

export const getRegionsByType = (region: string, type: string, delimitter: string): string => {
  const regionsByType = [];
  const allRegions = [
    'uk',
    'europe',
    'worldwide_excluding',
    'worldwide',
  ]

  const selectedRegionKey = indexOf(allRegions, region);

  if (selectedRegionKey !== -1) {
    forEach(allRegions, (region, index) => {

      if (region === 'worldwide_excluding') {
        region = 'Worldwide excluding Canada, Mexico and the USA';
      }

      if (type === 'lower' && index < selectedRegionKey) {
        regionsByType.push(startCase(region));
      }

      if (type === 'higher' && index > selectedRegionKey) {
        regionsByType.push(startCase(region));
      }
    })
  }

  if (type === 'lower') {
    return regionsByType.reverse().join(delimitter)
  }

  return regionsByType.join(delimitter)
}

export const hasWaitingListExclusionOnly = (referrals: any[] = [], hasWaitingListExclusion: boolean): boolean => {

  if (referrals && referrals.length > 1) {
    return false;
  }

  if (referrals && referrals.length === 1) {
    return !isEmpty(find(referrals, ['name', 'waiting_list_exclusion']))
  }

  return referrals.length === 0 && hasWaitingListExclusion;

}
