import * as React from 'react';

/* tslint:disable:max-line-length */

interface IProps {
  name: string;
  color?: string;
}

const setColor = (color) => {
  let setColor = '#FFFFFF'
  switch (color) {
    case 'blue':
      return setColor = '#41a5f5'
    case 'darkBlue':
      return setColor = '#0045A0'
    case 'green':
      return setColor = '#00A32E'
    case 'warning':
      return setColor = '#CC0033'
    case 'white':
      return setColor = '#FFFFFF'
    case 'gold':
      return setColor = '#fcb800'
    case 'platinum':
      return setColor = '#a8c2d4'
    case 'black':
      return setColor = '#070707'
    default:
      return setColor
  }
}

export const Person = (color) => {

  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="32" height="32"
         viewBox="0 0 32 32">
      <path
        d="M16 16c2.947 0 5.333-2.387 5.333-5.333s-2.387-5.333-5.333-5.333c-2.947 0-5.333 2.387-5.333 5.333s2.387 5.333 5.333 5.333zM16 18.667c-3.56 0-10.667 1.787-10.667 5.333v2.667h21.333v-2.667c0-3.547-7.107-5.333-10.667-5.333z"/>
    </svg>
  )
}

export const Edit = (color) => {

  return (
    <svg version="1.1" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg" width="14" height="14"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M4 23v5h5l14.747-14.747-5-5-14.747 14.747zM27.613 9.387c0.52-0.52 0.52-1.36 0-1.88l-3.12-3.12c-0.52-0.52-1.36-0.52-1.88 0l-2.44 2.44 5 5 2.44-2.44z"/>
    </svg>

  )
}

export const Globe = (color) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg">
      <path
        d="M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM4 12C4 11.39 4.08 10.79 4.21 10.22L8.99 15V16C8.99 17.1 9.89 18 10.99 18V19.93C7.06 19.43 4 16.07 4 12ZM17.89 17.4C17.63 16.59 16.89 16 15.99 16H14.99V13C14.99 12.45 14.54 12 13.99 12H7.99V10H9.99C10.54 10 10.99 9.55 10.99 9V7H12.99C14.09 7 14.99 6.1 14.99 5V4.59C17.92 5.77 20 8.65 20 12C20 14.08 19.19 15.98 17.89 17.4Z"/>
    </svg>
  )
}

export const ShieldTicked = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="24" fill={setColor(color)} height="24"
         viewBox="0 0 32 32">
      <path
        d="M16 1.333l-12 5.333v8c0 7.4 5.12 14.32 12 16 6.88-1.68 12-8.6 12-16v-8l-12-5.333zM13.333 22.667l-5.333-5.333 1.88-1.88 3.453 3.44 8.787-8.787 1.88 1.893-10.667 10.667z"/>
    </svg>
  )
}

export const Tick = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill={setColor(color)}
         viewBox="0 0 32 32" className="adm-icon__svg">
      <title>included</title>
      <path d="M12 21.6l-5.6-5.6-1.867 1.867 7.467 7.467 16-16-1.867-1.867-14.133 14.133z"/>
    </svg>

  )
}

export const ShieldPlus = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="32" height="32"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M4 6.667v8c0 7.4 5.12 14.32 12 16 6.88-1.68 12-8.6 12-16v-8l-12-5.333-12 5.333zM22.667 17.333h-5.333v5.333h-2.667v-5.333h-5.333v-2.667h5.333v-5.333h2.667v5.333h5.333v2.667z"/>
    </svg>

  )
}

export const WinterSports = (color) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg"
         className="adm-icon__svg">
      <path
        d="M18.5 4.5C18.5 5.6 17.6 6.5 16.5 6.5C15.4 6.5 14.5 5.6 14.5 4.5C14.5 3.4 15.4 2.5 16.5 2.5C17.6 2.5 18.5 3.4 18.5 4.5ZM15.78 20.9L16.54 21.17C17.16 21.38 17.81 21.5 18.5 21.5C19.34 21.5 20.15 21.32 20.88 21L22 22.13C20.95 22.68 19.76 23 18.5 23C17.64 23 16.82 22.86 16.05 22.59L2 17.47L2.5 16.06L9.4 18.57L11.12 14.13L7.55 10.4C6.66 9.46 6.88 7.93 8 7.28L11.48 5.27C12.58 4.63 14 5.17 14.39 6.38L14.72 7.46C15.16 8.88 16.2 10.03 17.55 10.6L18.07 9L19.5 9.46L18.38 12.91C15.93 12.51 13.9 10.84 13 8.59L10.47 10.04L13.5 13.5L11.28 19.26L14.37 20.38L16.47 13.94C16.93 14.12 17.41 14.25 17.91 14.35L15.78 20.9Z"/>
    </svg>
  )
}

export const Excess = (color) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg"
         className="adm-icon__svg">
      <path
        d="M21 18V19C21 20.1 20.1 21 19 21H5C3.89 21 3 20.1 3 19V5C3 3.9 3.89 3 5 3H19C20.1 3 21 3.9 21 5V6H12C10.89 6 10 6.9 10 8V16C10 17.1 10.89 18 12 18H21ZM12 16H22V8H12V16ZM16 13.5C15.17 13.5 14.5 12.83 14.5 12C14.5 11.17 15.17 10.5 16 10.5C16.83 10.5 17.5 11.17 17.5 12C17.5 12.83 16.83 13.5 16 13.5Z"/>
    </svg>

  )
}

export const GolfCover = (color) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg"
         className="adm-icon__svg">
      <path
        d="M9 2L17 5.92L11 8.98V18.03C13.84 18.19 16 19.01 16 20C16 21.1 13.31 22 10 22C6.69 22 4 21.1 4 20C4 19.26 5.21 18.62 7 18.27V20H9V2Z"
        fill={setColor(color)}/>
      <path
        d="M21 19.5C21 20.3284 20.3284 21 19.5 21C18.6716 21 18 20.3284 18 19.5C18 18.6716 18.6716 18 19.5 18C20.3284 18 21 18.6716 21 19.5Z"
        fill={setColor(color)}/>
    </svg>
  )
}

export const GadgetCover = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="24" height="24"
         viewBox="0 0 24 32" className="adm-icon__svg">
      <path
        d="M20.667 1.333h-10.667c-1.84 0-3.333 1.493-3.333 3.333v22.667c0 1.84 1.493 3.333 3.333 3.333h10.667c1.84 0 3.333-1.493 3.333-3.333v-22.667c0-1.84-1.493-3.333-3.333-3.333zM15.333 29.333c-1.107 0-2-0.893-2-2s0.893-2 2-2c1.107 0 2 0.893 2 2s-0.893 2-2 2zM21.333 24h-12v-18.667h12v18.667z"/>
    </svg>
  )
}

export const CruiseCover = (color) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg"
         className="adm-icon__svg">
      <path
        d="M20 21C18.61 21 17.22 20.53 16 19.68C13.56 21.39 10.44 21.39 8.00001 19.68C6.78001 20.53 5.39001 21 4.00001 21H2.00001V23H4.00001C5.38001 23 6.74001 22.65 8.00001 22.01C10.52 23.3 13.48 23.3 16 22.01C17.26 22.66 18.62 23 20 23H22V21H20ZM3.95001 19H4.00001C5.60001 19 7.02001 18.12 8.00001 17C8.98001 18.12 10.4 19 12 19C13.6 19 15.02 18.12 16 17C16.98 18.12 18.4 19 20 19H20.05L21.94 12.32C22.02 12.06 22 11.78 21.88 11.54C21.76 11.3 21.54 11.12 21.28 11.04L20 10.62V6C20 4.9 19.1 4 18 4H15V1H9.00001V4H6.00001C4.90001 4 4.00001 4.9 4.00001 6V10.62L2.71001 11.04C2.45001 11.12 2.23001 11.3 2.11001 11.54C1.99001 11.78 1.96001 12.06 2.05001 12.32L3.95001 19ZM6.00001 6H18V9.97L12 8L6.00001 9.97V6Z"/>
    </svg>
  )
}

export const Calendar = (color) => {
  return (
    <svg version="1.1" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg" width="32" height="32"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M26.667 4h-1.333v-2.667h-2.667v2.667h-13.333v-2.667h-2.667v2.667h-1.333c-1.467 0-2.667 1.2-2.667 2.667v21.333c0 1.467 1.2 2.667 2.667 2.667h21.333c1.467 0 2.667-1.2 2.667-2.667v-21.333c0-1.467-1.2-2.667-2.667-2.667zM26.667 28h-21.333v-17.333h21.333v17.333z"/>
    </svg>

  )
}

export const IPID = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="20" height="20"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M8 2.667c-1.467 0-2.653 1.2-2.653 2.667l-0.013 21.333c0 1.467 1.187 2.667 2.653 2.667h16.013c1.467 0 2.667-1.2 2.667-2.667v-16l-8-8h-10.667zM17.333 12v-7.333l7.333 7.333h-7.333z"/>
    </svg>
  )
}

export const DATE = (color) => {
  return (
    <svg version="1.1" fill={setColor(color)} xmlns="http://www.w3.org/2000/svg" width="32" height="32"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M26.667 4h-1.333v-2.667h-2.667v2.667h-13.333v-2.667h-2.667v2.667h-1.333c-1.467 0-2.667 1.2-2.667 2.667v21.333c0 1.467 1.2 2.667 2.667 2.667h21.333c1.467 0 2.667-1.2 2.667-2.667v-21.333c0-1.467-1.2-2.667-2.667-2.667zM26.667 28h-21.333v-17.333h21.333v17.333z"/>
    </svg>

  )
}

export const MONEY = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="32" height="32"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M6.667 5.333h24v16h-24v-16zM18.667 9.333c1.061 0 2.078 0.421 2.828 1.172s1.172 1.768 1.172 2.828c0 1.061-0.421 2.078-1.172 2.828s-1.768 1.172-2.828 1.172c-1.061 0-2.078-0.421-2.828-1.172s-1.172-1.768-1.172-2.828c0-1.061 0.421-2.078 1.172-2.828s1.768-1.172 2.828-1.172zM12 8c0 0.707-0.281 1.386-0.781 1.886s-1.178 0.781-1.886 0.781v5.333c0.707 0 1.386 0.281 1.886 0.781s0.781 1.178 0.781 1.886h13.333c0-0.707 0.281-1.385 0.781-1.886s1.178-0.781 1.886-0.781v-5.333c-0.707 0-1.385-0.281-1.886-0.781s-0.781-1.178-0.781-1.886h-13.333zM1.333 10.667h2.667v13.333h21.333v2.667h-24v-16z"/>
    </svg>


  )
}

export const WARNING = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="20" height="20"
         viewBox="0 0 32 32" className="adm-icon__svg">
      <path
        d="M1.333 28h29.333l-14.667-25.333-14.667 25.333zM17.333 24h-2.667v-2.667h2.667v2.667zM17.333 18.667h-2.667v-5.333h2.667v5.333z"/>
    </svg>
  )
}

export const PAYMENT = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="32" height="32"
         viewBox="0 0 32 32">
      <path
        d="M26.667 5.333h-21.333c-1.48 0-2.653 1.187-2.653 2.667l-0.013 16c0 1.48 1.187 2.667 2.667 2.667h21.333c1.48 0 2.667-1.187 2.667-2.667v-16c0-1.48-1.187-2.667-2.667-2.667zM26.667 24h-21.333v-8h21.333v8zM26.667 10.667h-21.333v-2.667h21.333v2.667z"/>
    </svg>
  )
}

export const LOCK = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" fill={setColor(color)} width="32" height="32"
         viewBox="0 0 32 32">
      <path
        d="M24 10.667h-1.333v-2.667c0-3.68-2.987-6.667-6.667-6.667s-6.667 2.987-6.667 6.667v2.667h-1.333c-1.467 0-2.667 1.2-2.667 2.667v13.333c0 1.467 1.2 2.667 2.667 2.667h16c1.467 0 2.667-1.2 2.667-2.667v-13.333c0-1.467-1.2-2.667-2.667-2.667zM16 22.667c-1.467 0-2.667-1.2-2.667-2.667s1.2-2.667 2.667-2.667c1.467 0 2.667 1.2 2.667 2.667s-1.2 2.667-2.667 2.667zM20.133 10.667h-8.267v-2.667c0-2.28 1.853-4.133 4.133-4.133s4.133 1.853 4.133 4.133v2.667z"/>
    </svg>
  )
}
export const SUMMARY = (color) => {
  return (
    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="32" fill={setColor(color)} height="32"
         viewBox="0 0 32 32">
      <path
        d="M4 17.333h2.667v-2.667h-2.667v2.667zM4 22.667h2.667v-2.667h-2.667v2.667zM4 12h2.667v-2.667h-2.667v2.667zM9.333 17.333h18.667v-2.667h-18.667v2.667zM9.333 22.667h18.667v-2.667h-18.667v2.667zM9.333 9.333v2.667h18.667v-2.667h-18.667z"/>
    </svg>
  )
}

export const DROPDOWNARROW = (color) => {
  return (
    <svg stroke="currentColor" fill={setColor(color)} stroke-width="0" viewBox="0 0 20 20" height="20"
         xmlns="http://www.w3.org/2000/svg">
      <path fill="none" d="M0 0h24v24H0V0z"/>
      <path d="M7.41 8.59L12 13.17l4.59-4.58L18 10l-6 6-6-6 1.41-1.41z"/>
    </svg>
  )
}


export default ({ name, color }: IProps) => {
  switch (name) {
    case 'Person':
      return Person(color);
    case 'Edit':
      return Edit(color);
    case 'Globe':
      return Globe(color);
    case 'ShieldTicked':
      return ShieldTicked(color);
    case 'Tick':
      return Tick(color);
    case 'ShieldPlus':
      return ShieldPlus(color);
    case 'winter_sports':
      return WinterSports(color);
    case 'excess_waiver':
      return Excess(color);
    case 'golf_cover':
      return GolfCover(color);
    case 'gadget_cover':
      return GadgetCover(color);
    case 'cruise_cover':
      return CruiseCover(color);
    case 'Calendar':
      return Calendar(color);
    case 'IPID':
      return IPID(color);
    case 'Date':
      return DATE(color);
    case 'Money':
      return MONEY(color);
    case 'Warning':
      return WARNING(color);
    case 'Payment':
      return PAYMENT(color);
    case 'Lock':
      return LOCK(color);
    case 'Summary':
      return SUMMARY(color);
    case 'DropdownArrow':
      return DROPDOWNARROW(color);
    default:
      console.error('No name provided');
  }
};
