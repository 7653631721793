import { IDestination, IMTACancellationOptions, IPolicy, IStateCities, ITraveller } from '../../data/api/models';
import { IUpsellCampaign } from '../modal/model';

export interface IMtaAction {
  type: string;
  payload?: any;
}

export interface IMtaState {
  selectedPolicy: IPolicy;
  policyInfo: {
    meta: {
      controlGroup: number;
      termNumber: number;
      hasRenewal: boolean;
      isInCoolingOff: boolean;
      tier: string;
      schemeType: string;
      upsellMaxTripDuration: number;
      discount: number;
      renewalDiscount: number;
      renewalProductId: string;
      customer: {
        firstPolicyStartDate: string
      }
    }
  };
  paymentMethod: {
    maskedPAN: string;
    id: string;
  }
  rules: {
    canUpsell: boolean
  }
  mtaQuote: any;
  coverDetails: {
    selectedMta: string;
  };
  quotes: {};
  signpost: { isSignposted: boolean, isMedical: boolean };
  paymentModal: { open: boolean, transactionId: string };
  destinations: IDestination[],
  stateCities: IStateCities[],
  isLoading: boolean;
  complete: boolean;
  cancellationOptions: IMTACancellationOptions;
  upsellOptions: {
    campaign: IUpsellCampaign
  }
  voucherCodeStatus?: string,
  voucherCodeMessage?: string,
  regexVoucherCode?: boolean,
  voucherCodePreventDisplay?: boolean,
  campaignCode: string,
  afterSubmit: {
    changes: {};
    policyId: string;
    success: boolean;
    discount: any;
    error: string;
    returnPremium: string;
    renewalPremium: string;
    quoteReference: string;
    reason?: string;
  };
}

export interface IPersonalDetailsForm {
  policyId?: string;
  travellers?: ITraveller[];
}

export enum IMtaFormNames {
  PERSONAL_DETAILS = 'personal-details',
  COVER_DETAILS = 'cover-details',
  CANCEL_FORM = 'cancel-form'
}

export const enum PageForm {
  MTA_DECLARATION = 'MTADeclaration',
  MTA_CHANGE_DESTINATION = 'MTAChangeDestination',
  MTA_CHANGE_TRIP_DURATION = 'MTAChangeTripDuration',
  MTA_REMOVE_OPTION = 'MTARemoveOption',
  MTA_CHANGE_TRIP_DATES = 'MTAChangeTripDates',
  MTA_ADDITIONAL_COVER = 'MTAAdditionalCover',
  MTA_ADDITIONAL_TRAVELLERS = 'MTAAdditionalTravellers',
  MTA_REMOVE_TRAVELLER = 'MTARemoveTraveller',
  MTA_CHANGE_TIER = 'MTAChangeTier',
  MTA_CHANGE_REGION = 'MTAChangeRegion',
  MTA_CHANGE_TRIP_TYPE = 'MTAChangeTripType',
  MTA_MEDICAL_DECLARATION = 'MTAMedicalDeclaration',
  MTA_SUMMARY = 'MTASummaryForm',
  MTA_CANCEL = 'MTACancelForm'
}

export const enum CancellationReasons {
  NOT_TRAVELLING_SOON = 'not_travelling_soon',
  BOUGHT_A_DUPLICATE_POLICY = 'bought_a_duplicate_policy',
  POOR_SERVICE = 'poor_service',
  TOO_EXPENSIVE = 'too_expensive',
  COVER_NOT_SUITABLE = 'cover_not_suitable',
  OTHER = 'other',
}

export interface ICoverDetailsForm {
  page: string;
  coverDetailsDeclaration?: boolean;
  isUpsell?: boolean;
  data: any;
  changingMedical?: boolean;
  addingTravellers?: boolean;
  removeOption?: string[];
  premium?: any;
}

export interface IMtaActions {
  submitPersonalDetailsForm();

  storeMtaPolicyApplication(mtaForm);

  setSelectedPolicy(Policy);

  setSelectedMta(mtaName);

  handleMTASubmit(redirectUrl?);

  calculateMtaPremiums(mtaForm?);

  resetMtaData();

  getSelectedScheme();

  resetAfterSubmit();

  setCompleteMTA();

  getCustomerMtaOptions();

  setSignPost(data);

  getCustomerPolicyPaymentMethod();

  getPolicyInfo();

  recalculateScreening(selection, type);

  addEndorsements();

  loadDestinations();

  loadStateCities();

  getDetailsForCancellation(policyId);

  cancelPolicy(formValues, policyId);

  cancelRenewal(formValues, renewalId);

  setUpsellRules(canUpsell);

  setMTACancellationOptions(data);

  getMTAQuote(schemeId);

  calculateCancellationDiscount(discount);

  calculateRenewalDiscount(discount);

  issueDiscountMTA(formValues, premium, discount, reason);

  applyRenewalDiscount(formValues, premium, discount, reason);
  setUpsellOptions(data: any);
  checkVoucherCode(campaignCode);
  resetVoucherCode();
}
