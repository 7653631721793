import React = require('react');
import Banner from '../../scenes/Quote/components/Banner';
import {get} from 'lodash';
import {useState} from 'react';
import {FaSpinner} from 'react-icons/fa';
import {IAuthActions} from 'src/app/services/auth/reducer';

interface IBannerData {
  policyId?: string,
  authActions?: IAuthActions
  customerName?: string,
}

export const AdmiralHomeInsuranceBanner = ({policyId, authActions, customerName}: IBannerData) => {

  const [isGetQuotePressed, setGetQuotePressed] = useState(false);
  const [loading, setLoading] = useState<'loading' | 'done' | 'error'>('loading');
  const [data, setData] = useState<any>({});

  const S3Link = window.__APP_PROD__ ? 'https://admiral-prod.s3.eu-west-1.amazonaws.com/images/global/household/' : 'https://admiral-uat.s3.eu-west-1.amazonaws.com/images/global/household/';

  const loadOffers = () => {
    return policyId && authActions && customerName &&
      authActions.getHouseholdOffer(policyId)
        .then(offers => {
          setLoading('done');
          setData({
            deepLink: get(offers, 'deeplink'),
            premium: get(offers, 'premium'),
            tierName: get(offers, 'tier_name'),
            coverType: get(offers, 'cover_type'),
            // name: get(offers, 'name'), // not available on error, passing in customerName instead.
          });
        })
        .catch(error => {
          setLoading('error');
        });
  }

  const handleRetrieveQuote = () => {
    const urlPath = new URL(data.deepLink);
    const urlParams = new URLSearchParams(urlPath.search);
    const value = urlParams.get('quoteKey')
    const baseUrl = window.__HOUSEHOLD_RETRIEVE_URL__;
    const retrieveUrl = baseUrl + `?quoteKey=${value}&tier=${data.tierName}&brand=Admiral`
    window.open(retrieveUrl, '_blank');
  }

  const handleButtonPressed = () => {
    setGetQuotePressed(true);
    if (!loadOffers()) setLoading('error');
  }

  const renderYellowButton = (text: string, handleButtonPressed: () => void) => {
    return (
      <div className={'ahib--main-button-container'}>
        <div
          onClick={handleButtonPressed}
          style={{
            width: '150px',
            height: '45px',
            lineHeight: '43px',
            textAlign: 'center',
            fontSize: '14px',
            fontWeight: 'bold',
            backgroundColor: '#FFDD00',
            border: '1px solid #888',
            borderRadius: '22px',
            cursor: 'pointer',
          }}
        >{text}</div>
      </div>
    );
  }

  const renderGreeting = () => {
    return <div className={'ahib--greeting'}>Hello {customerName},</div>
  }

  const renderFooter1 = () => {
    return <div className={'ahib--footer'}>
      <div>
        By clicking Calculate Price,
        I agree to the <a
        href="https://www.admiral.com/insurance/your-policy/conditions-of-use.php?contentOnly=1&_ga=2.247788551.1652976520.1710158109-8321581.1710155735"
        target="_blank">conditions of use</a> and <a
        href="https://www.admiral.com/your-privacy-and-security?contentOnly=1&_ga=2.247788551.1652976520.1710158109-8321581.1710155735"
        target="_blank">privacy statement</a>.
      </div>
    </div>
  }
  const renderFooter2 = () => {
    return <div className={'ahib--footer'}>
      <div>Your home price, for our {data.tierName} tier for {data.coverType} cover, is based on assumptions.</div>
      <div>Price may change depending on your cover needs and eligibility. Price includes IPT.</div>
    </div>
  }

  return (
    <div className={'ahib--container'}>

      <Banner
        imageDesktop={S3Link + 'household-banner-desktop.jpg'}
        imageDesktopx2={S3Link + 'household-banner-desktop@2x.jpg'}
        imageDesktopx2Width={1400}
        imageMobile={S3Link + 'household-banner-mobile.jpg'}
        imageMobilex2={S3Link + 'household-banner-mobile@2x.jpg'}
        imageMobilex2Width={300}
        link={null}
        alt={'Admiral Home Insurance'}
      />

      <div className={'ahib--overlay-container'}>

        {/* - STATE 1 - Click to show price */}
        {!isGetQuotePressed && (<>
          <div className={'ahib--box'}>
            {renderGreeting()}
            <div className={'ahib--main-text'}>
              Get a Home Insurance quote with Admiral now
            </div>
          </div>
          <div className={'ahib--box'}>
            {renderYellowButton('Calculate Price', handleButtonPressed)}
          </div>
          {renderFooter1()}
        </>)}

        {/* - STATE 2 - Price shown */}
        {isGetQuotePressed && loading === 'done' && (<>
          <div className={'ahib--box'}>
            {renderGreeting()}
            <div className={'ahib--main-text'}>
              Get Home Insurance with Admiral from only <strong>£{data.premium}</strong>
            </div>
          </div>
          <div className={'ahib--box'}>
            {renderYellowButton('Click to Purchase', handleRetrieveQuote)}
          </div>
          {renderFooter2()}
        </>)}

        {/* - STATE - Loading */}
        {isGetQuotePressed && loading === 'loading' && (<>
          <div style={{display: 'flex', alignItems: 'center'}}>
            <FaSpinner className="fa-spin" style={{fontSize: '1.5em'}}/>
            <div style={{paddingLeft: '10px', fontStyle: 'italic', fontSize: '12px'}}>
              <div>Retrieving your quote</div>
              <div>Please wait...</div>
            </div>
          </div>
        </>)}

        {/* - STATE - Error */}
        {isGetQuotePressed && loading === 'error' && (<>
          <div className={'ahib--box'}>
            {renderGreeting()}
            <div className={'ahib--error'}>
              Sorry but we are unable to proceed.
              From the Travel policy details we have used, we are unable to provide you with a quote.
            </div>
          </div>
        </>)}

      </div>

    </div>
  );
};
