import React = require('react');
import { useState } from 'react';

export const CoverForCovidPanel = ({}) => {
  const [showText, setShowText] = useState(false);

  const handleOnClick = () => {
    setShowText(!showText)
  }

  return (
    <div className={'information-panel-container'}>

      <div className={'dropdown-box'} onClick={handleOnClick}>
        <div className={'dropdown-box-text'}>Cover for Covid-19 related event can be read here</div>
        <div className={'dropdown-box-icon'}>{showText ? 'Less Information' : 'More Information'}</div>
      </div>

      {showText && (
        <div className={'information-panel-body'}>
          <p>Claims related to Covid-19</p>
          <p>Our policies will cover you against unforeseen events. It is part of our claims process to request evidence
            and conduct relevant investigations to ensure you did not have reason to believe you were suffering from
            COVID at the time of purchase.</p>
          <p>Our policies cover emergency medical expenses if you contract Covid-19 while on your trip as well as
            cancelling your trip if you were diagnosed with Covid-19 before your trip was due to start.</p>
          <p className={'sub-heading'}>There is no cover if:</p>
          <ul style={{ paddingLeft: '40px' }}>
            <li>You had reason to believe you may have had Covid-19 when you booked your trip, purchased your policy or
              travelled.
            </li>
            <li>travel restrictions or quarantine that any government or public authority imposes on a community,
              location or vessel because of Covid-19. This includes (but is not limited to) local lockdowns, entry
              requirements, being denied entry, airspace closures; having to quarantine after arriving in the UK
              or abroad; or
            </li>
            <li>the Foreign Commonwealth Development Office (FCDO) advice changing their advice to avoid ‘all travel’ or
              ‘all but essential travel’ for your destination because of Covid-19.
            </li>
          </ul>
          <p><span className={'sub-heading'}>Note:</span> If you had a positive diagnosis for Covid-19 and were
            prescribed medication, received treatment or had a consultation with a doctor or hospital specialist in the
            past 2 years, this will need to be declared.</p>
        </div>

      )}

    </div>
  )
}
