import React = require('react');
import { Button, Modal } from 'react-bootstrap';
import QuoteIcons from '../../scenes/Quote/components/Icon/QuoteIcons';
import { useState } from 'react';
import { IModalActions, IUpsellModal } from '../../services/modal/model';
import { IMtaActions } from '../../services/mta/models';
import { get } from 'lodash';

interface IMarketingUpsellModalProps {
  push: (route: string) => void;
  upsellModal: IUpsellModal;
  modalActions: IModalActions;
  mtaActions: IMtaActions;
}

export const MarketingUpsellModal = ({
                                       push,
                                       upsellModal,
                                       modalActions,
                                       mtaActions,
                                     }: IMarketingUpsellModalProps) => {

  const [showMoreInfo, setShowMoreInfo] = useState(false);
  const showModal = sessionStorage.getItem('showMarketingUpsell')
  // don't set campaign if already has a campaign on the policy
  const campaign = !get(upsellModal, 'data.hasDiscount', false) ? get(upsellModal, 'data.campaign', null) : null;

  if (upsellModal.opened && showModal === 'hide') {
    return null;
  }

  if (upsellModal.opened) {
    sessionStorage.setItem('showMarketingUpsell', 'show')
  }

  const onCloseModal = () => {
    sessionStorage.setItem('showMarketingUpsell', 'hide')
    modalActions.closeUpsellModal()
  }

  const handleUpsell = () => {
    sessionStorage.setItem('showMarketingUpsell', 'hide')
    modalActions.closeUpsellModal()
    mtaActions.setUpsellOptions({ campaign })
    push(`redirect-to-mta-upsell/${upsellModal?.data?.policyId}`)
  }

  const showInfo = () => {
    setShowMoreInfo(!showMoreInfo)
  }

  const renderCampaign = () => {
    if (campaign && campaign.discount) {
      return (
        <>
          <div className={'upsell-discount'}>
            <div className={'upsell-discount-title'}>Get {campaign.discount}% off if you switch!</div>
            <div>Using discount code {campaign.code}</div>
          </div>
        </>
      )
    }

    return null;
  }

  const icon = (
    <img height={'20px'} style={{ marginRight: '10px' }} src={require('../../scenes/Quote/img/black-tick.svg')}
         alt={'Admiral'}/>);

  const price = upsellModal?.data?.difference ? upsellModal?.data?.difference.toFixed(2) : '';
  const fullAMTPrice = upsellModal?.data?.fullAMTPrice ? upsellModal?.data?.fullAMTPrice.toFixed(2) : '';

  return (
    <Modal show={upsellModal.opened} onHide={onCloseModal} backdrop={'static'} style={{ top: '25%' }}
           className={'upsell-modal'}>
      <Modal.Body>
        <div className={'marketing-upsell-modal-body'}>
          <div className={'upsell-header-image'}>
            <img src={require('./img/marketingUpsell.jpg')} alt="Admiral"/>
          </div>
          <div className={'header-text-wrapper'}>
            <div className={'header-title'}>Switch to Annual Multi-Trip Insurance!</div>
            <div className={'header-subtitle'}>It could save money when you travel again</div>
            <div className={'upsell-benefits-container'}>
              <div style={{ display: 'flex' }} className={'upsell-benefits'}>
                <div style={icon ? { paddingRight: '0' } : { display: 'list-item', marginLeft: '25px' }}>{icon}</div>
                <div>Unlimited trips for 12 months up to 31 days</div>
              </div>
              <div style={{ display: 'flex' }} className={'upsell-benefits'}>
                <div style={icon ? { paddingRight: '0' } : { display: 'list-item', marginLeft: '25px' }}>{icon}</div>
                <div>Cover in the UK and abroad</div>
              </div>
              <div style={{ display: 'flex' }} className={'upsell-benefits'}>
                <div style={icon ? { paddingRight: '0' } : { display: 'list-item', marginLeft: '25px' }}>{icon}</div>
                <div>Saves you time and hassle</div>
              </div>
            </div>

            <div>{renderCampaign()}</div>

            <div className={'modal-footer-buttons'}>
              <Button
                className={'admiral-secondary-btn accept'}
                bsSize="small"
                block={true}
                type={'button'}
                onClick={handleUpsell}
              >
                SWITCH FOR £{price}
              </Button>

              <Button
                className={'admiral-white-btn cancel'}
                bsSize="small"
                block={true}
                type={'button'}
                onClick={onCloseModal}
              >
                NOT NOW
              </Button>
            </div>
            <div style={{ fontSize: '12px' }}>
              Price is based on Annual Multi Trip price of £{fullAMTPrice} (including IPT) less the amount already paid
              for your single trip
              policy. {campaign && campaign.discount ? 'A discount may be applied when switching to reduce your Annual Multi-trip price.' : ''}
            </div>
            {upsellModal?.data?.IPIDLink && upsellModal?.data?.IPIDLink.filePath && (
              <div style={{ display: 'flex', marginTop: '23px', alignItems: 'center' }} className={'upsell-benefits'}>
                <div style={{ maxHeight: '20px' }}><QuoteIcons name={'IPID'} color={'darkBlue'}/></div>
                <div className={'ipid'} style={{ flexBasis: '70%' }}>
                  <a href={upsellModal?.data?.IPIDLink.filePath} target={'_blank'}>
                    Insurance Product Information Document
                  </a>
                </div>
                <div className={'more-info'} onClick={showInfo}>
                  <div className={'info-dropdown'}>INFO <QuoteIcons name={'DropdownArrow'} color={'blue'}/></div>
                </div>
              </div>
            )}
          </div>
        </div>
        {showMoreInfo && (
          <div style={{ textAlign: 'left' }}>
            Your Annual Multi-Trip price is calculated based on the travellers, tier level and region on your existing
            Single Trip policy. You can expand the region when you switch, or make other changes by visiting ‘Make a
            Change’ after switching. Any changes may affect the price.
          </div>
        )}
      </Modal.Body>
    </Modal>
  )
}
