import * as React from 'react';
import { Field, reduxForm, InjectedFormProps, submit, getFormSyncErrors } from 'redux-form';
import { flow, forEach, isEmpty } from 'lodash';
import * as Commonmark from 'react-commonmark';
import { IBrandingActions, II18nMessages } from '../../../../services/branding/models';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FieldFormControl from '../../../../components/FieldFormControl';
import { IChannel, IDestination, IProduct } from '../../../../data/api/models';
import { isNotFalse, isRequired } from '../../../../util/validators';
import { actions as quoteActions, getInitValues } from '../../../../services/quote/reducer';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { IStore } from '../../../../redux/IStore';
import { IAuthState } from '../../../../services/auth/models';
import BusinessContinuityPlan from '../../../../helpers/BusinessContinuityPlan';
import { bindActionCreators } from 'redux';
import { IQuoteActions } from '../../../../services/quote/models';
import { renderProductDefinition } from '../../../CustomerAreaNew/scenes/MakeAChange/Helpers';
import { actions as brandingActions } from '../../../../services/branding/reducer';
import Heap from '../../../../helpers/Heap';

interface ITripDetailsFormProps extends InjectedFormProps {
  destinations: IDestination[];
  i18n: II18nMessages;
  groupType: string;
  tripType: string;
  startDate: string;
  endDate: string;
  product: IProduct;
  destinationsValue: string[];
  channel: IChannel;
  actions: {
    submit(name: string);
    quote: IQuoteActions;
    branding: IBrandingActions,
  };

  renderDefinition(name: string): any;
  synchronousError: any;

  auth: IAuthState;
}

class TripDeclaration extends React.Component<ITripDetailsFormProps, any> {

  public constructor(props) {
    super(props);
    Heap.handleHeapStartPage('tripdeclaration')
  }
  public componentWillMount() {
    const { product, change, initialValues } = this.props;

    const data = getInitValues(product);

    forEach(data, (value, key) => {
      if (initialValues[key] === null) {
        change(key, value);
      }
    });
  }

  public static getCovidDisclaimer = () => {
    return '**IMPORTANT – CORONAVIRUS GUIDANCE** \n\n **Cover for specific Covid-19 related events** \n\n ' +
      '<p style="color: #CC0033">Our policies will cover you against unforeseen events. It is part of our claims process to request evidence and conduct relevant investigations to ensure you did not have reason to believe you were suffering from COVID at the time of purchase. \n\n </p>' +
      'Our policies cover emergency medical expenses if you contract Covid-19 while on your trip as well as cancelling your trip if you test positive for Covid-19 within the 14 days before your trip departure date. \n\n ' +
      'There is no cover if:  \n\n ' +
      '• You had reason to believe you may have Covid-19 when you booked your trip, purchased your policy or travelled \n\n ' +
      '• You are travelling against FCDO advice (including if your trip is essential) \n\n ' +
      '• You are cancelling or cutting short your trip due to travel restrictions imposed by any government such as local or national lockdowns, or if FCDO advice related to Covid-19 changes before or during your trip \n\n ' +
      '• You need to self-isolate as a result of potential exposure to Covid-19 in the UK or abroad, unless it’s because one of the insured persons has tested positive within the 14 days prior to their trip departure. \n\n ' +
      '**Note:** If you had a positive diagnosis for Covid-19 and were prescribed medication, received treatment or had a consultation with a doctor or hospital specialist in the past 2 years, this will need to be declared. \n\n ' +
      '**Mental health conditions:** If you would like information about travel insurance and mental health conditions or more support to complete your insurance application, please visit our dedicated page [link](link)';
  }

  private handleSubmit = () => {
    const { actions, synchronousError } = this.props;
    Promise.resolve(actions.submit('quote')).then(() => {
      if (synchronousError && !isEmpty(synchronousError)) {
        window.scrollTo(0, 0);
      }
    });
  }

  public render() {
    const {
      submitting,
      handleSubmit,
      valid,
      submitFailed,
      channel,
      auth,
      actions,
    } = this.props;

    const bcp = channel.bcp || null;
    const bcpMessage = BusinessContinuityPlan.getBCPMessage(bcp);
    const product = actions.branding.getProduct();
    const renderDefinition = renderProductDefinition(product);
    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        {(bcpMessage) && (
          <div className="steps-container">
            <div className={'section-important'}>
              <div className={'important'}>
                <Commonmark source={bcpMessage}/>
              </div>
            </div>
          </div>
        )}

        {submitFailed && !valid && (
          <div className="error-block-container">
            <h1>Whoops!</h1>
            <p>Missing something? Please check the areas marked in orange before you continue.</p>
          </div>
        )}

        <div className="steps-container">

          <p className="p-out-of-section">
            Please confirm that you and any additional travellers:
          </p>

          <ul className="declaration">
            <li className="declaration-list">Are permanent UK residents. {renderDefinition('Are a permanent UK resident', '**Are a permanent UK resident**\n\n You have your only or main home in the UK and have not spent more than six months outside the UK in the year before taking out (or renewing) this policy.')}</li>
            <li className="declaration-list">Will depart from and return to the UK.</li>
            <li className="declaration-list">Are registered with a doctor in the UK.</li>
            <li className="declaration-list">Are unaware of any reason why a trip would be cancelled or cut short.</li>
            <li className="declaration-list">Have not started a trip.</li>
          </ul>

          {/* Type Declaration */}

          <div className="section-important">
            <Row className="radio-horizontal">
              <Col sm={12}>
                <Field
                  name="quotationDeclaration"
                  id="quotation-declaration"
                  type="checkbox"
                  component={FieldFormControl}
                  validate={[
                    isRequired,
                    isNotFalse,
                  ]}
                >
                  <div/>
                  <span style={{ fontWeight: 'normal' }}>Tick here to agree</span>
                </Field>
              </Col>
            </Row>
          </div>

          <div className="white-section">
            <p>
              If you are unable to confirm any of the assumptions above,
              you will be unable to continue with this quote.
            </p>
          </div>

          {/* Nav Buttons */}

          <div className="btn-bar">
            {auth.isAuthenticated && (
              <Link to="/account/summary">
                <Button
                  id="continue"
                  bsStyle="primary"
                  bsSize="lg"
                  className="left"
                  disabled={submitting}
                >BACK</Button>
              </Link>
            )}

            <Button
              id="continue"
              bsStyle="primary"
              bsSize="lg"
              type="button"
              onClick={this.handleSubmit}
              className="pull-right"
              disabled={submitting || (submitFailed && !valid)}
            >
              CONTINUE
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    return component;
  },
  connect(
    (state: IStore) => {
      const synchronousError = getFormSyncErrors('quote')(state);
      return {
        auth: state.auth,
        synchronousError,
      };
    },
    (dispatch) => ({
      actions: {
        submit: bindActionCreators(submit, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        branding: bindActionCreators({ ...brandingActions }, dispatch),
      },
    }),
  ),
])(TripDeclaration);
