import { IBCP } from '../data/api/models';
import { get } from 'lodash';

export default class BusinessContinuityPlan {
  public static doNotSell(bcp?: IBCP): boolean {
    return bcp
      && bcp.enabled
      && bcp.doNotSell
      && bcp.messageKey
      && get(bcp.contents, bcp.messageKey)
      && bcp.allowedSchemeTypes.length === 0;
  }

  public static getBCPMessage(bcp?: IBCP) {
    if (!bcp) {
      return '';
    }

    if (bcp.enabled && bcp.messageKey) {
      return get(bcp.contents, bcp.messageKey);
    }

    return '';
  }

  public static isSchemeTypeAllowed(schemeType: string, bcp ?: IBCP) {
    let allowed = true;
    if (bcp && bcp.enabled && bcp.doNotSell && bcp.allowedSchemeTypes.length) {
      allowed = bcp.allowedSchemeTypes.includes(schemeType);
    }

    return allowed;
  }
}
