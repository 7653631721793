import { capitalize, forEach, get, head, isEmpty, last, set } from 'lodash';
import { ICustomerPaymentMethod, IPolicyApplication, IStep } from '../data/api/models';
import * as moment from 'moment/moment';

interface IAdditional {
  premium?: number,
  coverLevel?: string
  errorMessage?: string
}

export default class Heap {
  public static track(name, properties =  {}): void {
    if (window.heap) {
      window.heap.track(name, properties);
    }
  }

  public static trackEvent = (eventName: string, application: any, type: string, additional: IAdditional = null) => {

    switch (type) {
      case 'quote':
        Heap.trackQuoteEvent(eventName, application, additional)
        break;
    }
  }

  private static trackQuoteEvent = (eventName: string, application: any, additional) => {

    const premium = head(get(application, 'premiums'));
    const errorMessage = get(additional, 'errorMessage')
    let additionalOptions = '';
    let hasScreening = false;
    if (get(application, 'options.gadget_cover')) {
      additionalOptions += 'Gadget Cover '
    }

    if (get(application, 'options.excess_waiver')) {
      additionalOptions += 'Excess Waiver'
    }

    forEach(get(application, 'travellers'), (traveller) => {
      if (traveller.screening) {
        hasScreening = true;
      }
    });

    const props = {
      trv_quote_reference: get(application, 'quoteReference', undefined),
      trv_channel: get(application, 'channelName', undefined),
      trv_aggregator: get(application, 'channel.channelType', '') === 'AGG' ? get(application, 'channel.name', undefined) : undefined,
      trv_policy_type: get(application, 'tripType', undefined),
      trv_country: !isEmpty(get(application, 'destinations', [])) ? get(application, 'destinations').join(', ') : undefined,
      trv_region: get(application, 'region', undefined),
      trv_start_date: get(application, 'startDate', undefined),
      trv_group_type: get(application, 'groupType', undefined),
      trv_winter_sports: get(application, 'options.winter_sports', false) ? 'Y' : 'N',
      trv_cruise_cover: get(application, 'options.cruise_cover', false) ? 'Y' : 'N',
      trv_voucher_code: get(application, 'campaignCode', undefined),
      trv_selected_tier: get(premium, 'scheme.coverLevel'),
      trv_additional_covers: additionalOptions,
      trv_medical: hasScreening ? 'Y' : 'N',
      trv_premium: get(premium, 'gross', 0)
    }

    if (errorMessage) {
      set(props, 'trv_purchase_error', errorMessage);
    }

    if (window.__HEAP_LOG__) {
      console.log(`%cEvent Name: ${eventName}`, 'color: green');
      console.log(props)
      console.log(application)
    }

    Heap.track(eventName, props);
  }

  public static handleHeapExitPage = (nextStep: IStep, currentStep: IStep, formValues: IPolicyApplication) => {
    const eventName = 'Exit' + capitalize(currentStep.key) + 'OQJ';
    if (nextStep.key) {
      Heap.trackEvent(eventName, formValues, 'quote')
    }
  }

  public static handleHeapStartPage = (name: string) => {
    const eventName = 'Start' + capitalize(name) + 'OQJ';
    if (name) {
      Heap.track(eventName)
    }
  }

  public static handleCalculationReferrals = (quoteReference: string, isMedical: boolean, isUnderwriter: boolean) => {
    const eventName = 'ReferralBlock';

    const props = {
      trv_quote_reference: quoteReference,
      trv_medical_block: isMedical ? 'Y' : 'N',
      trv_underwriting_block: isUnderwriter ? 'Y' : 'N'
    }

    if (window.__HEAP_LOG__) {
      console.log(`%cEvent Name: ${eventName}`, 'color: green');
      console.log(props)
    }

    Heap.track('ReferralBlock', props);

  }

}
