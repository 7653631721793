export interface IContent {
  // id: string;
  name: string;
  slug: string;
  duration: number;
  contentType: any;
  sections: any;
  priority?: string;
  fromDate?: Date;
  toDate?: Date;
  createdAt: Date;
  updatedAt: Date;
  metadata: any;
}

export function _transformContentResponse(response) {
  const content: IContent = {
    // id: response.id,
    ...response.attributes,
  };

  return content;
}

export interface IContentActions {
  requestingContent(requesting: any),
  setContent(content: any),
  getContent(filters: any[]),
  getContentByName(name: string),
}
