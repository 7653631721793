export const  reorderObjectKeyValues = (obj: any, order: string[]) => {
  const newObject = {};
  if (obj && order && order.length) {
    order.forEach((value, key) => {
      if (obj.hasOwnProperty(order[key])) {
        newObject[order[key]] = obj[order[key]];
      }
    })
    return newObject;
  }
  return obj;
}
