import { IStateCities } from '../models';

export function fromJsonApi(data: any[]): IStateCities[] {
  return data.map((item) => {
    const { id, attributes: { name, included, country_code } } = item;

    return {
      id,
      name,
      included,
      countryCode: country_code,
    };
  });
}
