import * as React from 'react';
import DatePicker from 'react-datepicker';
import * as moment from 'moment';
import './style.scss';

const DATE_FORMAT = 'DD/MM/YYYY';

const AlternativeDatePicker = ({ input, change, placeholder, meta: { touched, error }, ...props }) => {
  const handleChange = (date) => {
    const momentDate = moment(date);

    input.onChange(momentDate.isValid() ? momentDate.format(DATE_FORMAT) : '');
  };

  return (
    <div className={touched && error ? 'has-error' : ''}>
      <div className="icon-cal">
        <DatePicker
          {...input}
          {...props}
          locale="en-gb"
          customInput={<img alt={''} src={require('./image/icon-cal.svg')}/>}
          placeholder={placeholder}
          dateFormat={DATE_FORMAT}
          selected={input.value ? moment(input.value, DATE_FORMAT) : null}
          onChange={handleChange}
          disabledKeyboardNavigation={false}
        />
      </div>
      {/*{touched && error && <HelpBlock>{error}</HelpBlock>}*/}
    </div>
  );
};

export default AlternativeDatePicker;
