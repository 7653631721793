import * as React from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { IBrandingState } from '../../../../services/branding/models';
import { IStore } from '../../../../redux/IStore';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import steps from '../../steps';
import { push } from 'react-router-redux';

interface IRetrieveQuote extends RouteComponentProps<any> {
  quote: IQuoteState;
  branding: IBrandingState;
  actions: {
    quote: IQuoteActions,
    push: (path: string) => any;
  };
}

class RetrieveQuote extends React.Component<IRetrieveQuote, any> {

  constructor(props) {
    super(props);
    this.retrieveQuote()
  }

  private retrieveQuote = () => {
    const { actions, match, branding } = this.props;
    const quoteSteps = steps(branding.channel);

    actions.quote.retrieveQuoteFromId(match.params.id).then(() => {
      actions.quote.isRetrievingQuote(false)
      actions.push(quoteSteps[0].route);
    }).catch(() => {
      actions.push(quoteSteps[0].route);
    });

  }

  public render() {
    return null;
  }
}

export default flow([
  connect(
    (state: IStore) => ({
      branding: state.branding,
      quote: state.quote,
    }),
    (dispatch) => ({
      actions: {
        push: bindActionCreators(push, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(RetrieveQuote);
