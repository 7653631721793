import { ICustomerPaymentMethod } from '../models';
import { get } from 'lodash';

export function fromJsonApi(data): ICustomerPaymentMethod {
  return {
    id: get(data, 'id'),
    assigned: get(data, 'attributes.assigned'),
    expiry: get(data, 'attributes.expiry'),
    permissions: get(data, 'attributes.permissions'),
    status: get(data, 'attributes.status'),
    maskedPan: get(data, 'attributes.metadata.maskedPAN'),
  };
}
