import * as React from 'react';
import { setAnswer } from '../../../../../../services/verisk/reducer';
import { Button, Col, ControlLabel, FormControl, FormGroup, InputGroup, Row } from 'react-bootstrap';
import { ConditionQuestionEdit } from '../ConditionQuestionEdit';

export default class BmiQuestion extends React.Component<any, any> {
  public state = {
    dirty: false,
    isMetricSystem: true,
    height1: '',
    height2: '',
    weight1: '',
    weight2: '',
  };

  public componentWillReceiveProps(props) {
    if (!this.state.dirty) {
      this.setState({
        dirty: false,
        ...props.question.attributes.data,
      });
    }
  }

  private changeUnitSystem = (system) => () => {
    const isMetricSystem = system === 'metric';

    this.setState({
      isMetricSystem,
      height1: '',
      height2: '',
      weight1: '',
      weight2: '',
    });
  }

  private handleSubmit = () => {
    const { condition, question, dispatch } = this.props;

    dispatch(setAnswer(condition, question, this.state, true));
  }

  private handleOnChange = (value) => {
    return (event) => {
      this.setState({
        dirty: true,
        [value]: event.target.value,
      });
    };
  }

  public render() {
    const { screening, question, dispatch, condition } = this.props;
    const { isMetricSystem, height1, height2, weight1, weight2 } = this.state;
    const isActiveQuestion = question?.attributes?.isActive || false;

    return (
      <>
        <div className="single-choice-question row">
          <div className="col-xs-12">
            <div className="form-group">
              <label className="control-label">Please enter your height and weight.</label>
              {question.attributes.info ? (
                <p className="text-info" style={{ fontSize: '14px' }}>
                  {question.attributes.info}
                  <br/>
                </p>
              ) : ''}

              {question.attributes.warning ? (
                <p className="text-danger" style={{ fontSize: '14px' }}>
                  {question.attributes.warning}
                  <br/>
                </p>
              ) : ''}

              {!isActiveQuestion && (
                <div className="row">
                  <div className="col-sm-12">
                    <ConditionQuestionEdit
                      selectedAnswers={question?.attributes?.data}
                      screening={screening}
                      dispatch={dispatch}
                      condition={condition}
                      question={question}
                      isBMIObject={true}
                    />
                  </div>
                </div>
              )}

            </div>

            {isActiveQuestion && (
              <>
                <div className="row">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className={'radio-container radio-inline'}>
                        <div className={'form-radio '}>
                          <input type="checkbox"
                                 id={'metric-bmi-choice'}
                                 checked={isMetricSystem}
                                 onChange={this.changeUnitSystem('metric')}
                          />
                          <label htmlFor={'metric-bmi-choice'} style={{ paddingLeft: 10 }}>
                            Metric measure?
                          </label>
                        </div>
                      </div>
                      <div className={'radio-container radio-inline'}>
                        <div className={'form-radio radio-inline'}>
                          <input type="checkbox"
                                 id={'imperial-bmi-choice'}
                                 checked={!isMetricSystem}
                                 onChange={this.changeUnitSystem('imperial')}
                          />
                          <label htmlFor={'imperial-bmi-choice'} style={{ paddingLeft: 10 }}>
                            Imperial measure?
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <Row>
                  <Col sm={12} style={{ marginBottom: 5 }}>
                    {isMetricSystem ? (
                      <FormGroup controlId="formHeight">
                        <Col componentClass={ControlLabel} sm={2}>
                          Height
                        </Col>
                        <Col xs={2}>
                          <InputGroup>
                            <FormControl type="number" value={height1} onChange={this.handleOnChange('height1')}
                                         style={{ width: 80 }}/>
                            <InputGroup.Addon>cm</InputGroup.Addon>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    ) : (
                      <FormGroup controlId="formHeight">
                        <Col componentClass={ControlLabel} sm={2}>
                          Height
                        </Col>
                        <Col xs={2}>
                          <InputGroup>
                            <FormControl type="number" value={height1} onChange={this.handleOnChange('height1')}
                                         style={{ width: 80 }}/>
                            <InputGroup.Addon>ft</InputGroup.Addon>
                          </InputGroup>
                        </Col>
                        <Col xs={2}>
                          <InputGroup>
                            <FormControl type="number" value={height2} onChange={this.handleOnChange('height2')}
                                         style={{ width: 80 }}/>
                            <InputGroup.Addon>in</InputGroup.Addon>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    )}
                  </Col>
                  <Col sm={12} style={{ marginBottom: 5 }}>
                    {isMetricSystem ? (
                      <FormGroup controlId="formWeight">
                        <Col componentClass={ControlLabel} sm={2}>
                          Weight
                        </Col>
                        <Col sm={2}>
                          <InputGroup>
                            <FormControl type="number" value={weight1} onChange={this.handleOnChange('weight1')}
                                         style={{ width: 80 }}/>
                            <InputGroup.Addon>kg</InputGroup.Addon>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    ) : (
                      <FormGroup controlId="formWeight">
                        <Col componentClass={ControlLabel} sm={2}>
                          Weight
                        </Col>
                        <Col sm={2}>
                          <InputGroup>
                            <FormControl type="number" value={weight1} onChange={this.handleOnChange('weight1')}
                                         style={{ width: 80 }}/>
                            <InputGroup.Addon>st</InputGroup.Addon>
                          </InputGroup>
                        </Col>
                        <Col sm={2}>
                          <InputGroup>
                            <FormControl type="number" value={weight2} onChange={this.handleOnChange('weight2')}
                                         style={{ width: 80 }}/>
                            <InputGroup.Addon>lb</InputGroup.Addon>
                          </InputGroup>
                        </Col>
                      </FormGroup>
                    )}
                  </>
                </Row>
              </>
            )}
          </div>

          {isActiveQuestion && <div>
            <Col sm={12}>
              <Button
                bsStyle="primary"
                disabled={screening.isSubmitting}
                onClick={this.handleSubmit}
              >
                Set Height and Weight
              </Button>
            </Col>
          </div>}

        </div>
        <div className={'screening-border-bottom'}/>
      </>
    );
  }
}
