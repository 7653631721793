import 'intl';
import 'intl/locale-data/jsonp/en-GB';

import axios from 'axios';
import * as e6p from 'es6-promise';
import * as React from 'react';
import { hydrate, unmountComponentAtNode } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { connect, Provider } from 'react-redux';
import { ConnectedRouter } from 'react-router-redux';
import { createBrowserHistory } from 'history';
import { configureStore } from './app/redux/store';
import App from './app';
import * as a11y from 'react-a11y';
// import * as injectTapEventPlugin from 'react-tap-event-plugin';
import { AppContainer } from 'react-hot-loader';
import { JobProvider } from 'react-jobs';
import { Modal } from 'react-overlays';
import AuthInterceptor from './app/helpers/AuthInterceptor';
import { IStore } from './app/redux/IStore';
import ReduxToastr from 'react-redux-toastr'
import { logoutTimer } from './app/data/api/timer';
// import * as ReactGA from 'react-ga';

// injectTapEventPlugin();

e6p.polyfill();

if (process.env.NODE_ENV === 'local') {
  a11y(React);
}

Modal.prototype.componentWillMount = function () {
  this.focus = () => null;
};

axios.defaults.baseURL = window.__API_URL__;

const rehydrateState = window.__JOBS_STATE__;
const history = createBrowserHistory();
const store = configureStore(
  history,
  window.__INITIAL_STATE__,
);
const mountNode = document.getElementById('app');

let Client = ({ Component, branding }) => {
  return (
    <IntlProvider locale={branding.locale} messages={branding.i18n}>
      <ConnectedRouter
        history={history}
      >
        <Component branding={branding}/>
      </ConnectedRouter>
    </IntlProvider>
  );
};

Client = connect((state: IStore) => ({ branding: state.branding }))(Client);

const renderIt = (Component, branding) => {

  return hydrate(
    <AppContainer>
      <JobProvider rehydrateState={rehydrateState}>
        <Provider store={store}>
          <div>
            <Client Component={Component} branding={branding}/>
            <ReduxToastr
              timeOut={4000}
              newestOnTop={true}
              preventDuplicates={true}
              position="top-right"
              progressBar={true}
              closeOnToastrClick={true}/>
          </div>
        </Provider>
      </JobProvider>
    </AppContainer>,
    mountNode,
  );
};

renderIt(App);

if (module.hot) {
  module.hot.accept('./app', () => {
    const newApp = require('./app').default;

    setImmediate(() => {
      // Preventing the hot reloading error from react-router
      unmountComponentAtNode(mountNode);
      renderIt(newApp);
    });
  });
}
