import { Col, HelpBlock, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FieldFormControl from '../../../../../../../components/FieldFormControl';
import { isRequired, mustBeFalse } from '../../../../../../../util/validators';
import * as React from 'react';
import { get } from 'lodash';
import { windowSize } from '../../../../../../../hooks/windowSize';

export function DynamicComponent(
  {
    title,
    validationMessage = 'both',
    name,
    active,
    bodyText,
    dynamicComponent,
    mustBeNo,
    props,
    optionKeyName,
  }) {

  const validate = mustBeNo ? [isRequired, mustBeFalse] : [isRequired];

  const error = get(props, `synchronousError.options.${optionKeyName}`, null);
  const isMobile = windowSize(600).isMobile;
  let hasIcon = false;
  let source = '';

  switch (optionKeyName) {
    case 'hazardous_activities':
      hasIcon = true;
      source = get(props, `options.${optionKeyName}`, false) ? require('../../../img/haz_a_colored.svg') : require('../../../img/haz_a_grey.svg');
      break;
  }

  return (
    <div className="medical-question medical-section" key={name}>
      <Row>
        {hasIcon && (
          <Col sm={7}>
            <div className="question-text">
              <p>
                {!isMobile && (
                  <>
                    <img src={source} alt="Admiral" className="additional-cover-icon-desktop"/>
                    <div className="title">{title}</div>
                    <div>{bodyText}</div>
                  </>
                )}
                {isMobile && (
                  <>
                    <div style={{ display: 'flex' }}>
                      <div><img src={source} alt="Admiral" className="additional-cover-icon-mobile"/></div>
                      <div className="title" style={{ paddingTop: '15px' }}>{title}</div>
                    </div>
                    <div>{bodyText}</div>
                  </>
                )}
              </p>
              {active && dynamicComponent}
            </div>
          </Col>
        )}
        {!hasIcon && (
          <Col sm={7}>
            <div className="question-text">
              <div className="title">
                {title}
              </div>
              <p>
                {bodyText}
              </p>
              {active && dynamicComponent}
            </div>
          </Col>
        )}
        <Col sm={5}>
          <Row>
            <Col xs={12}>

              <Col xs={6} className="radio-button-yes">
                <Field
                  name={name}
                  type="radio"
                  format={null}
                  value={true}
                  complexValue={true}
                  className="answer"
                  validationMessage={validationMessage}
                  component={FieldFormControl}
                  validate={validate}
                >

                  <div className="medical-btn yes">
                    Yes
                  </div>
                </Field>
              </Col>
              <Col xs={6} className="radio-button-no">
                <Field
                  name={name}
                  type="radio"
                  format={null}
                  value={false}
                  complexValue={true}
                  className="answer"
                  validationMessage={validationMessage}
                  component={FieldFormControl}
                  validate={validate}
                >
                  <div className="medical-btn no">
                    No
                  </div>
                </Field>
              </Col>
              {error && validationMessage !== 'both' && props.submitFailed && (
                <Row style={{ textAlign: 'center' }}>
                  <Col>
                    <div className={error ? 'has-error' : ''}>
                      {<HelpBlock>{error}</HelpBlock>}
                    </div>
                  </Col>
                </Row>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
