import * as React from 'react';
import { Field } from 'redux-form';
import SelectControl from '../SelectControl';

const childTitleList = [
  {label: 'Mr', value: 'Mr'},
  {label: 'Mrs', value: 'Mrs'},
  {label: 'Miss', value: 'Miss'},
  {label: 'Ms', value: 'Ms'},
  {label: 'Master', value: 'Master'},
  {label: 'Mx', value: 'Mx'},
  {label: 'Dr', value: 'Dr'},
  {label: 'Prof', value: 'Prof'},
  {label: 'Rev', value: 'Rev'},
  {label: 'Cpt', value: 'Cpt'},
  {label: 'Sir', value: 'Sir'},
  {label: 'Dame', value: 'Dame'},
  {label: 'Lord', value: 'Lord'},
  {label: 'Lady', value: 'Lady'},
];

const adultTitleList = [
  {label: 'Mr', value: 'Mr'},
  {label: 'Mrs', value: 'Mrs'},
  {label: 'Miss', value: 'Miss'},
  {label: 'Ms', value: 'Ms'},
  {label: 'Master', value: 'Master'},
  {label: 'Mx', value: 'Mx'},
  {label: 'Dr', value: 'Dr'},
  {label: 'Prof', value: 'Prof'},
  {label: 'Rev', value: 'Rev'},
  {label: 'Cpt', value: 'Cpt'},
  {label: 'Sir', value: 'Sir'},
  {label: 'Dame', value: 'Dame'},
  {label: 'Lord', value: 'Lord'},
  {label: 'Lady', value: 'Lady'},
];

interface IProps {
  name: string;
  validate?: any;
  type: string;
  disabled?: boolean;
  className?: string;
}

export default class TitleField extends React.Component<IProps> {
  public render() {
    const { name, validate, type, className } = this.props;

    let component = null;

    switch (type) {
      case 'child':
        component = (
          <Field
            name={name}
            className={className ? className : 'dropdown-tex'}
            placeholder={'Title'}
            component={SelectControl}
            options={childTitleList}
            validate={validate}
            disabled={this.props.disabled}
          />
        );
        break;
      default:
        component = (
          <Field
            name={name}
            className={className ? className : 'dropdown-tex'}
            placeholder={'Title'}
            component={SelectControl}
            forceFilter={false}
            options={adultTitleList}
            validate={validate}
            disabled={this.props.disabled}
          />
        );
        break;
    }
    return (
      <div>
        {component}
      </div>
    );
  }
}
