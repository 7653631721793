import { routerMiddleware } from 'react-router-redux';
import { applyMiddleware, compose, createStore, Store, Middleware } from 'redux';
import thunk from 'redux-thunk';
import appConfig from '../../../config/main';
import { IStore } from './IStore';
import rootReducer from './reducers';

export function configureStore(history, initialState?: IStore): Store<IStore> {
  const middlewares: Middleware[] = [
    routerMiddleware(history),
    thunk,
  ];

  const composeEnhancers = (appConfig.env !== 'production' &&
    typeof window === 'object' &&
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

  let store: Store<IStore>;
  store = createStore(rootReducer, initialState, composeEnhancers(
    applyMiddleware(...middlewares),
  ));

  if (appConfig.env === 'local' && (module as any).hot) {
    (module as any).hot.accept('./reducers', () => {
      store.replaceReducer((require('./reducers')));
    });
  }

  return store;
}
