import * as React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { flow } from 'lodash';

interface INavLink {
  to: string;
  location: any;
  onSelect?: () => void;
}

class NavLink extends React.Component<INavLink> {

  private handleOnSelect = () => {
    const { onSelect } = this.props;

    if (onSelect) {
      onSelect();
    }
  }

  public render() {
    const { to, location } = this.props;

    const isActive = location.pathname.includes(this.props.to);
    const className = isActive ? 'active' : '';

    return (
      <li>
        <Link className={className} to={to} onClick={this.handleOnSelect}>
          {this.props.children}
        </Link>
      </li>
    );
  }
}

export default flow([
  withRouter,
])(NavLink);
