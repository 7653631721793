import * as React from 'react';

interface InfoAnnieProps {
  title?: string | any;
  text?: any;
  color?: string;
}

export const InfoAnnie = ({ title, text = '', color = 'blue' }: InfoAnnieProps) => {

  let onlyTitle = false;

  if (title && !text) {
    onlyTitle = true;
  }

  return (
    <div className={'info-annie-container ' + color}>
      <div className={'annie-img'}><img src={require('./img/annie-blue.png')} alt={'Admiral'}/></div>
      <div className={'content'}>
        {title && <div className={onlyTitle ? 'title standalone' : 'title'}>{title}</div>}
        {text && <div className={'text'}>{text}</div>}
      </div>
    </div>
  )
}
