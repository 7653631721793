import * as React from 'react';
import { flow } from 'lodash';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { actions as brandingActions } from '../../../../services/branding/reducer';
import { IBrandingActions, IBrandingState } from '../../../../services/branding/models';
import { withJob } from 'react-jobs';
import Loading from '../../../../components/Loading';
import { IStore } from '../../../../redux/IStore';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { getAggregatorPolicyApplication } from '../../../../data/api/api';
import * as queryString from 'query-string';

interface IRetrieveAggregatorQuote extends RouteComponentProps<any> {
  quote: IQuoteState;
  branding: IBrandingState;
  actions: {
    branding: IBrandingActions,
    quote: IQuoteActions,
  };
}

class RetrieveAggregatorQuote extends React.Component<IRetrieveAggregatorQuote> {
  public render() {
    const { branding, quote, match } = this.props;

    if (quote.application && quote.application.id === match.params.id) {
      return (<Redirect to={branding.channel.steps[0].route} />);
    }

    return null;
  }
}

export default flow([
  withJob({
    work: (props: IRetrieveAggregatorQuote) => {

      if (! props.quote.application || props.quote.application.id !== props.match.params.id) {
        getAggregatorPolicyApplication(props.match.params.id).then((policyApplication) => {
          props.actions.quote.isRetrievingQuote(true)
          policyApplication.steps = [policyApplication.channel.steps[0].key];

          props.actions.branding.setChannel(policyApplication.channel);
          props.actions.quote.setCustomer(policyApplication.customer);
          props.actions.quote.setQuotes(policyApplication.quotes);
          props.actions.quote.setApplication(policyApplication);
          props.actions.quote.isRetrievingQuote(false)
        });

        const query = queryString.parse(props.location.search);

        if (query.reference) {
          props.actions.branding.setAggregatorTracking({
            type: 'idol',
            idol: {
              reference: query.reference,
            },
          });
        }

        if (query.cref) {
          props.actions.branding.setAggregatorTracking({
            type: 'cyti',
            cyti: {
              cref: query.cref,
              url: query.u,
              token: query.t,
            },
          });
        }
      }

    },
    LoadingComponent: (props) => <Loading />,
    ErrorComponent: ({ error }) => {
      console.error(error);

      return (
        <div>
          {error.message}
        </div>
      );
    },
  }),
  connect(
    (state: IStore) => ({
      branding: state.branding,
      quote: state.quote,
    }),
    (dispatch) => ({
      actions: {
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        branding: bindActionCreators({ ...brandingActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(RetrieveAggregatorQuote);
