import { Alert } from 'react-bootstrap';
import * as React from 'react';

export const AlertConditionSearch = ({ text }) => {
  return (
    <div className="col-xs-12">
      <Alert bsStyle="danger">
        {text}
      </Alert>
    </div>
  );
};
