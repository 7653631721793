import * as api from '../../data/api/api';
import { IStore } from '../../redux/IStore';
import { filter, head } from 'lodash';
import { IContentActions } from './models';

const REQUESTING_CONTENT = 'REQUESTING_CONTENT';
const SET_CONTENT = 'SET_CONTENT';

const initialState = {
  requesting: false,
  content: [],
};

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case REQUESTING_CONTENT:
      return {
        ...state,
        requesting: action.requesting,
      };

    case SET_CONTENT:
      return {
        ...state,
        content: action.data,
      };

    default:
      return state;
  }
};

const requestingContent = (requesting) => {
  return {
    type: REQUESTING_CONTENT,
    requesting,
  };
};

const setContent = (content) => {
  return {
    type: SET_CONTENT,
    data: content,
  };
};

const getContent = (filters = []) => (dispatch) => {
  dispatch(requestingContent(true));
  return api
    .listContents(filters)
    .then((content) => {
      dispatch(setContent(content));
      dispatch(requestingContent(false));
    });
};

const getContentByName = (name) => (dispatch, getState) => {
  const state: IStore = getState().content.content;
  return head(filter(state, ['name', name])) || null;
}

export const actions: IContentActions = {
  requestingContent,
  setContent,
  getContent,
  getContentByName,
};
