import * as React from 'react';
import { flow, forEach } from 'lodash';
import { connect } from 'react-redux';
import { formValueSelector } from 'redux-form';
import { IPremium } from '../../../../data/api/models';
import { IStore } from '../../../../redux/IStore';
import { ICompleteData } from '../../../../services/quote/models';

interface ITrackingProps {
  premium: IPremium;
  complete: ICompleteData;
  values: {
    tripType: string;
    schemeId: string;
    quoteReference: string;
    campaignCode: string;
  };
}

class Tracking extends React.Component<ITrackingProps> {
  public quoteTracking() {
    const values = this.props.values;

    if (! values.tripType) {
      return;
    }

    return [
      <input key={1} type="hidden" id="track_purchased" value={!! this.props.complete ? 'true' : 'false'} />,
      <input key={2} type="hidden" id="track_trip_type" value={values.tripType} />,
      <input key={3} type="hidden" id="track_quote_reference" value={values.quoteReference} />,
      <input key={4} type="hidden" id="track_campaign_code" value={values.campaignCode} />,
    ];
  }

  public completeTracking() {
    const complete = this.props.complete;

    if (! complete) {
      return;
    }

    return [
      <input key={1} type="hidden" id="track_policy_number" value={complete.policy_number} />,
    ];
  }

  public premiumTracking() {
    const premium = this.props.premium;

    if (! premium) {
      return;
    }

    return [
      <input key={1} type="hidden" id="track_premium_gross" value={premium.gross} />,
      <input key={2} type="hidden" id="track_premium_currency" value={premium.currency} />,
      <input key={3} type="hidden" id="track_premium_principal" value={premium.principal} />,
      <input key={4} type="hidden" id="track_premium_gross_ex_additional" value={premium.grossExAdditional} />,
      <input key={5} type="hidden" id="track_premium_tax" value={premium.tax} />,
      <input key={6} type="hidden" id="track_premium_ex_tax" value={premium.exTax} />,
      <input key={7} type="hidden" id="track_premium_scheme_sku" value={premium.scheme.id} />,
      <input key={8} type="hidden" id="track_premium_scheme_name" value={premium.scheme.name} />,
      <input key={9} type="hidden" id="track_premium_scheme_insurer" value={premium.scheme.insurer} />,
      <input key={10} type="hidden" id="track_premium_scheme_cover_level" value={premium.scheme.coverLevel} />,
    ];
  }

  public render() {
    return (
      <div>
        {this.completeTracking()}
        {this.premiumTracking()}
        {this.quoteTracking()}
      </div>
    );
  }
}

let selector;

export default flow([
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      let premium: IPremium = null;

      const values = selector(
        state,
        'tripType',
        'schemeId',
        'quoteReference',
        'campaignCode',
      );

      if (state.quote.quotes) {
        forEach(state.quote.quotes, (tripTypes, level) => {
          forEach(tripTypes, (quotedPremium: IPremium) => {
            if (!quotedPremium) {
              return null;
            }

            if (quotedPremium.scheme.id === values.schemeId) {
              premium = quotedPremium;
            }
          });
        });
      }

      return {
        premium,
        values,
        complete: state.quote.complete,
      };
    },
  ),
])(Tracking);
