import * as React from 'react';
import { flow, forEach, get, has, includes, keys, map, values } from 'lodash';
import { II18nMessages } from '../../../../services/branding/models';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { Field, formValueSelector, getFormSyncErrors, InjectedFormProps, reduxForm, submit } from 'redux-form';
import { IBenefit, IChannel, IOptionMap, IPremium, IProduct } from '../../../../data/api/models';
import SelectControl from '../../../../components/SelectControl';
import { IQuoteActions, IQuoteState } from '../../../../services/quote/models';
import { isRequired } from '../../../../util/validators';
import SaveQuoteModal from '../../components/SaveQuoteModal';
import FieldFormControl from '../../../../components/FieldFormControl';
import { connect } from 'react-redux';
import * as Commonmark from 'react-commonmark';
import CoverSummary from './components/CoverSummary';
import { FaCheck, FaSpinner, FaTimes } from 'react-icons/fa';
import { bindActionCreators } from 'redux';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { push } from 'react-router-redux';
import { IStore } from '../../../../redux/IStore';
import { DynamicTextWithAdd } from './components/QuestionOptions/DynamicTextWithAdd';
import { DynamicComponent } from './components/QuestionOptions/DynamicComponent';

interface IAdditionalCoverForm extends InjectedFormProps {
  i18n: II18nMessages;
  quote: IQuoteState;
  product: IProduct;
  visibility: { options: any };
  startDate: string;
  endDate: string;
  pristine: boolean;
  submitting: boolean;
  onBack: () => void;
  options: IOptionMap;
  channel: IChannel;
  dispatch: any;
  premium: IPremium;

  renderDefinition(name: string): any;

  actions: {
    submit(name: string);
    push: (route: string) => void;
    quote: IQuoteActions;
  };
  synchronousError: any;
}

class AdditionalCoverForm extends React.Component<IAdditionalCoverForm> {
  public componentWillMount() {
    const { product, change } = this.props;

    forEach(product.metadata.options, (option, key) => {

      if (!option.default) {
        return;
      }

      if (!has(product.metadata.options, key)) {
        change(`options.${key}`, option.default);
      }
    });
  }

  public componentDidMount() {
    const { options, change } = this.props;
    const allOptions = ['golf_cover', 'winter_sports', 'cruise_cover', 'gadget_cover', 'excess_waiver'];
    allOptions.forEach((key, value) => {
      if (options[key]) {
        change(`visibility.options.${key}`, true);
      }
    });
  }

  private recalculatePrice = () => {
    const { actions } = this.props;
    setTimeout((() => {
      actions.quote.calculate(null, true);
    }), 300);
  }

  public componentWillReceiveProps(nextProps) {
    const { quote } = this.props;

    const quotes = quote.quotes;
    const coverLevel = Object.keys(quotes)[0];
    const tripType = Object.keys(quotes[coverLevel])[0];

    const quotedCover = quotes[coverLevel][tripType];

    if (!quotedCover.information) {
      return null;
    }

  }

  private renderListOption = (option, key) => {
    const list = map(option.items, (item, key) => ({
      label: item,
      value: key,
    }));

    return (
      <div key={key}>
        <Row>
          <Col md={12} lg={6}>
            <div className="question-text">
              <div>{option.question}</div>
            </div>
          </Col>
          <Col md={12} lg={6}>
            <Field
              name={`options.${key}`}
              type="select"
              options={list}
              component={SelectControl}
              clearable={false}
              validate={[isRequired]}
            />
          </Col>
        </Row>
      </div>
    );
  }

  private renderBooleanOption = (option, key) => {
    const { renderDefinition } = this.props;

    return (
      <div className="radio-question additional-option" key={key}>
        <Row>
          <Col md={12} lg={8}>
            <div className="question-text">
              <div>
                {option.question} {renderDefinition(key)}
              </div>
            </div>
          </Col>
          <Col md={12} lg={4}>
            <Field
              name={`options.${key}`}
              type="radio"
              value={false}
              complexValue={true}
              className="answer"
              component={FieldFormControl}
              validate={[isRequired]}
            >
              <div className="medical-btn">
                <FaTimes/> No
              </div>
            </Field>
            <Field
              name={`options.${key}`}
              type="radio"
              value={true}
              complexValue={true}
              className="answer"
              component={FieldFormControl}
              validate={[isRequired]}
            >
              <div className="medical-btn">
                <FaCheck/> Yes
              </div>
            </Field>
          </Col>
        </Row>
      </div>
    );
  }

  // private standardiseTheName = (name) => startCase(name.replace('-', ' '));

  private renderQuestionOption = (option, key, exclude) => {
    const { quote, channel, visibility, product, options, premium } = this.props;
    const quotes = quote.quotes;
    const coverLevel = keys(quotes)[0];
    const tripType = keys(quotes[coverLevel])[0];
    const quotedCover = quotes[coverLevel][tripType];

    // TODO likely to include gadget and golf options all tiers so unsure if this will be relevant
    const boltOns = quotedCover.information.boltOns;
    const currency = quotedCover.currency;
    const boltOn = boltOns ? boltOns[key] : null;
    const boltOnTotal = boltOn ? boltOn.total : 0;

    const channelType = channel.channelType;
    const aggregatorChannel = channelType === 'AGG';
    const isAggregatorCruiseCover = key === 'aggregator_cruise_cover';
    const hasAggregatorCruiseCoverBenefit = includes(keys(boltOns), 'aggregator_cruise_cover');
    const isCruiseCover = key === 'cruise_cover';
    const isGolfCover = key === 'golf_cover';
    const benefits: IBenefit[]  = get(quotedCover, 'scheme.allBenefits', []);

    // some of the options/bolt ons are shown on the trip details page so don't need to be shown here
    if (exclude.includes(key)) {
      return;
    }

    if (key === 'hazardous_activities') {
      const dynamicComponent = [(
        <p>If you intend in taking part in activities that may be considered hazardous or dangerous
          during your trip please check
          the <a href={premium && premium.scheme.documents['Policy Wording']}
                 target="_blank">policy wording</a> or call our
          customer services on 0333 234 9913 as this may need to be checked by our Underwriting team before
          acceptance. All hazardous or dangerous activities must be added to a policy in order to be covered.</p>
      )];

      return (
        <DynamicComponent name={`options.${key}`} title={option.question} key={key}
                          bodyText={product.metadata.definitions[`${key}_alt_title`]}
                          active={options && options[`${key}`]}
                          props={this.props}
                          optionKeyName={key}
                          validationMessage={'single'}
                          dynamicComponent={dynamicComponent}
                          mustBeNo={true}/>
      );
    }

    let bodyText = '';
    let dynamicText = '';
    if (isGolfCover) {
      bodyText = product.metadata.definitions[`${key}_alt_title`];
      dynamicText = product.metadata.definitions[`${key}_alt_dynamic`];
    } else {
      bodyText = product.metadata.definitions[`${key}_alt_${coverLevel}`];
      dynamicText = product.metadata.definitions[`${key}_alt_dynamic`];
    }

    if (boltOnTotal === 0) {
      return null;
    }

    if (!aggregatorChannel && isAggregatorCruiseCover) {
      return null;
    }

    if (
      aggregatorChannel
      && (
        (hasAggregatorCruiseCoverBenefit && isCruiseCover)
        || (!hasAggregatorCruiseCoverBenefit && isAggregatorCruiseCover)
      )
    ) {
      return null;
    }

    if (!quotedCover.information) {
      return null;
    }

    // When no is clicked set the add button to false so it is unselected and recalculate the price
    const unselectAdd = () => {
      const { options } = this.props;
      if (options[key]) {
        this.props.change(`options.${key}`, null);
        this.recalculatePrice();
      }
    };

    return (
      <DynamicTextWithAdd title={option.question}
                          dynamicText={dynamicText} bodyText={bodyText}
                          name={`options.${key}`}
                          key={key}
                          onOptionClick={this.recalculatePrice}
                          visibilityOfDynamicText={visibility && visibility.options[key]}
                          disabledCase={quote.isCalculating}
                          props={this.props}
                          optionKeyName={key}
                          validationMessage={'single'}
                          currency={currency} boltOnTotal={boltOnTotal}
                          visibilityOfAddButton={visibility && visibility.options[key]}
                          benefits={benefits}
                          unselectAdd={unselectAdd}/>
    );

  }

  private renderOption = (option, key) => {
    const exclude = ['winter_sports', 'cruise_cover', 'golf_cover'];
    switch (option.type) {
      case 'boolean':
        return this.renderBooleanOption(option, key);
      case 'list':
        return this.renderListOption(option, key);
      case 'question':
        return this.renderQuestionOption(option, key, exclude);
      default:
        return null;
    }
  }

  private transformLinks = (url) => {
    const { product } = this.props;

    if (url === 'hazardous_activities') {
      return product.documents[Object.keys(product.documents)[0]];
    }

    return url;
  }

  private checkForHazardousActivity = () => {
    const { actions, options, synchronousError } = this.props;
    Promise.resolve(actions.submit('quote')).then(() => {
      const hazardousActivity = options && options.hazardous_activities;

      if (hazardousActivity) {
        window.scrollTo(0, 0);
      }

      if (synchronousError && synchronousError.visibility) {
        window.scrollTo(0, 0);
      }
    });

  }

  public render() {
    const {
      handleSubmit, submitting, valid, product, quote, submitFailed,
      visibility, options, change,
    } = this.props;
    const quotes = quote.quotes;
    const hasFinishedCalculation = values(quotes).length === 1;

    // Manual reordering of options as they want hazardousActivity at the bottom
    const extensionsOfCOver = {
      excess_waiver: product.metadata.options.excess_waiver,
      gadget_cover: product.metadata.options.gadget_cover,
    };
    const additionalCover = {
      hazardous_activities: product.metadata.options.hazardous_activities,
      golf_cover: product.metadata.options.golf_cover,
      winter_sports: product.metadata.options.winter_sports,
      cruise_cover: product.metadata.options.cruise_cover,
    };

    const hazardousActivity = options && options.hazardous_activities;

    const coverLevel = Object.keys(quotes)[0];
    let tripType = '';
    let quotedCover = null;
    if (coverLevel) {
      tripType = Object.keys(quotes[coverLevel])[0];
    }
    if (tripType) {
      quotedCover = quotes[coverLevel][tripType];
    }
    if (quotedCover && quotedCover.information.medicalCoverForCovid) {
      change('medicalCoverForCovid', quotedCover.information.medicalCoverForCovid);
    }

    return (
      <Form onSubmit={handleSubmit} autoComplete="off">
        {isRequired && submitFailed && !hazardousActivity && !valid && (
          <div className="error-block-container" id="error-container">
            <h1>Whoops!</h1>
            <p>Missing something? Please check the areas marked in orange before you continue.</p>
          </div>
        )}
        {(submitFailed && !valid && hazardousActivity) && (
          <div className="error-block-container">
            <h1>Whoops!</h1>
            <p>As you have identified that you will be taking part in a hazardous activity,
              please call us on 0333 234 991 so we can discuss this.</p>
          </div>
        )}
        <div className="steps-container">
          <div className="section-title">
            <h2>Extensions of Cover</h2>

            {product.metadata.hazardous_activities && product.documents && (
              <Commonmark
                source={product.metadata.hazardous_activities}
                linkTarget="_blank"
                transformLinkUri={this.transformLinks}
              />
            )}
          </div>

          <CoverSummary product={product} showOnlyTotal={true}/>

          <h4 className="choose-from-a-range-title">Choose from a range of optional extras to add to your cover. </h4>

          <div className="medical-section-group options-section-group">
            {hasFinishedCalculation ? (
              map(extensionsOfCOver, this.renderOption)
            ) : (
              <FaSpinner className="fa-spin"/>
            )}

          </div>

          <div className="section-title">
            <h2>Additional Cover</h2>
          </div>
          <h4>Choose from a range of optional extras to add to your cover. </h4>

          <div className="medical-section-group options-section-group">
            {hasFinishedCalculation ? (
              map(additionalCover, this.renderOption)
            ) : (
              <FaSpinner className="fa-spin"/>
            )}

            <hr/>

          </div>

          <CoverSummary product={product}/>

          <div className="btn-bar">
            <SaveQuoteModal {...this.props} buttonText={'SAVE FOR LATER'} disabled={!valid}/>
            <Button
              bsStyle="primary"
              type="submit"
              id="continue"
              className="pull-right"
              bsSize="lg"
              onClick={this.checkForHazardousActivity}
              // If golf or gadget_cover are marked as yes without the add button being added then submission will fail
              disabled={submitting || (submitFailed && !valid) ||
                (visibility && visibility.options.golf_cover && !options.golf_cover) ||
                (visibility && visibility.options.gadget_cover && !options.gadget_cover) ||
                (visibility && visibility.options.excess_waiver && !options.excess_waiver) ||
                quote.isCalculating}
            >
              PAY NOW
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

let selector;

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const quote = state.quote;
      const options = selector(state, 'options');
      const visibility = selector(state, 'visibility');
      const tripType = selector(state, 'tripType');
      const schemeId = selector(state, 'schemeId');
      const startDate = selector(state, 'startDate');
      const endDate = selector(state, 'endDate');
      const channel = state.branding.channel;
      const quotes = state.quote.quotes;
      const synchronousError = getFormSyncErrors('quote')(state);

      let premium = null;

      forEach(quotes, (tripTypes, level) => {
        forEach(tripTypes, (quotedPremium) => {
          if (quotedPremium.scheme.id === schemeId) {
            premium = quotedPremium;
          }
        });
      });

      return {
        options,
        quote,
        schemeId,
        startDate,
        endDate,
        channel,
        visibility,
        tripType,
        premium,
        synchronousError,
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        submit: bindActionCreators(submit, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        push: bindActionCreators(push, dispatch),
      },
    }),
  ),
])(AdditionalCoverForm);
