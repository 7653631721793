import { IPaymentMethod } from '../models';
import { get } from 'lodash';

export function fromJsonApi(data): IPaymentMethod {
  let paymentData = { id: null, type: null };
  const payload = get(data, 'relationships.active_customer_payment_method.data');
  if (payload) {
    paymentData = payload
  }
  return {
    id: paymentData.id || '',
    type: paymentData.type || '',
  };
}

export function paymentMethodsOnPolicy(data): IPaymentMethod {
  let paymentData = { id: null, type: null };
  const payload = get(data, 'relationships.policy_customer_payment_method.data');
  if (payload) {
    paymentData = payload
  }
  return {
    id: paymentData.id || '',
    type: paymentData.type || '',
  };
}
