import * as React from 'react';
import { flow, forEach, get, head, startsWith } from 'lodash';
import { change, formValueSelector, getFormSyncErrors, reduxForm, submit } from 'redux-form';
import { connect } from 'react-redux';
import { IStore } from '../../../../redux/IStore';
import { bindActionCreators } from 'redux';
import { actions as quoteActions, actions } from '../../../../services/quote/reducer';
import { push } from 'react-router-redux';
import { CommonPayment, validate } from '../Payment/common';
import { isRequired } from '../../../../util/validators';
import { renewalTypeSelectionEnum } from '../Payment/components/YourRenewal';
import { Button, Col, Form, Row } from 'react-bootstrap';
import Signposting, { SignpostType } from '../../components/Signposting';
import { FaSpinner } from 'react-icons/fa';
import ConsentAndIntent from '../../../../components/ConsentAndIntent';
import './style.scss';
import { shouldIgnoreCardPermissions } from '../../../../helpers/Quote';
import AggQuoteSummary from '../../components/Aggregator/AggQuoteSummary';
import MedicalImportantSection from '../QuoteSummary/MedicalImportantSection';
import EmergencyMessaging, { EmergencyMessagingEnum } from '../../../../components/EmergencyMessaging';
import Loading from '../../../../components/Loading';

class AggPayment extends CommonPayment {

  constructor(props) {
    super(props);
    const { actions } = props;
    actions.change('quote', 'policyDeclaration', true);
    this.recalculatePrice();

    this.state = {
      loading: false,
    }
  }

  private recalculatePrice = () => {
    const { actions } = this.props;

    actions.change('quote', 'preventPatch', true);

    this.setState({ loading: true })
    actions.quote.calculate(null, true).then(() => {
      this.setState({ loading: false });
    });
  }

  private renderEmergencyMessage = () => {
    return (
      <EmergencyMessaging type={EmergencyMessagingEnum.AGGREGATOR}/>
    )
  }

  public render() {
    const {
      product,
      submitting,
      permissions,
      i18n,
      change,
      actions,
      handleSubmit,
      valid,
      dispatch,
      reuseCustomerAddress,
      submitFailed,
      quote,
      password,
      passwordConfirmation,
      errorMessage,
      channel,
      premium,
      quoteReference,
      travellers,
      renderDefinition,
      quoteType,
      renewalStatus,
      medicalsDeclared,
    } = this.props;

    const { loading } = this.state;

    const leadTraveller = quote.application.travellers[0];
    const customer = quote.customer;

    const hasCustomer = !!customer;
    const isIOM = leadTraveller.address && startsWith(leadTraveller.address.postcode, 'IM');

    const disableSubmit = (customer.status === 'new' && password !== passwordConfirmation) || isIOM;

    const formHasError = isRequired && submitFailed && !valid;
    const isAggregator = channel.channelType === 'AGG';
    const ignoredChannelKeys = ['idol', 'go-compare', 'compare-the-market', 'confused', 'uswitch', 'money']
    const shouldShowSignPost = isAggregator && !ignoredChannelKeys.includes(channel.key);

    const isSignposted = get(premium, 'isSignposted', false);
    const shouldShowSection = quote.application.tripType === 'annual' && quoteType !== 'renewal';
    const ignorePermissions = shouldIgnoreCardPermissions();
    const hasMedical = this.hasTravellerMedical()
    const referrals = get(premium, 'referrals', []);

    let fraudMessage = '';
    if (errorMessage && errorMessage.toLowerCase().includes('fraud')) {
      fraudMessage = 'Card Payment was unsuccessful: It appears that you are abroad already. Unfortunately we are unable to accept payments if you are already overseas. If this is not the case please call our customer services team on 0333 234 9913';
    }

    if (!permissions && !ignorePermissions) {
      actions.change('quote', 'renewalStatus', renewalTypeSelectionEnum.MANUAL)
    }

    const schemeType = get(premium, 'schemeType');
    const premiumGross = get(premium, 'gross');

    if (loading) {
      return (
        <Loading/>
      )
    }

    if (referrals.length > 0) {
      return (
        <div style={{ textAlign: 'center' }}>
          <h1>Sorry, something went wrong</h1>
          <p>
            Unfortunately there has been a problem with your quote , please see
            our <a className="pointer" href={'https://www.admiral.com/contact-us/travel'} target={'_blank'}>Contact
            Us</a> page for
            details on how to get in touch.
          </p>
        </div>
      );
    }

    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        {(formHasError || errorMessage) && (
          <div className="error-block-container" id="form-errors">
            <h1>Whoops!</h1>
            {formHasError && (
              <p>Missing something? Please check the areas marked in orange before you continue.</p>)}
            {errorMessage && (
              <p>{fraudMessage ? fraudMessage : (errorMessage !== 'Payment Error' ? errorMessage : '')}
              </p>
            )}
            {errorMessage && errorMessage === 'Payment Error' && (
              <Row>
                <Col>
                  <p>We have been unable to process your payment request.</p>
                  <p>No Payment has been taken</p>
                  <p>No cover has been set up</p>
                  <p>Your details have been saved – quote Ref {quoteReference}</p>
                  <p>Please try again or <a className="pointer"
                                            href={'https://www.admiral.com/contact-us/travel'}
                                            target={'_blank'}>contact us</a> so we can help you
                    further.
                  </p>
                </Col>
              </Row>

            )}
          </div>
        )}

        {isIOM && (
          <div className="error-block-container">
            <p>
              {`If you would like to continue a quote or purchase, please contact our call-centre on ${channel.phone}.`}
            </p>
          </div>
        )}

        <div className="steps-container">

          {this.renderEmergencyMessage()}

          <Row className={'greetings'}>
            <Col xs={12}
                 className={'customer-name'}>Hi {leadTraveller.firstName + ' ' + leadTraveller.lastName + ','}</Col>
            <Col xs={12} className={'greeting-1'}>Your price for {schemeType} travel insurance is
              £{premiumGross ? premiumGross.toFixed(2) : ''} </Col>
          </Row>

          <div className={'section-space'}>
            <AggQuoteSummary
              channel={channel}
              isAggregator={isAggregator}
              quoteReference={quoteReference}
              product={product}
              quote={quote}
              renderDefinition={renderDefinition}
              quoteType={quoteType}
              permissions={permissions}
              dispatch={dispatch}
            />
          </div>

          {shouldShowSignPost && isSignposted && (
            <div style={{ marginBottom: '20px' }} className={'section-space'}>
              <Signposting type={SignpostType.TYPE_A} withCheckbox={true}
                           checkBoxFormPath={'signpost_consent'}/>
            </div>
          )}

          <MedicalImportantSection
            hasTravellerDeclaredCondition={hasMedical}
            isAggregator={true}
            {...this.props}
          />

          <div className={'section-space'}>
            {this.renderTravellersForInput('Additional Details')}
          </div>

          {this.renderYourCover()}

          <div className={'duplicate-warning'}>Please make sure you don’t already have cover in place.</div>

          <div>
            <div className="section-title">
              <h2 id={'terms-and-conditions'}>Terms and Conditions</h2>
            </div>
            {this.renderCompliance()}
            {this.renderPrintOptions()}
          </div>

          <div className="btn-bar btn-bar--auto-height section-space">
            <ConsentAndIntent
              permissions={permissions}
              leadTraveller={leadTraveller}
              ignorePermissions={ignorePermissions}
              i18n={i18n}
              change={change}
              channel={channel}
              dispatch={dispatch}
              reuseCustomerAddress={reuseCustomerAddress}
              isMta={false}
              travellers={travellers}
              showRenewal={shouldShowSection}
              renderDefinition={renderDefinition}
              renewalStatus={renewalStatus}
            />
            {this.renderTrackingItems()}
            <div className="clearfix"/>
            <div style={{ display: medicalsDeclared === false ? 'none' : '' }}>
              <Row style={{ marginTop: '40px' }}>
                <Col xs={8}>
                  {this.renderPremium(isAggregator)}
                </Col>
                <Col xs={4}>
                  <Button
                    bsStyle="primary"
                    type="submit"
                    bsSize="lg"
                    className="pull-right purchase-button"
                    onClick={this.checkForErrors}
                    disabled={
                      submitting ||
                      (submitFailed && !valid) ||
                      disableSubmit ||
                      medicalsDeclared === false ||
                      !hasCustomer ||
                      quote.waitForPurchaseToComplete
                    }
                  >
                    {quote.waitForPurchaseToComplete && (
                      <FaSpinner className="fa-spin"/>
                    )} PAY NOW
                  </Button>

                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Form>
    );
  }
}

let selector;
export default flow([
  reduxForm({
    form: 'quote',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const channel = state.branding.channel;
      const product = head(get(state, 'branding.channel.products', []));
      const quotes = state.quote.quotes;
      const errorMessage = state.quote.errorMessage;
      const synchronousError = getFormSyncErrors('quote')(state);
      const schemeId = selector(state, 'schemeId');
      const password = selector(state, 'password');
      const passwordConfirmation = selector(state, 'passwordConfirmation');
      const policyDeclaration = selector(state, 'policyDeclaration');
      const paymentInfo = selector(state, 'payment');
      const startDate = selector(state, 'startDate');
      const endDate = selector(state, 'endDate');
      const post = selector(state, 'communications.post');
      const fraudCheck = state.quote.application.fraudCheck;
      const options = selector(state, 'options');
      const visibility = selector(state, 'visibility');
      const payment = selector(state, 'payment');
      const permissions = selector(state, 'permissions');
      const applicationId = selector(state, 'id');
      const quoteReference = selector(state, 'quoteReference');
      const reuseCustomerAddress = selector(state, 'reuseCustomerAddress');
      const travellers = selector(state, 'travellers');
      const quoteType = selector(state, 'quoteType');
      const documents = selector(state, 'documents');
      const renewalStatus = selector(state, 'renewalStatus');
      const medicalsDeclared = selector(state, 'medicalsDeclared');

      let premium = null;

      forEach(quotes, (tripTypes, level) => {
        forEach(tripTypes, (quotedPremium) => {
          if (quotedPremium.scheme.id === schemeId) {
            premium = quotedPremium;
          }
        });
      });

      return {
        schemeId,
        premium,
        channel,
        policyDeclaration,
        password,
        passwordConfirmation,
        errorMessage,
        paymentInfo,
        startDate,
        endDate,
        post,
        fraudCheck,
        options,
        visibility,
        synchronousError,
        payment,
        permissions,
        applicationId,
        quoteReference,
        reuseCustomerAddress,
        travellers,
        quoteType,
        documents,
        product,
        renewalStatus,
        medicalsDeclared
      };
    },
    (dispatch) => ({
      actions: {
        dispatch,
        submit: bindActionCreators(submit, dispatch),
        calculate: bindActionCreators(actions.calculate, dispatch),
        push: bindActionCreators(push, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        change: bindActionCreators(change, dispatch),
      },
    }),
  ),
])(AggPayment);
