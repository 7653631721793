import * as React from 'react';
import Select from 'react-select';
import { Button, FormControl, HelpBlock, InputGroup } from 'react-bootstrap';
import { FaSearch, FaSpinner } from 'react-icons/fa';
import { WrappedFieldProps } from 'redux-form';
import { OptionComponent, valueComponent } from '../../helpers/SessionCam';
import { IPostcodeAddress, IPostcodeLookup } from '../../data/api/models';
import * as api from '../../data/api/api';

interface IPostcodeControlProps extends WrappedFieldProps {
  className: string;

  [option: string]: any;

  onSearchComplete(showAddress?: boolean): void;

  onSelectAddress(address?: IPostcodeAddress): void;

  onManualEntry(): void

  onClearAddress(): void;
}

interface IPostcodeControlState {
  isSearching: boolean;
  addresses: IPostcodeLookup[];
  showClearButton: boolean;
}

class PostcodeControl extends React.Component<IPostcodeControlProps, IPostcodeControlState> {
  public state = {
    isSearching: false,
    addresses: [],
    showClearButton: true,
  };

  private searchPostcode = () => {
    const { input, onSearchComplete } = this.props;

    this.setState({ isSearching: true });

    api.lookupPostcode(input.value)
      .then((addresses) => {
        this.setState({
          isSearching: false,
          addresses,
        });

        if (addresses && addresses.length === 0) {
          onSearchComplete(true);
        }
      }).catch((error) => {
      this.setState({ isSearching: false });
      onSearchComplete(true);
    });
  }

  private enterManualPostcode = () => {
    const { onManualEntry } = this.props;
    this.setState({addresses : []})

    onManualEntry()
  }

  private selectAddress = (item) => {
    const { onSelectAddress } = this.props;

    this.setState({
      isSearching: true,
      addresses: [],
    });

    api.getPostcode(item.value)
      .then((address) => {
        this.setState({
          isSearching: false,
          showClearButton: true,
        });

        onSelectAddress(address);
      });
  }

  private handleAddressClear = () => {
    const { onClearAddress } = this.props;
    onClearAddress();
    this.setState({
      showClearButton: false,
    });
  }

  public render() {
    const {
      input,
      className,
      meta,
      clearButton,
      onClearAddress,
      onSelectAddress,
      onManualEntry,
      ...props
    } = this.props;
    const { isSearching, addresses } = this.state;
    const isMobile = window.innerWidth <= 980;
    const showOnlyOnSelect = meta && meta.error && meta.error.includes('Please use the Lookup button to select an address.');

    const list = addresses.length > 0 ? addresses.map((address: IPostcodeLookup) => {
      return {
        label: address.line,
        value: address.id,
      };
    }) : [];

    return (
      <div className={!showOnlyOnSelect && meta.touched && meta.error ? 'has-error' : ''}>
        <InputGroup>
          <FormControl
            type="text"
            placeholder="Postcode"
            className={meta.dirty ? className + ' dirty-field' : className}
            {...input}
            {...props}
          />
          {(clearButton && this.state.showClearButton) ? (
            <InputGroup.Button>
              <Button
                bsStyle="primary"
                className="btn-postcode"
                type="button"
                onClick={this.handleAddressClear}
              >
                Clear
              </Button>
            </InputGroup.Button>
          ) : (
            <InputGroup.Button>
              <Button
                bsStyle="primary"
                className="btn-postcode"
                type="button"
                disabled={isSearching || !input.value}
                onClick={this.searchPostcode}
              >
                {isSearching ? (<FaSpinner className="fa-spin"/>) : (<FaSearch/>)} Lookup
              </Button>
            </InputGroup.Button>
          )}
        </InputGroup>

        <div className={'mt-15'}>
          <Button
            bsStyle="primary"
            className="btn-postcode"
            type="button"
            block={true}
            onClick={this.enterManualPostcode}
          >
            Manual Entry
          </Button>
        </div>

        {addresses.length > 0 && (
          <div className={showOnlyOnSelect && meta.touched && meta.error ? 'has-error' : ''}>
            <div>
              <br/>
              <Select
                value={input.value}
                valueComponent={valueComponent}
                className={meta.dirty ? className + ' dirty-field' : className}
                onChange={this.selectAddress}
                options={list}
                searchable={!isMobile}
                optionComponent={OptionComponent}
                placeholder="Select Address..."
                clearable={false}
              />
            </div>
            <>
              {showOnlyOnSelect && meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
            </>
          </div>
        )}

        {!showOnlyOnSelect && meta.touched && meta.error && <HelpBlock>{meta.error}</HelpBlock>}
      </div>
    );
  }
}

export default PostcodeControl;
