import React = require('react');
import { useState } from 'react';
import QuoteIcons from '../Icon/QuoteIcons';

export const CancellationPanel = ({ premium }) => {
  const [showText, setShowText] = useState(true);

  const handleOnClick = () => {
    setShowText(!showText)
  }

  return (
    <div className={'information-panel-container'}>

      <div className={'dropdown-box'} onClick={handleOnClick}>
        <div className={'dropdown-box-text'}>What does Trip Cancellation mean?</div>
        <div className={'dropdown-box-icon'}>{showText ? 'Less Information' : 'More Information'}</div>
      </div>

      {showText && (
        <>
          <div className={'information-panel-body'}>
            <div>The Table Summarises what scenarios are and aren't covered</div>
            <div>REMEMBER - you will not be covered for anything that occurs <b>before</b> your cover start date</div>
          </div>

          <div className={'cancellation-table-wrapper'}>
            <table className={'cancellation-table'}>
              <thead>
              <tr>
                <td>Reason</td>
                <td>Covered?</td>
                <td>Reason</td>
                <td>Covered?</td>
              </tr>
              </thead>
              <tbody>
              <tr>
                <td>Illness, injury or death of insured person</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
                <td>Injury, illness of death of close relative *</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
              </tr>
              <tr>
                <td>Change in FCDO advice</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
                <td>Unemployment/Redundancy</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
              </tr>
              <tr>
                <td>Home Damaged</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
                <td>Called for Jury Duty</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
              </tr>
              <tr>
                <td>Armed Forces/Emergency Services Leave is Cancelled</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
                <td>Passport stolen from the home in 7 days prior trip</td>
                <td><QuoteIcons name={'Tick'} color={'black'}/></td>
              </tr>
              <tr>
                <td>Changing your mind</td>
                <td>X</td>
                <td>Any other Passport or visa issues</td>
                <td>X</td>
              </tr>
              <tr>
                <td>Supplier Failure**</td>
                <td>X</td>
                <td>Strike or severe weather that is already expected***</td>
                <td>X</td>
              </tr>
              <tr>
                <td>Falling pregnant (without complications)</td>
                <td>X</td>
                <td>Illness, injury or death of a pet</td>
                <td>X</td>
              </tr>
              </tbody>
            </table>
          </div>

          <div className={'information-panel-body'}>
            <div>* Pre-existing medical conditions of non-travelling relatives (or of those whose health may affect your
              decision to travel or remain overseas) <b>are not covered</b>. This applies regardless of whether you were
              aware of the condition or not.
            </div>
            <div>** If the reason for your airline cancelling your flight is due staff shortages or errors leading to
              overbooking, this is the responsibility of the airline and there is <b>no cover</b> under your travel
              insurance
              policy but you are legally entitled to a full refund or a replacement flight from the airline and you may
              also be entitled to compensation
            </div>
            <div>*** Where weather warnings are already in place for your travel dates and region at the point of you
              buying travel insurance.
            </div>
            <div style={{ paddingTop: '20px' }}>Please refer to the Insurance Product Information Document or Policy
              Wording for further information.
            </div>
          </div>
        </>
      )}

    </div>
  )
}
