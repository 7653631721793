import * as React from 'react';
import { Button } from 'react-bootstrap';
import { isEmpty } from 'lodash';
import { getAnswerOptions } from '../../../../../../services/verisk/reducer';

export const ConditionQuestionEdit = ({
                                        selectedAnswers,
                                        screening,
                                        dispatch,
                                        condition,
                                        question,
                                        isBMIObject = false,
                                      }) => {

  const handleEditQuestionOptions = () => {
    const declarationNumber = parseInt(screening?.declaration?.data.attributes?.declarationNumber, 10);
    dispatch(getAnswerOptions(condition, question, declarationNumber));
  };

  const renderEditButton = () => {
    return (
      <Button
        bsStyle="primary"
        bsSize="sm"
        className="pull-right"
        disabled={screening.isSubmitting}
        onClick={handleEditQuestionOptions}
      >Edit
      </Button>
    );
  };

  if (isEmpty(selectedAnswers) || selectedAnswers === undefined) {
    return null;
  }

  if (isBMIObject === true) {
    return (
      <div style={{ display: 'flex', alignItems: 'center'  }}>
        <div style={{ flex: 2 }}>
          {selectedAnswers.isMetricSystem && (
            <>
              <div>{'Height: ' + selectedAnswers.height1 + 'cm'}</div>
              <div>{'Weight: ' + selectedAnswers.weight1 + 'kg'}</div>
            </>
          )}
          {!selectedAnswers.isMetricSystem && (
            <>
              <div>{'Height: ' + selectedAnswers.height1 + 'ft ' + selectedAnswers.height2 + 'in'}</div>
              <div>{'Weight: ' + selectedAnswers.weight1 + 'st ' + selectedAnswers.weight2 + 'lb'}</div>
            </>
          )}
        </div>
        <div style={{ flex: 1, textAlign: 'right' }}>
          {renderEditButton()}
        </div>
      </div>
    );
  }

  return (
    <div style={{ display: 'flex', alignItems: 'center'  }}>
      <div style={{ flex: 2 }}>{selectedAnswers.map((answer, key) => {
        return (
          <div key={key}>{answer?.attributes?.text}</div>
        );
      })}
      </div>
      <div style={{ flex: 1, textAlign: 'right' }}>
        {renderEditButton()}
      </div>
    </div>
  );
};
