import * as React from 'react';
import { flow, forEach } from 'lodash';
import appConfig from '../../../../config/main';
import Helmet from 'react-helmet';
import Header from './scenes/Header';
import NewHeader from './scenes/NewHeader';
import Footer from './scenes/Footer';
import { withRouter } from 'react-router';
// import '../../styles/salt.scss';
import { IChannel } from '../../data/api/models';
import { IAuthState } from '../../services/auth/models';

interface IShellProps {
  steps: object;
  channel: IChannel;
  auth: IAuthState;
}

interface IShellState {
  hideContainer: boolean;
}

class Shell extends React.Component<IShellProps, IShellState> {
  public state = {
    hideContainer: false,
  };

  public constructor(props) {
    super(props);

    if (process.env.BROWSER) {
      const accepted: boolean = window.localStorage.getItem('eu-cookie-accepted') === 'true';

      this.setState({ hideContainer: accepted })
    }
  }

  public render() {
    const { children, steps, channel, location} = this.props;

    let subTitle = '';

    let isCustomerArea = false;
    const hideContactUsSelection = false;

    if (location.pathname.includes('account') || location.pathname.includes('auth')
      || location.pathname.includes('password')) {
      isCustomerArea = true;
    }

    forEach(steps, (step) => {
      if (step.route === location.pathname) {
        subTitle = step.title;
      }
    });

    const brandTemplate = {
      // titleTemplate: `${channel.baseName}: %s`,
      titleTemplate: `${channel.baseName}: ${subTitle}`,
    };

    return (
      <div>
        <Helmet {...appConfig.app.head} {...brandTemplate} />
        {!isCustomerArea && <Header steps={steps} channel={channel} hideContactUsSelection={hideContactUsSelection}/>}
        {isCustomerArea && <NewHeader channel={channel} hideContactUsSelection={hideContactUsSelection}/>}
        {children}
        <Footer channel={channel}/>
      </div>
    );
  }
}

export default flow([
  withRouter,
])(Shell);
