import React = require('react');
import { useState } from 'react';

export const GadgetMoreInfo = ({deprecation}) => {

  const [showMoreInfo, setShowMoreInfo] = useState(false);

  const changeShowMoreInfo = () => {
    setShowMoreInfo(!showMoreInfo)
  }

  return (
    <>
      <div>
        If the gadget is more than a year old, deductions will be made to allow for wear and tear. <span
        className={'more-info'}
        onClick={changeShowMoreInfo}>{showMoreInfo ? ' Less information' : ' More information'}</span>
      </div>
      {showMoreInfo && (
        <>
          <div className={'more-info-table-header'}><b>Important:</b> Any item under 12 months old will not be subject
            to additional wear and tear
          </div>
          <div className={'more-info-table-row'}>
            <div className={'more-info-table-column'}><b>Item</b></div>
            <div className={'more-info-table-column'}><b>Depreciation applicable</b></div>
          </div>
          <div className={'more-info-table-row'}>
            <div className={'more-info-table-column'}>Gadget</div>
            <div className={'more-info-table-column'}>{deprecation ? deprecation : '20'}% per year up to a maximum depreciation of 60%</div>
          </div>
        </>

      )}
    </>
  )
}
