import * as React from 'react';

interface IOptionComponentProps {
  option: any;
  className: string;
  isFocused: boolean;
  onSelect(option: any, MouseEventHandler);
  onFocus(option: any, MouseEventHandler);
}

export class OptionComponent extends React.Component<IOptionComponentProps> {
  public handleMouseDown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.onSelect(this.props.option, event);
  }

  public handleMouseEnter = (event) => {
    this.props.onFocus(this.props.option, event);
  }

  public handleMouseMove = (event) => {
    if (this.props.isFocused) {
      return;
    }

    this.props.onFocus(this.props.option, event);
  }

  public render() {
    const { children, className } = this.props;

    return (
      <div className={className + ' sessioncamhidetext'}
           onMouseDown={this.handleMouseDown}
           onMouseEnter={this.handleMouseEnter}
           onMouseMove={this.handleMouseMove}>
        {children}
      </div>
    );
  }
}

export const valueComponent = ({children}) => {
  return (
    <div className="Select-value">
      <span className="Select-value-label sessioncamhidetext">{children}</span>
    </div>
  );
};
