import * as React from 'react';

import { flow, get } from 'lodash';
import { getFormSyncErrors } from 'redux-form';
import { connect } from 'react-redux';
import { IStore } from '../../redux/IStore';
import { bindActionCreators } from 'redux';
import { actions as contentActions } from '../../services/content/reducer';
import { IContentActions } from '../../services/content/models';
import * as Commonmark from 'react-commonmark';

interface IEmergencyMessaging {
  type: string;
  actions: {
    content: IContentActions;
  }
}

export enum EmergencyMessagingEnum {
  QUOTE_JOURNEY = 'quoteJourney',
  PORTAL = 'portal',
  AGGREGATOR = 'aggregators'
}

const EmergencyMessaging = ({ type, actions }: IEmergencyMessaging) => {

  const emergencyContent = actions.content.getContentByName('Emergency Messaging')

  if (!emergencyContent) {
    return null;
  }

  const sectionMessage = get(emergencyContent, 'sections.message')
  const color = get(emergencyContent, 'metadata.selected_banner_color')
  let shouldShow = false;
  let message = '';

  switch (type) {
    case EmergencyMessagingEnum.QUOTE_JOURNEY:
      shouldShow = get(emergencyContent, 'metadata.quote_journey.enabled', false)
      message = get(sectionMessage, 'quote_journey', '')
      break;
    case EmergencyMessagingEnum.PORTAL:
      shouldShow = get(emergencyContent, 'metadata.portal.enabled', false)
      message = get(sectionMessage, 'portal', '')
      break;
    case EmergencyMessagingEnum.AGGREGATOR:
      shouldShow = get(emergencyContent, 'metadata.aggregator.enabled', false)
      message = get(sectionMessage, 'aggregator', '')
      break;
  }

  if (!shouldShow) {
    return null;
  }

  if (!message) {
    return null;
  }

  return (
    <div style={{
      textAlign: 'center',
      backgroundColor: color,
      padding: '15px',
      borderRadius: '10px',
      color: '#333333',
    }}
         className={'emergency-messaging'}>
      <Commonmark
        linkTarget="_blank"
        source={message}
      />
    </div>
  );
}

export default flow([
  connect(
    (state: IStore) => {
      const synchronousError = getFormSyncErrors('quote')(state);
      return {
        auth: state.auth,
        synchronousError,
      };
    },
    (dispatch) => ({
      actions: {
        content: bindActionCreators({ ...contentActions }, dispatch)
      },
    }),
  ),
])(EmergencyMessaging);
