import { forEach } from 'lodash';
import { IDeclaration } from '../data/api/models';

export default function mapMedicalQuestions(
  salesChannel: string,
  medicalDeclaration: IDeclaration[],
  ) {
  const isAdmiralChannel = salesChannel === 'admiral-b2c' || salesChannel === 'admiral-b2c-renewal' ||
    salesChannel === 'call-centre' || salesChannel === 'call-centre-renewal';

  if (isAdmiralChannel) {
    forEach(medicalDeclaration, (question, key) => {
      if (key === 'is-awaiting-medical-investigation-treatment') {
        question.should_not_screen = true;
      }
      question.negotiable = true;
    });

  }

  return medicalDeclaration;
}
