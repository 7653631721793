import * as React from 'react';
import {connect} from 'react-redux';
import {cloneDeep, each, flow, get, isEmpty, isNil, isUndefined} from 'lodash';
import {Col, Modal as BootstrapModal, OverlayTrigger, Popover, Row} from 'react-bootstrap';
import {IBrandingActions, IBrandingState, II18nMessages} from '../../services/branding/models';
import {Redirect, Route, RouteComponentProps, Switch, withRouter} from 'react-router';
import {IStore} from '../../redux/IStore';
import {actions as brandingActions} from '../../services/branding/reducer';
import {bindActionCreators} from 'redux';
import {IChannel, ICustomer, IPolicyApplication, IProduct, IStep} from '../../data/api/models';
import {withJob} from 'react-jobs';
import {IQuoteActions, IQuoteState as IQuoteServiceState} from '../../services/quote/models';
import {actions as quoteActions} from '../../services/quote/reducer';
import Loading from '../../components/Loading/index';
import {push} from 'react-router-redux';
import {FaQuestionCircle} from 'react-icons/fa';
import * as Commonmark from 'react-commonmark';
import * as api from '../../data/api/api';
import axios from 'axios';
import {IAuthState} from '../../services/auth/models';
import Tracking from './components/Tracking';
import {IReactStep} from './steps';
import RetrieveAggregatorQuote from './components/RetrieveAggregatorQuote';
import Heap from '../../helpers/Heap';
import BusinessContinuityPlan from '../../helpers/BusinessContinuityPlan';
import ErrorBoundary from '../../components/ErrorBoundary';
import {mentalHealthLinkRenderer} from '../../helpers/Quote';
import DataLayer from '../../helpers/DataLayer';
import EmergencyMessaging, {EmergencyMessagingEnum} from '../../components/EmergencyMessaging';

const ModalHeader = BootstrapModal.Header;
const ModalTitle = BootstrapModal.Title;
const ModalBody = BootstrapModal.Body;

interface IQuoteProps extends RouteComponentProps<any> {
  auth: IAuthState;
  i18n: II18nMessages;
  steps: IReactStep[];
  quote: IQuoteServiceState;
  channel: IChannel;
  customer: ICustomer;
  branding: IBrandingState;
  actions: {
    branding: IBrandingActions;
    quote: IQuoteActions;
    push: (route: string) => void;
  };
}

interface IQuoteState {
  showModal: boolean;
  values: any;
  transactionId: string | null;
  originalGrossPremium: number | null;
}

export const REGION_WORLDWIDE_EXCLUDING_LABEL_OLD = 'Worldwide Excluding USA, Canada and Caribbean';

class Quote extends React.Component<IQuoteProps, IQuoteState> {
  public state = {
    showModal: false,
    values: null,
    transactionId: null,
    originalGrossPremium: null,
  };

  public componentDidMount() {
    const {actions, quote, branding} = this.props;
    const product = actions.branding.getProduct();

    if (process.env.BROWSER) {
      window.addEventListener('message', this.handleWindowMessage, {passive: true});
    }

    if (!quote.initialised) {
      actions.quote.initFromProduct(product, quote, branding);
    }
  }

  componentWillUnmount() {
    if (process.env.BROWSER) {
      window.removeEventListener('message', this.handleWindowMessage, {capture: false});
    }
  }

  private handleWindowMessage = (event: MessageEvent) => {
    const {actions} = this.props;

    // For Chrome, the origin property is in the event.originalEvent object.
    const origin = event.origin || event.originalEvent.origin;

    if (origin !== axios.defaults.baseURL) {
      return;
    }

    const data = event.data;

    this.scrollToTop();

    if (data.status !== 'complete') {
      // Show an error?

      // console.log('Payment not complete', data.message, data);
      actions.quote.error('Payment Error');
      this.closeModal();

      return;
    }

    actions.quote.complete(data.policies[0]);
    this.closeModal();

    actions.quote.addStepToWizard('complete', false);

    actions.push('/complete');
  }

  public openModal = (values, transactionId) => {
    this.setState({
      showModal: true,
      transactionId,
      values,
    });
  }

  public closeModal = () => {
    this.setState({
      showModal: false,
      values: null,
    });
  }

  private getCurrentLocation = () => {
    const {location, steps} = this.props;

    let currentLocationIndex = null;

    steps.forEach((step, key) => {
      if (step.route === location.pathname) {
        currentLocationIndex = key;
      }
    });

    return currentLocationIndex;
  }

  private scrollToTop = () => {
    if (process.env.BROWSER) {
      window.scrollTo(0, 0);
    }
  }

  private handleBack = () => {
    const {steps, actions} = this.props;
    const currentLocation = this.getCurrentLocation();

    actions.push(steps[currentLocation - 1].route);
    this.scrollToTop();
  }

  private handleSubmit = (formValues: IPolicyApplication) => {
    const {steps, actions, channel, quote} = this.props;
    const currentLocation = this.getCurrentLocation();
    const currentStep = steps [currentLocation];
    let nextStep = steps[currentLocation + 1];
    const isFinalStep = nextStep.key === 'complete';
    const isSchemeChoice = currentStep.key === 'schemeChoice';

    Heap.handleHeapExitPage(nextStep, currentStep, formValues);
    DataLayer.handleTrackPage(nextStep, currentStep, formValues)

    if (isSchemeChoice) {
      if (get(formValues, 'tripType', '') === 'annual') {
        formValues.endDate = null;
      }
    }

    if (nextStep.key === 'signpost') {
      const medicalLoad = get(formValues, 'signposted.medicalLoad', false);
      const isUpsell = get(formValues, 'isUpsell', false);
      if (!medicalLoad || (medicalLoad && !isUpsell) // if there was no upsell then we show the signpost inside the SchemeChoice page
      ) {
        nextStep = steps[currentLocation + 2]; // skip signpost page
      }
    }

    if (!isFinalStep) {
      formValues.steps.push(nextStep.key);
      actions.quote.addStepToWizard(nextStep.key);
    } else {
      formValues.purchasedFromB2C = true;
      formValues.purchaseChannel = ['call-centre', 'call-centre-renewal'].includes(channel.key)
        ? 'admiral-b2c' : channel.key;
    }

    const handleNextStep = this.handleNextStep(formValues, nextStep, cloneDeep(quote.application));

    if (isUndefined(currentStep.key) || !currentStep.saveApplication) {
      actions.quote.addStepToWizard(nextStep.key, false);
      handleNextStep();
      return;
    }

    if (nextStep.key === 'schemeChoice') {
      formValues.calculateAllSchemes = true;
    }

    let patch = currentStep.saveApplicationWithPatch;

    const isAggregator = channel.channelType === 'AGG';

    const hasAdditionalAggregatorOption = channel.additionalAggregatorOptions.some((option) => {
      return !isNil(formValues.options[option.key]);
    });

    const hasPrintOption = formValues.documentPack === 'full' || formValues.documentPack === 'personal';

    if (isAggregator && (hasAdditionalAggregatorOption || hasPrintOption)) {
      patch = false;
    }

    if (isAggregator && quote.application.quoteType === 'renewal') {
      patch = true;
    }

    if (isFinalStep) {
      // TODO: check if IGLOO global object is set before making this request
      const fraudCheck = quote.application.premiums[0].information.fraudCheck || false;
      formValues.fraudCheck = {
        answer: 'not-required',
      };

      actions.quote.setWaitForPurchaseToComplete(true);

      if (
        isAggregator && quote.application.quoteType === 'renewal'
        && ['msm', 'msm-renewal', 'tsm', 'tsm-renewal'].includes(channel.key)
      ) {
        formValues.nextTermRenewalStatus = 'auto';
      }

      if (fraudCheck) {
        return actions.quote.fraudCheck(formValues.id)
          .then((data) => {
            formValues.fraudCheck = data.meta;
            // Save the policy application
            actions.quote.submitForm(formValues, isFinalStep, false, patch && !formValues.preventPatch)
              .then((application) => {
                if (data.meta.answer === 'deny') {
                  actions.quote.error('We are unable to complete this transaction.' +
                    ' We sincerely apologise for the inconvenience caused.');
                  this.scrollToTop();
                  actions.quote.setWaitForPurchaseToComplete(false);
                  return;
                }

                handleNextStep(application);
              })
              .catch((error) => {
                actions.quote.setWaitForPurchaseToComplete(false);
              });
          })
          .catch((error) => {
            actions.quote.setWaitForPurchaseToComplete(false);
          });
      }
    }

    return actions.quote.submitForm(formValues, true, false, patch && !formValues.preventPatch)
      .then(handleNextStep)
      .catch((error) => {
        actions.quote.setWaitForPurchaseToComplete(false);
      });
  }

  private handleNextStep = (formValues, nextStep: IStep, previousApplication: IPolicyApplication) =>
    (application?: IPolicyApplication) => {
      const {actions} = this.props;

      if (nextStep.key !== 'complete' && this.state && this.state.originalGrossPremium) {
        this.setState({originalGrossPremium: null});
        actions.quote.error('');
      }

      if (nextStep.key === 'complete') {
        let previousPremium = 0;
        if (this.state && this.state.originalGrossPremium) {
          previousPremium = this.state.originalGrossPremium;
        } else {
          previousPremium = previousApplication.premiums[0].gross;
          this.setState({originalGrossPremium: previousPremium});
        }

        const newPremium = application.premiums[0].gross;
        if (previousPremium !== newPremium) {
          actions.quote.error(
            'There has been a problem issuing your policy, please '
            + 'contact us on ' + this.props.channel.phone + ' if you would like to complete your purchase.',
          );
          this.scrollToTop();
          actions.quote.setWaitForPurchaseToComplete(false);
          return;
        }

        return actions.quote.purchase(formValues)
          .then((data) => {
            actions.quote.setWaitForPurchaseToComplete(false);

            actions.quote.complete(data.policies[0]);
            actions.quote.addStepToWizard('complete', false);

            actions.push(nextStep.route);


            this.scrollToTop();
          })
          .catch((error) => {
            actions.quote.setWaitForPurchaseToComplete(false);

            let rethrow = true;
            let message = null;

            each(error.response.data.errors, (e: any) => {
              if (e.id !== 'redirect-to-provider') {
                message = e.detail;
                return;
              }

              this.openModal(formValues, e.data.transactionId);
              rethrow = false;
            });

            if (!rethrow) {
              return;
            }

            if (message) {
              Heap.trackEvent('PurchaseFailed', formValues, 'quote', {errorMessage: message})
              actions.quote.error(message);
              this.scrollToTop();
            }

            throw error;
          });
      }

      actions.push(nextStep.route);
      this.scrollToTop();

      if (nextStep.key !== 'schemeChoice') {
        if (nextStep.calculatePremium) {
          actions.quote.calculate(formValues);
        }
      }
    }

  private handlePropagation = (e) => {
    e.preventDefault()
    e.stopPropagation()
  }

  private renderStepRoutes = (product: IProduct) => {
    const {steps, quote, i18n, channel} = this.props;

    return steps.map((step, key) => {
      const {route: routePath} = step;
      const exact = routePath === '/';

      const onBack = key > 0 ? this.handleBack : null;

      return (
        <Route key={key} exact={exact} path={routePath}>
          <ErrorBoundary {...this.props}>
            <step.component
              product={product}
              initialValues={quote.application}
              destinations={quote.destinations}
              stateCities={quote.stateCities}
              occupations={quote.occupations}
              industries={quote.industries}
              channel={channel}
              i18n={i18n}
              onSubmit={this.handleSubmit}
              onBack={onBack}
              renderDefinition={this.renderDefinition(product)}
              {...this.props}
            />
          </ErrorBoundary>
        </Route>
      );
    });
  }

  private renderDefinition = (product: IProduct) => (name: string, useProvidedString?: string) => {
    const definition = useProvidedString ? useProvidedString : product.metadata.definitions[name];
    let className;

    if (!definition) {
      return null;
    }

    const popover = (
      <Popover
        id={`popover-${name}`}
      >
        <Commonmark
          source={definition}
          renderers={{link: mentalHealthLinkRenderer}}
        />
      </Popover>
    );

    className = (name === 'region' || name === 'destinations') ? 'info-btn no-margin' : 'info-btn';

    return (
      <OverlayTrigger
        trigger="click"
        rootClose={true}
        overlay={popover}
        placement="bottom"
        positionLeft={200}
        positionTop={50}
      >
        <FaQuestionCircle data-d={name} onClick={this.handlePropagation}
                          className={className}/>
      </OverlayTrigger>
    );
  }

  private renderPaymentModal = () => {
    return (
      <BootstrapModal
        show={true}
        onHide={this.closeModal}
        keyboard={false}
        backdrop="static"
      >
        <ModalHeader closeButton={false}>
          <ModalTitle>Payment</ModalTitle>
        </ModalHeader>
        <ModalBody>
          <iframe src={api.getPaymentUrl(this.state.transactionId)} className="payments-modal"/>
        </ModalBody>
      </BootstrapModal>
    );
  }

  private renderHero = () => {
    return (
      <>
        <EmergencyMessaging type={EmergencyMessagingEnum.QUOTE_JOURNEY}/>
      </>
    );
  }

  public render() {
    const {actions, steps, quote, branding} = this.props;
    const product = actions.branding.getProduct();
    const currentLocation = this.getCurrentLocation() || 0;

    if (quote.isRetrievingQuote) {
      return (
        <Loading/>
      )
    }

    if (!quote.isRetrievingQuote) {
      if (!isEmpty(quote.application.steps) && quote.application.steps.indexOf(steps[currentLocation].key) === -1) {
        return (
          <Redirect to={steps[0].route}/>
        );
      }
    }

    const bcp = branding.channel.bcp || null;
    if (BusinessContinuityPlan.doNotSell(bcp)) {
      return (
        <div className="internal-page">
          <Tracking/>
          <div className="container">
            <Route exact={true} path="/" component={this.renderHero}/>
            <Row>
              <Col md={12}>
                <div className="quote-content-container">
                  <div className={'steps-container section'}>
                    <Commonmark source={get(bcp.contents, bcp.messageKey)}/>
                  </div>
                </div>
              </Col>
            </Row>
          </div>
        </div>
      );
    }

    return (
      <div className="internal-page">
        <Tracking/>
        <Route exact={true} path="/aggregator/:id" component={RetrieveAggregatorQuote}/>
        <div className="container">
          <Route exact={true} path="/" component={this.renderHero}/>
          <Row>
            <Col md={12}>
              <div className="quote-content-container">
                <Switch>
                  {this.renderStepRoutes(product)}
                </Switch>
              </div>
            </Col>
          </Row>
        </div>

        {this.state.showModal && this.renderPaymentModal()}
      </div>
    );
  }
}

export default flow([
  withJob({
    work: (props: IQuoteProps) => {
      const promises = [];

      if (props.quote.destinations.length === 0) {
        promises.push(props.actions.quote.loadDestinations());
      }

      if (props.quote.stateCities.length === 0) {
        promises.push(props.actions.quote.loadStateCities());
      }

      if (props.quote.occupations.length === 0) {
        promises.push(props.actions.quote.loadOccupations());
      }

      if (props.quote.industries.length === 0) {
        promises.push(props.actions.quote.loadIndustries());
      }

      if (!props.quote.channels || props.quote.channels.length === 0) {
        promises.push(props.actions.quote.loadChannels());
      }

      return Promise.all(promises);
    },
    LoadingComponent: (props) => <Loading/>,
    ErrorComponent: ({error}) => {
      console.error(error);

      return (
        <div>
          {error.message}
        </div>
      );
    },
  }),
  connect(
    (state: IStore) => ({
      isDrawerOpened: state.header.isDrawerOpened,
      i18n: state.branding.i18n,
      quote: state.quote,
      auth: state.auth,
      branding: state.branding,
    }),
    (dispatch) => ({
      actions: {
        quote: bindActionCreators({...quoteActions}, dispatch),
        branding: bindActionCreators({...brandingActions}, dispatch),
        push: bindActionCreators(push, dispatch),
      },
    }),
  ),
  withRouter,
])(Quote);
