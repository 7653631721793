import { useState, useEffect } from 'react';

// Hook
export const windowSize = (customSize = 980) => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
    isMobile: false
  });

  const mobileSize = customSize;

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        isMobile: window.innerWidth <= mobileSize,
      });
    }

    // Add event listener
    window.addEventListener("resize", handleResize, { passive: true });

    // Call handler right away so state gets updated with initial window size
    handleResize();

    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize, {capture: false});
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}
