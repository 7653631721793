import * as React from 'react';
import { flow } from 'lodash';
import { formValueSelector, InjectedFormProps, reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { IStore } from '../../../../redux/IStore';
import { Button, Form } from 'react-bootstrap';
import Signposting, { SignpostType } from '../../components/Signposting';
import { IQuoteActions } from '../../../../services/quote/models';
import { bindActionCreators } from 'redux';
import { actions as quoteActions } from '../../../../services/quote/reducer';

interface ISignpost extends InjectedFormProps {
  actions: {
    quote: IQuoteActions;
  };
}

class Signpost extends React.Component<ISignpost, any> {

  public constructor(props) {
    super(props);
    const { applicationId } = props;
    this.props.actions.quote.addNotes('Medical load over threshold – directory signposted', applicationId);
  }

  public render() {
    const {
      submitting,
      handleSubmit,
      valid,
      submitFailed,
    } = this.props;
    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        <div className="steps-container">
          <Signposting type={SignpostType.TYPE_A} medical={false}/>
          <div className="btn-bar" style={{ marginTop: '20px' }}>
            <Button
              id="continue"
              bsStyle="primary"
              bsSize="lg"
              type="submit"
              className="pull-right"
              disabled={submitting || (submitFailed && !valid)}
            >
              CONTINUE
            </Button>
          </div>
        </div>
      </Form>
    );
  }
}

let selector;

export default flow([
  reduxForm({
    form: 'quote',
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const applicationId = selector(
        state,
        'id',
      );
      return {
        auth: state.auth,
        applicationId,
      };
    },
    (dispatch) => ({
      dispatch,
      actions: {
        quote: bindActionCreators({ ...quoteActions }, dispatch),
      },
    }),
  ),
])(Signpost);
