import { IBaseChannel, IBCP, IChannel, IProduct } from '../models';
import { getIncludedResource } from '../util';

export function fromJsonApi(data, included: any[]): IChannel {
  const {
    name,
    channel_type: channelType,
    key,
    whitelabel,
    metadata,
  } = data.attributes;

  const products = getIncludedResource(data, included, 'products', true).map((resource): IProduct => {
    const {
      name,
      product_type: productType,
      product_code: productCode,
      status,
      summary,
      description,
      icon,
      metadata,
      documents,
    } = resource.attributes;

    return {
      id: resource.id,
      name,
      productType,
      productCode,
      status,
      summary,
      description,
      icon,
      metadata,
      documents,
    };
  });

  let bcp: IBCP = null;
  if (metadata.bcp) {
    bcp = {
      enabled: metadata.bcp.enabled || false,
      doNotSell: metadata.bcp.do_not_sell || false,
      messageKey: metadata.bcp.message_key,
      contents: metadata.bcp.contents,
      allowedSchemeTypes: metadata.bcp.allowed_scheme_types || [],
    };
  }

  return {
    id: data.id,
    name,
    channelType,
    key,
    whitelabel,
    products,
    baseName: metadata.base_name,
    phone: metadata.phone,
    footer: metadata.footer,
    openingHours: metadata.opening_hours,
    offlineSchedule: metadata.offline_schedule,
    gtmId: metadata.gtm_id,
    channelLogo: metadata.channel_logo,
    additionalLogo: metadata.additional_logo,
    steps: metadata.steps,
    cseScript: metadata.cse_script,
    additionalAggregatorOptions: metadata.additional_aggregator_options || [],
    bcp,
    emergencyNumber: metadata.emergency_number || '',
    claimsNumber: metadata.claims_number || '',
  };
}

export function fromJsonApiArray(channels): IBaseChannel[] {
  const results = [];

  channels.map((data) => {
    const {
      name,
      key,
    } = data.attributes;

    results.push({
      id: data.id,
      name,
      key,
    });
  });

  return results;
}
