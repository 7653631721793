import * as React from 'react';

export default function Banner({
   imageDesktop,
   imageDesktopx2,
   imageDesktopx2Width,
   imageMobile,
   imageMobilex2,
   imageMobilex2Width,
   link,
   alt,
}) {

  return (
    <div className="banner">
      <a href={link} target="_blank">
        <img
          className="banner-desktop"
          src={imageDesktop}
          srcSet={`${imageDesktopx2}
          ${imageDesktopx2Width}w`}
          alt={alt}
        />
      </a>
      <a href={link} target="_blank">
        <img
          className="banner-mobile"
          src={imageMobile}
          srcSet={`${imageMobilex2} ${imageMobilex2Width}w`}
          alt={alt}
        />
      </a>
    </div>
  );

}
