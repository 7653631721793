import * as React from 'react';
import {get, keys, map, reduce, size} from 'lodash';
import {FormattedNumber} from 'react-intl';
import {Field, InjectedFormProps} from 'redux-form';
import FieldFormControl from '../../../../../../components/FieldFormControl';
import {isRequired} from '../../../../../../util/validators';
import Benefit from '../Benefit';
import {Button, Col, Row} from 'react-bootstrap';
import {IQuoteState} from '../../../../../../services/quote/models';
import {IProduct} from '../../../../../../data/api/models';
import {standardiseTheName} from '../../../../../../helpers/Quote';
import {StickyContainer, Sticky} from 'react-sticky'

interface IQuoteProps extends InjectedFormProps {
  quote: IQuoteState;
  product: IProduct;
  tripType: string;
  schemeId: string;
  options: any;
  onSingleSchemeSelected: any;
  toggleMoreInfo: () => void;
  moreInfoState: boolean;
  dispatch: any;
  change: any;
}

class Quotes extends React.Component<IQuoteProps, any> {

  private dispatchClaimRates = () => {
    const {change, quote, dispatch} = this.props;

    const quotes = quote.quotes;
    const coverLevel = keys(quotes)[0];
    const tripType = keys(quotes[coverLevel])[0];
    const quotedCover = quotes[coverLevel][tripType];
    const information = quotedCover.information;
    const claimCalculated = {
      perilName: get(information, 'claimCalculated.perilName', 'other'),
      claimCount: get(information, 'claimCalculated.claimCount', 0),
      claimRate: get(information, 'claimCalculated.claimRate', 1),
    };
    if (!get(quote, 'application.claimCalculated', null)) {
      dispatch(change('claimCalculated', claimCalculated));
    }
  }

  private isSelected = (quote, schemeId) => {
    return get(quote, 'scheme.id') === schemeId
  }

  render() {
    const {
      quote,
      product,
      tripType: tripTypeValue,
      schemeId,
      options,
      onSingleSchemeSelected,
      toggleMoreInfo,
      moreInfoState
    } = this.props;
    const quotes = quote.quotes;
    let tripTypeName = standardiseTheName(product.metadata.trip_type[tripTypeValue]);
    let hasDiscount = false;

    const orderedQuotes = product.metadata.scheme_order.map((order) => {
      return quotes[order];
    });

    if (!tripTypeValue) {
      return null;
    }

    this.dispatchClaimRates();

    const selectedTripTypeQuotes = map(orderedQuotes[0] && orderedQuotes, (tripTypes, level) => {
      if (size(tripTypes) === 1) {
        tripTypeName = keys(tripTypes)[0];
      }
      return tripTypes[tripTypeName]
    })

    const documentsOrder = [
      'Insurance Product Information Document',
      'Policy Wording',
    ];

    return (
      // <div className={scheme.id === schemeId ? 'quote-container active' : 'quote-container'}>
      <div className={'quote-container'}>
        <div className="quote-body">

          <Row className="quote-header-container">
            {map(selectedTripTypeQuotes, (quote, index) => {
              hasDiscount = get(quote, 'discount') < 0;
              return (
                <Col xs={4}>
                  <div className="quote-header">
                    {get(quote, 'scheme.coverLevel')}
                  </div>
                </Col>
              )
            })}
          </Row>

          <Row>
            <Col>
              <StickyContainer className={'sticky-container-prices'}>
                <Sticky>
                  {({
                      style,
                    }) => (
                    <div style={style} className={'sticky-prices'}>
                      <div className={hasDiscount ? 'quote-value-container discount' : 'quote-value-container'}>
                        {map(selectedTripTypeQuotes, (quote, index) => {
                          const discountedPrice = get(quote, 'premiumBeforeAdjustment.gross') ? get(quote, 'premiumBeforeAdjustment.gross').toFixed(2) : 0.00;

                          return (
                            <Col xs={4}
                                 className={this.isSelected(quote, schemeId) ? 'quote-prices selected' : 'quote-prices'}>
                              <h1 className={hasDiscount ? 'discount-price' : ''}>
                                {get(quote, 'gross') <= 0 ? (
                                  <span>
                                N/A
                              </span>
                                ) : (
                                  <>
                                    {hasDiscount && (
                                      <div className={'discount-price-strikethrough'}>
                                        {'£' + discountedPrice}
                                      </div>
                                    )}
                                    <FormattedNumber
                                      value={quote ? get(quote, 'gross') : 0}
                                      style="currency"
                                      currency={quote.currency}
                                    />
                                  </>
                                )}
                              </h1>
                            </Col>
                          )
                        })}
                      </div>
                    </div>
                  )}
                </Sticky>
              </StickyContainer>
            </Col>
          </Row>

          <Row className={hasDiscount ? 'quote-select-container discount' : 'quote-select-container'}>
            {map(selectedTripTypeQuotes, (quote, index) => {
              const disabled = get(quote, 'gross') <= 0;
              return (
                <Col xs={4} className={`button-wrapper-${index}`}>
                  <div style={{padding: '0 15px 0 15px'}}>
                    <Field
                      name="schemeId"
                      type="radio"
                      value={get(quote, 'scheme.id')}
                      className="select-quote"
                      component={FieldFormControl}
                      validate={[isRequired]}
                      disabled={disabled}
                      onChange={onSingleSchemeSelected.bind(this, quote)}

                    >
                      <div className={'select-quote-btn' + (disabled ? ' disabled' : '')}>
                        {this.isSelected(quote, schemeId) ? 'selected' : 'select'}
                      </div>
                    </Field>
                  </div>
                </Col>
              )
            })}
          </Row>
          {/*Documents*/}
          <Row className="quote-documents-container">
            {map(selectedTripTypeQuotes, (quote, index) => {

              const documents = get(quote, 'scheme.documents');
              const isAnnual = tripTypeName === 'Annual Multi Trip';
              const coverLevel = get(quote, 'scheme.coverLevel');

              const filteredDocuments = reduce(keys(documents), (acc: any, current: string) => {
                const isIpid = current.toLowerCase().includes('ipid');
                const isCoverLevel = current.toLowerCase().includes(coverLevel);
                const isSingle = current.toLowerCase().includes(tripTypeName.toLowerCase());
                const isMultiTrip = isAnnual && current.toLowerCase().includes('multi-trip');

                if (!isIpid || (isCoverLevel && (isSingle || isMultiTrip))) {
                  acc[current] = documents[current];
                }

                return acc;
              }, {});

              if (quote === null) {
                return null;
              }

              return (
                <Col xs={4} className={`document-wrapper-${index}`}>
                  <div className="quote-documents">
                    {map(documentsOrder, (documentName: string) => {
                      const documentUrl = get(filteredDocuments, documentName);
                      if (!documentUrl) {
                        return null;
                      }

                      return (
                        <div style={{padding: '0 15px 0 15px'}}>
                          <a className="btn btn-documents" href={documentUrl} target="_blank" key={documentName}>
                            {documentName}
                          </a>
                        </div>
                      );
                    })}
                  </div>
                </Col>
              )
            })}
          </Row>

          {/*Benefits*/}
          <Benefit
            title={'Policy Excess'}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
            definition={product.metadata.definitions.excess}
          />

          <Benefit
            title={'Medical Expenses'}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
            definition={'Medical'}
          />

          <Benefit
            title="Trip Cancellation"
            definition={'Cancellation'}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
          />

          <Benefit
            title="Personal belongings"
            definition={'Baggage'}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
          />

          <Benefit
            title="Single item limit"
            definition={'single item limit'}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
          />

          <Benefit
            title="Overall valuables limit"
            definition={'valuables total limit'}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
          />

          <Benefit
            title="Winter sports"
            definition={product.metadata.definitions.winter_sports_alt_info}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
            covered={options.winter_sports}
          />

          <Benefit
            title="Cruise cover"
            definition={product.metadata.definitions.cruise_cover_alt_info}
            selectedTripTypeQuotes={selectedTripTypeQuotes}
            covered={options.cruise_cover}
          />

          <Row>
            {map(selectedTripTypeQuotes, (quote, index) => {
              return (
                <Col xs={4} key={index} className={`more-info-wrapper-${index}`}>
                  <div style={{padding: '0 15px 15px 15px'}}>
                    <Button className="more-info-btn" block={true} onClick={toggleMoreInfo}>
                      {moreInfoState ? 'Less info' : 'More info'}
                    </Button>
                  </div>
                </Col>
              )
            })}
          </Row>

          {moreInfoState && (
            <div>
              <Benefit
                title="Personal accident"
                definition={'Personal Accident'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Money & documents"
                definition={'Money, Passports and Travel Documents'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Legal Liability"
                definition={'Legal Liability'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Delayed/missed departure"
                definition={'Missed Departure, Travel Delay or Abandoned Trip'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Legal assistance"
                definition={'Legal assistance'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Catastrophe"
                definition={'Catastrophe'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Hijack"
                definition={'Hijack'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
              />
              <Benefit
                title="Gadget cover"
                definition={'Gadget cover'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
                covered={options.gadget_cover}
              />
              <Benefit
                title="Excess Waiver"
                definition={'Excess Waiver'}
                selectedTripTypeQuotes={selectedTripTypeQuotes}
                covered={options.excess_waiver}
              />
            </div>
          )}
        </div>
      </div>
    )

  }
}

export default Quotes;
