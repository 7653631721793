import * as React from 'react';
import { withRouter } from 'react-router';
import { flow } from 'lodash';
import { FiPhone, FiUser } from 'react-icons/fi/';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { IChannel } from '../../../../../data/api/models';
import { IAuthState } from '../../../../../services/auth/models';
import { IHeaderActions } from '../models';
import ContactUsModal from '../../../components/ContactUsModal';
import { IStore } from '../../../../../redux/IStore';
import { actions as authActions, IAuthActions } from '../../../../../services/auth/reducer';
import { actions as headerActions } from '../../Header/reducer';
import { Col, Row } from 'react-bootstrap';

interface IPortalAreaTopNavProps {
  channel: IChannel;
  auth: IAuthState;
  openContactUsModal: boolean;
  hideContactUsSelection: boolean;
  actions: {
    auth: IAuthActions;
    header: IHeaderActions;
  };
}

interface IPortalAreaTopNavState {
  showContactUsModal: boolean;
}

class PortalAreaTopNav extends React.Component<IPortalAreaTopNavProps, IPortalAreaTopNavState> {
  constructor(props) {
    super(props);
  }

  private toggleContactUsModal = (show: boolean) => () => {
    const { actions } = this.props;
    show ? actions.header.openContactUsModal() : actions.header.closeContactUsModal();
  }

  private handleRedirect = (url) => {
    let portalUrl = '';
    if (typeof window === 'object' && window.__PORTAL_URL__) {
      portalUrl = window.__PORTAL_URL__;
    }

    window.open(portalUrl + url, '_blank');

  }

  public render() {
    const { auth, channel, openContactUsModal, hideContactUsSelection } = this.props;

    return (
      <div className="portal-area-top-nav">
        <div className="container">
          <Row className="portal-area-header-flex-box">
            {auth.isAuthenticated && (
              <Col xs={hideContactUsSelection ? 12 : 6} className="portal-area-customer">
                <FiUser className="customer-area-top-nav__icon"
                        onClick={this.handleRedirect.bind(this, '/account/summary')}/><span>My Travel Account</span>
              </Col>
            )}

            {!auth.isAuthenticated && (
              <Col xs={hideContactUsSelection ? 12 : 6} className="portal-area-customer">
                <FiUser className="customer-area-top-nav__icon"
                        onClick={this.handleRedirect.bind(this, '/auth/login')}/><span>My Travel Account</span>
              </Col>
            )}

            {!hideContactUsSelection && (
              <Col xs={6} className="pointer">
                <a href={'https://www.admiral.com/contact-us/travel'} target={'_blank'}>
                  <FiPhone className="customer-area-top-nav__icon"/>Contact Us
                </a>
              </Col>
            )}
          </Row>
        </div>
        <ContactUsModal
          channel={channel}
          showModal={openContactUsModal}
          closeModal={this.toggleContactUsModal(false)}
          isAuthenticated={auth.isAuthenticated}
        />
      </div>
    );
  }
}

export default flow([
  connect(
    (state: IStore) => ({
      auth: state.auth,
      openContactUsModal: state.header.isContactUsModalOpened,
    }),
    (dispatch) => ({
      actions: {
        auth: bindActionCreators({ ...authActions }, dispatch),
        header: bindActionCreators({ ...headerActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(PortalAreaTopNav);
