import { IAuthAction, IAuthState } from './models';
import axios from 'axios';
import { push } from 'react-router-redux';
import * as api from '../../data/api/api';
import { ICustomer, ICustomerClaim, IMarketingUpsell, IPolicyClaims } from '../../data/api/models';
import * as moment from 'moment';
import Heap from '../../helpers/Heap';
import { get, isEmpty, kebabCase, last, sortBy } from 'lodash';
import { decryptClient, encryptClient } from './Encryption';

export const LOGIN = 'b2c/auth/LOGIN';
export const LOGIN_FAILURE = 'b2c/auth/LOGIN_FAILURE';
export const LOGOUT = 'b2c/auth/LOGOUT';
export const UPDATE = 'b2c/auth/UPDATE';
export const RELOAD = 'b2c/auth/RELOAD';
export const FORGOTTEN_PASSWORD = 'b2c/auth/FORGOTTEN_PASSWORD';
export const CREATE_ACCOUNT = 'b2c/auth/CREATE_ACCOUNT';
export const PASSWORD_RESET = 'b2c/auth/PASSWORD_RESET';
export const RESET = 'b2c/auth/RESET';
export const UNAUTHENTICATE = 'b2c/auth/UNAUTHENTICATE';
export const HAS_ERROR = 'b2c/auth/HAS_ERROR';
export const SET_CUSTOMER_CLAIMS_IFRAME_URL = 'b2c/auth/SET_CUSTOMER_CLAIMS_IFRAME_URL';
export const SET_CUSTOMER_UPSELL_MARKETING = 'b2c/auth/SET_CUSTOMER_UPSELL_MARKETING';
export const SET_CUSTOMER_CLAIMS = 'b2c/auth/SET_CUSTOMER_CLAIMS';
export const SET_CUSTOMER_POLICY_CLAIMS = 'b2c/auth/SET_CUSTOMER_POLICY_CLAIMS';
export const SET_LOAD_CUSTOMER_CLAIMS = 'b2c/auth/SET_LOAD_CUSTOMER_CLAIMS';
export const CUSTOMER_PASSWORD_UPDATE_SUCCESS = 'b2c/auth/CUSTOMER_PASSWORD_UPDATE_SUCCESS';
export const CUSTOMER_PASSWORD_UPDATE_FAILED = 'b2c/auth/CUSTOMER_PASSWORD_UPDATE_FAILED';
export const SET_CUSTOMER_HOUSEHOLD_OFFER = 'b2c/auth/CUSTOMER_HOUSEHOLD_OFFER';

const initialState: IAuthState = {
  isAuthenticated: false,
  loginFailed: false,
  loginFailedCounter: 0,
  resetPassword: false,
  createAccount: false,
  customer: null,
  policyClaims: [],
  offers: null,
  hasPasswordBeenReset: false,
  error: {
    login: [],
    reset: [],
    update: [],
    updateCustomerPassword: null,
    remind: null,
  },
  updateCustomerPassword: {
    success: false,
    failed: false,
  },
};

export function reducer(state: IAuthState = initialState, action: IAuthAction): IAuthState {
  switch (action.type) {
    case LOGIN:
      return {
        ...state,
        isAuthenticated: true,
        resetPassword: false,
        customer: action.payload,
        loginFailed: false,
        loginFailedCounter: 0,
      };
    case SET_CUSTOMER_CLAIMS_IFRAME_URL:
      return {
        ...state,
        customer: {
          ...state.customer,
          claimsIframeUrl: action.payload,
        },
      };
    case SET_CUSTOMER_CLAIMS:
      return {
        ...state,
        customer: {
          ...state.customer,
          claims: action.payload,
        },
      };
    case SET_CUSTOMER_POLICY_CLAIMS:
      return {
        ...state,
        policyClaims: action.payload,
      };
    case SET_CUSTOMER_UPSELL_MARKETING:
      return {
        ...state,
        customer: {
          ...state.customer,
          upsellMarketing: action.payload,
        },
      };
    case SET_CUSTOMER_HOUSEHOLD_OFFER:
      return {
        ...state,
        offers: {
          ...state.offers,
          household: action.payload
        },
      };
    case SET_LOAD_CUSTOMER_CLAIMS:
      return {
        ...state,
        customer: {
          ...state.customer,
          loadClaims: action.payload,
        },
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        loginFailed: true,
        loginFailedCounter: action.payload,
      };

    case RELOAD:
      return {
        ...state,
        customer: {
          claimsIframeUrl: state.customer.claimsIframeUrl,
          claims: state.customer.claims,
          loadClaims: state.customer.loadClaims,
          upsellMarketing: state.customer.upsellMarketing,
          ...action.payload,
        },
      };

    case UNAUTHENTICATE:
      return {
        ...state,
        isAuthenticated: false,
        resetPassword: false,
      };

    case LOGOUT:
      return {
        ...state,
        isAuthenticated: false,
        resetPassword: false,
        customer: null,
        policyClaims: [],
        offers: null,
      };

    case UPDATE:
      return {
        ...state,
        customer: action.payload,
      };

    case FORGOTTEN_PASSWORD:
      return {
        ...state,
        resetPassword: true,
      };

    case CREATE_ACCOUNT:
      return {
        ...state,
        resetPassword: false,
        createAccount: true,
      };

    case PASSWORD_RESET:
      return {
        ...state,
        resetPassword: false,
        hasPasswordBeenReset: true,
      };

    case HAS_ERROR:
      return {
        ...state,
        error: action.payload,
      };

    case RESET:
      return {
        ...state,
        hasPasswordBeenReset: false,
        error: null,
      };

    case CUSTOMER_PASSWORD_UPDATE_SUCCESS:
      return {
        ...state,
        error: {
          ...state.error,
          updateCustomerPassword: undefined,
        },
        updateCustomerPassword: {
          ...state.updateCustomerPassword,
          failed: false,
          success: action.payload,
        },
      };
    case CUSTOMER_PASSWORD_UPDATE_FAILED:
      return {
        ...state,
        updateCustomerPassword: {
          ...state.updateCustomerPassword,
          failed: true,
          success: false,
        },
      };

    default:
      return state;
  }
}

const login = (username, password, returnTo = '') => (dispatch, getState) => {
  const data = {
    username,
    password,
  };

  return axios.post('/auth/login', data, { baseURL: '' })
    .then((response) => {
      axios.defaults.headers.common = {
        ...axios.defaults.headers.common,
        Authorization: 'Bearer ' + response.data.access_token,
      };

      const cookieValue = JSON.stringify({ access_token: response.data.access_token });

      window.sessionStorage.setItem('authenticated', 'true')
      window.sessionStorage.setItem('token', encryptClient(cookieValue))

      return api.getCustomer().then((customer: ICustomer) => {
        dispatch({ type: LOGIN, payload: customer });

        const { branding } = getState();
        const newClaimsSystemFromDate = branding.channel.products[0].metadata.new_claims_system_from_date || '';

        let loadCustomerClaims = true;
        if (newClaimsSystemFromDate) {
          const date = moment(newClaimsSystemFromDate);
          loadCustomerClaims = customer.policies.filter((policy) => {
            return moment(policy.issuedAt).isAfter(date);
          }).length > 0;
        }

        dispatch({
          type: SET_LOAD_CUSTOMER_CLAIMS,
          payload: loadCustomerClaims,
        });
      }).then(() => {
        if (returnTo) {
          dispatch(push('/account/redirect?returnTo=' + returnTo));
        } else {
          dispatch(push('/account/summary'));
        }

        Heap.track('CustomerLoginSuccess', {});
      });
    })
    .catch((error) => {
      const { auth } = getState();
      const loginFailedCounter = auth.loginFailedCounter + 1;
      dispatch({ type: LOGIN_FAILURE, payload: loginFailedCounter });

      dispatch({ type: HAS_ERROR, payload: { login: [] } });
      const errorArray = get(error, 'response.data.errors', []);

      let loginError;
      if (error.response && error.response.data) {
        if (typeof error.response.data === 'string') {
          loginError = error.response.data;
        }

        if (typeof error.response.data === 'object' && error.response.data.error_description) {
          loginError = error.response.data.error_description;
        }
      }

      if (!isEmpty(errorArray)) {
        dispatch({ type: HAS_ERROR, payload: { login: errorArray } });
      }

      Heap.track('CustomerLoginFailed', {
        customer_login_error: loginError,
      });
    });
};

const reAuthenticateCustomer = (redirectUrl?: string) => (dispatch, getState) => {
  const token = JSON.parse(decryptClient(window.sessionStorage.getItem('token')))

  axios.defaults.headers.common = {
    ...axios.defaults.headers.common,
    Authorization: 'Bearer ' + token.access_token,
  };

  return api.getCustomer().then((customer: ICustomer) => {
    dispatch({ type: LOGIN, payload: customer });

    const { branding } = getState();
    const newClaimsSystemFromDate = branding.channel.products[0].metadata.new_claims_system_from_date || '';

    let loadCustomerClaims = true;
    if (newClaimsSystemFromDate) {
      const date = moment(newClaimsSystemFromDate);
      loadCustomerClaims = customer.policies.filter((policy) => {
        return moment(policy.issuedAt).isAfter(date);
      }).length > 0;
    }

    dispatch({
      type: SET_LOAD_CUSTOMER_CLAIMS,
      payload: loadCustomerClaims,
    });
    if (redirectUrl) {
      dispatch(push(redirectUrl));
    }
  })
}

const unauthenticate = (): IAuthAction => ({ type: UNAUTHENTICATE });

const logout = (redirect = true) => (dispatch) => {
  dispatch(unauthenticate());

  return axios.post('/auth/logout', {}, { baseURL: '' })
    .then(() => {
      delete axios.defaults.headers.common.Authorization;
      window.sessionStorage.removeItem('authenticated')
      window.sessionStorage.removeItem('token')
      if (redirect) {
        dispatch(push('/auth/login'));
      }

      setTimeout(() => {
        dispatch({ type: LOGOUT });
      }, 1000);
    });
};

const forgottenPassword = (email) => (dispatch) => {
  return api.passwordReminder(email)
    .then(() => {
      dispatch({ type: FORGOTTEN_PASSWORD });
    })
    .catch((error) => {
      dispatch({ type: HAS_ERROR, payload: { remind: error.response.data.errors } });
    });
};

const createAccount = (email) => (dispatch) => {
  return api.accountCreator(email)
    .then(() => {
      dispatch({ type: CREATE_ACCOUNT });
    });
};

const resetPassword = (token, values) => (dispatch) => {
  // const dob = moment(values.dob, 'DD/MM/YYYY').format('YYYY-MM-DD');
  return api.resetPassword(token, values)
    .then(() => {
      dispatch({ type: PASSWORD_RESET });
      dispatch(push('/auth/login'));
    })
    .then(() => {
      dispatch(login(values.email, values.password))
        .then(() => {
          dispatch(push('/account/summary'));
          dispatch({ type: RESET });
        })
        .catch((error) => {
          dispatch({ type: HAS_ERROR, payload: { reset: error.response.data.errors } });
        });
    })
    .catch((error) => {
      dispatch({ type: HAS_ERROR, payload: { reset: error.response.data.errors } });
    });
};

const update = (customer: ICustomer) => (dispatch) => {
  return api.updateCustomer(customer)
    .then((customer: ICustomer) => {
      dispatch({ type: UPDATE, payload: customer });
    })
    .catch((error) => {
      dispatch({ type: HAS_ERROR, payload: { update: error.response.data.errors } });
    });
};

const reloadCustomer = () => (dispatch) => {
  return api.getCustomer().then((customer: ICustomer) => {
    dispatch({ type: RELOAD, payload: customer });
  });
};

const getCustomerRateableClaims = () => (dispatch) => {
  return api.getCustomerRateableClaims().then((claims: IPolicyClaims[]) => {
    dispatch({ type: SET_CUSTOMER_POLICY_CLAIMS, payload: claims })
  })
};

const updateCustomerPassword = (currentPassword, newPassword, newPasswordConfirmation, callback) => (dispatch) => {
  return api.updateUserPassword(currentPassword, newPassword, newPasswordConfirmation).then(() => {
    dispatch({ type: CUSTOMER_PASSWORD_UPDATE_SUCCESS, payload: true });
    callback();
  }).catch((error) => {
    dispatch({ type: CUSTOMER_PASSWORD_UPDATE_FAILED, payload: true });
    dispatch({ type: HAS_ERROR, payload: { updateCustomerPassword: error.response.data.errors } });
  });
};

const getCustomerClaims = () => (dispatch) => {
  return api.getCustomerClaims().then((claims: ICustomerClaim[]) => {
    dispatch({ type: SET_CUSTOMER_CLAIMS, payload: claims });
  });
};

const getCustomerUpsellMarketing = () => (dispatch) => {
  return api.getCustomerUpsellMarketing().then((marketing: IMarketingUpsell[]) => {
    dispatch({ type: SET_CUSTOMER_UPSELL_MARKETING, payload: marketing });
  });
};

const getCustomerClaimsIframeUrl = () => (dispatch) => {
  return api.getCustomerClaimsIframeUrl().then((url) => {
    dispatch({ type: SET_CUSTOMER_CLAIMS_IFRAME_URL, payload: url });
    return url;
  });
};

const getHouseholdOffer = (policyId: string) => (dispatch) => {
  return api.getHouseHoldOffer(policyId).then((data) => {
    dispatch(setHouseholdOffer(data))
    return data;
  })
}

const setHouseholdOffer = (data) => (dispatch) => {
  dispatch({ type: SET_CUSTOMER_HOUSEHOLD_OFFER, payload: data })
}

const downloadDocument = (documentId: string, type: string, resourceId: string, documentName: string, view: boolean = false) => (dispatch) => {
  return api.getDocumentFile(documentId, type, resourceId).then((res) => {
    if (get(res, 'data')) {
      const pdfBlob = new Blob([get(res, 'data')], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(pdfBlob);
      if (view) {
        window.open(fileURL, '_blank_');
      } else {
        const link = document.createElement('a');
        link.href = fileURL;
        link.download = kebabCase(documentName) + '.pdf';
        link.click();
      }
      URL.revokeObjectURL(fileURL);
    }
  })
}

const downloadDocuments = (payload, type: string, resourceId: string) => (dispatch) => {
  return api.getDocumentsFile(payload, type, resourceId)
    .then((response) => {
      const url = get(response, 'data.meta.url');
      if (url) {
        window.open(url, '_blank');
        return true;
      }
    })
}

const getLatestPolicy = () => (dispatch, getState) => {
  const { auth } = getState();
  return last(sortBy(get(auth, 'customer.activePolicies', []), ['metadata', 'start_date'])) || null;
}

export interface IAuthActions {
  login(username, password, returnTo: string);

  logout();

  forgottenPassword(email);

  createAccount(email);

  resetPassword(token, values);

  update(customer: ICustomer);

  reloadCustomer();

  reAuthenticateCustomer(redirectUrl?: string);

  downloadDocument(documentId: string, type: string, resourceId: string, documentName: string, view?: boolean);

  updateCustomerPassword(currentPassword, password, passwordConfirmation, callback);

  getCustomerClaimsIframeUrl();

  getCustomerClaims();

  getCustomerRateableClaims();

  getCustomerUpsellMarketing();

  getLatestPolicy();

  getHouseholdOffer(policyId: string);

  setHouseholdOffer(data: any);

  downloadDocuments(payload: any, type: string, resourceId: string);

}

export const actions: IAuthActions = {
  login,
  logout,
  update,
  forgottenPassword,
  createAccount,
  resetPassword,
  reloadCustomer,
  updateCustomerPassword,
  getCustomerRateableClaims,
  getCustomerUpsellMarketing,
  getCustomerClaims,
  getCustomerClaimsIframeUrl,
  downloadDocument,
  getLatestPolicy,
  downloadDocuments,
  reAuthenticateCustomer,
  getHouseholdOffer,
  setHouseholdOffer,
};
