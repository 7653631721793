import { IProduct } from '../../../../../data/api/models';
import { OverlayTrigger, Popover } from 'react-bootstrap';
import * as Commonmark from 'react-commonmark';
import { FaQuestionCircle } from 'react-icons/fa';
import * as React from 'react';

export const renderProductDefinition = (product: IProduct) => (name: string, useProvidedString?: string) => {
  const definition = useProvidedString ? useProvidedString : product.metadata.definitions[name];
  let className;

  if (!definition) {
    return null;
  }

  const popover = (
    <Popover
      id={`popover-${name}`}
    >
      <Commonmark source={definition}/>
    </Popover>
  );

  className = (name === 'region' || name === 'destinations') ? 'info-btn no-margin' : 'info-btn';

  return (
    <OverlayTrigger
      trigger="click"
      rootClose={true}
      overlay={popover}
      placement="bottom"
      positionLeft={200}
      positionTop={50}
    >
      <FaQuestionCircle data-d={name}
                        className={className}/>
    </OverlayTrigger>
  );
}
