import * as React from 'react';
import { withRouter } from 'react-router';
import { flow } from 'lodash';
import { FiPhone, FiLogOut, FiHelpCircle } from 'react-icons/fi/';
import { FaHandsHelping} from 'react-icons/fa';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../redux/IStore';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import { actions as authActions, IAuthActions } from '../../../../../../services/auth/reducer';
import { IAuthState } from '../../../../../../services/auth/models';
import { IChannel } from '../../../../../../data/api/models';
import { IHeaderActions } from '../../../Header/models';
import { actions as headerActions } from '../../../Header/reducer';
import ContactUsModal from '../../../../components/ContactUsModal';

interface ICustomerAreaTopNavProps {
  channel: IChannel;
  auth: IAuthState;
  openContactUsModal: boolean;
  hideContactUsSelection: boolean;
  actions: {
    auth: IAuthActions;
    header: IHeaderActions;
  };
}

interface ICustomerAreaTopNavState {
  showContactUsModal: boolean;
}

class CustomerAreaTopNav extends React.Component<ICustomerAreaTopNavProps, ICustomerAreaTopNavState> {
  constructor(props) {
    super(props);
  }

  private toggleContactUsModal = (show: boolean) => () => {
    const { actions } = this.props;
    show ? actions.header.openContactUsModal() : actions.header.closeContactUsModal();
  }

  public render() {
    const { actions, auth, channel, openContactUsModal, hideContactUsSelection } = this.props;
    const hideFAQ = true;

    return (
      <div className="customer-area-top-nav">
        <ul>
          {auth.isAuthenticated && (
            <li>
              <Link to={'/account/manage'}>
                <FaHandsHelping className="customer-area-top-nav__icon"/>Accessibility and Support
              </Link>
            </li>
          )}

          {!hideContactUsSelection && (
            <li className="pointer">
              <a href={'https://www.admiral.com/contact-us/travel'} target={'_blank'}>
                <FiPhone className="customer-area-top-nav__icon"/>Contact Us
              </a>
            </li>
          )}
          <ContactUsModal
            channel={channel}
            showModal={openContactUsModal}
            closeModal={this.toggleContactUsModal(false)}
            isAuthenticated={auth.isAuthenticated}
          />

          {auth.isAuthenticated && (
            <>
              {!hideFAQ && (
                <li>
                  <Link to={'/account/faqs'}>
                    <FiHelpCircle className="customer-area-top-nav__icon"/>FAQ
                  </Link>
                </li>
              )}
              <li className="pointer"><a onClick={actions.auth.logout}>
                <FiLogOut className="customer-area-top-nav__icon"/> Logout</a>
              </li>
            </>
          )}
        </ul>
      </div>
    );
  }
}

export default flow([
  connect(
    (state: IStore) => ({
      auth: state.auth,
      openContactUsModal: state.header.isContactUsModalOpened,
    }),
    (dispatch) => ({
      actions: {
        auth: bindActionCreators({ ...authActions }, dispatch),
        header: bindActionCreators({ ...headerActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(CustomerAreaTopNav);
