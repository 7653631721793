import * as React from 'react';
import { Link } from 'react-router-dom';
import { II18nMessages } from '../../../../../../services/branding/models';
import { flow, map } from 'lodash';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { IStore } from '../../../../../../redux/IStore';
import { IQuoteState } from '../../../../../../services/quote/models';
import './style.scss';

interface IQuoteWizardNavigationProps {
  i18n: II18nMessages;
  steps: any[];
  currentStep: string;
  quote: IQuoteState;
}

class QuoteWizardNavigation extends React.Component<IQuoteWizardNavigationProps> {
  private renderItem = (activeKey) => (item, key) => {
    const { quote: { application: { steps = [] } } } = this.props;

    // Prevent user clicking quote in header after going back to a previous step
    // Disable all the steps after the current one to prevent issues with BCP rules where AMT is not allowed
    const currentStepIndex = steps.indexOf(this.props.currentStep);
    const itemIndex = steps.indexOf(item.key);
    const isEnabled = itemIndex > -1
      && (item.key !== 'schemeChoice' || activeKey === item.key)
      && itemIndex <= currentStepIndex;

    let className = isEnabled ? '' : 'disabled';

    if (item.key === activeKey) {
      className += ' active';
    }

    const hideLinks = this.props.currentStep === 'complete';
    const link = isEnabled && ! hideLinks ? item.route : '#';

    return (
      <li key={key} className={className}>
        <Link to={link}>
          <p><span>Step {key + 1}</span>{item.title}</p>
          <div className="number">{key + 1}</div>
        </Link>
      </li>
    );
  }

  private getStepsToRender = () =>  {
    const { currentStep } = this.props;
    let activeKey: string = '';

    this.props.steps.forEach((step) => {
      // Set the active key
      if (step.key === currentStep) {
        activeKey = typeof step.parent !== 'undefined' ? step.parent : step.key;
      }
    });

    const shownSteps = this.props.steps
      .filter((step) => {
        return (typeof step.hidden === 'undefined') || step.hidden === false;
      });

    const steps = shownSteps
      .filter((step) => {
        return typeof step.parent === 'undefined';
      });

    return { steps, activeKey };
  }

  public render() {
    const { steps, activeKey } = this.getStepsToRender();

    return (
      <div className="wizard">
        <div>
          <ul>
            {map(steps, this.renderItem(activeKey))}
          </ul>
        </div>
      </div>
    );
  }
}

export default flow([
  connect(
    (state: IStore) => ({
      quote: state.quote,
    }),
  ),
  withRouter,
])(QuoteWizardNavigation);
