import * as React from 'react';
import { map } from 'lodash';
import PropTypes from 'prop-types';
import { FaPhone } from 'react-icons/fa';
import { Modal, Button } from 'react-bootstrap';
import { IChannel } from '../../../../data/api/models';

interface IContactUsModalProps {
  channel: IChannel;
  showModal: PropTypes.func.isRequired;
  closeModal: PropTypes.func.isRequired;
  isAuthenticated: boolean;
}

interface IContactUsModalState {
  showModal: boolean;
}

class ContactUsModal extends React.Component<IContactUsModalProps, IContactUsModalState> {
  public render() {
    const { channel, showModal, closeModal, isAuthenticated } = this.props;
    const fixedPhoneNumber = '0333 234 9913';

    return (
      <Modal show={showModal} onHide={closeModal}>
        <Modal.Header closeButton={true}/>
        <Modal.Body>
          <h3>Have a Question?</h3>
          <ul className={'opening-hours'}>
            <li>
              If you have a question about your cover you can read our FAQ’s <a target={'_blank'}
                 href={'https://www.admiral.com/travel-insurance/FAQs'}>
                here </a>
              or contact us on:
            </li>
          </ul>
          <div className={'text-center'}>
            <a href={`tel:${fixedPhoneNumber}`}>
              <Button bsStyle="primary" className="phone-btn">
                <FaPhone/>
                {fixedPhoneNumber}
              </Button>
            </a>
          </div>
          <h4>
            <ul className="opening-hours special">
              {map(channel.openingHours, ((item, key) => {
                return <li key={key}>{item}</li>;
              }))}
            </ul>
          </h4>
          {isAuthenticated && (
            <ul className="opening-hours">
              <li>{channel.emergencyNumber}</li>
              <li>{channel.claimsNumber}</li>
            </ul>
          )}
        </Modal.Body>
      </Modal>
    );
  }
}

export default ContactUsModal;
