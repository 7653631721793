import { ITraveller } from '../data/api/models';
import { get } from 'lodash';
import * as moment from 'moment';

export const getTravellerAddressString = (traveller: ITraveller) => {
  if (!get(traveller, 'address', null)) {
    return;
  }
  return [
    traveller.address.line1,
    traveller.address.line2,
    traveller.address.line3,
    traveller.address.town,
    traveller.address.country,
    traveller.address.postcode
  ].filter((value) => !!value).join(', ');
};

export const mapTraveller = (type) => (traveller: ITraveller, key: number) => {
  const data: any = {
    id: traveller.id,
    title: traveller.title,
    first_name: traveller.firstName,
    last_name: traveller.lastName,
    dob: dateToApi(traveller.dob),
    screening: traveller.screening,
    screening_id: traveller.screeningSessionId,
    conditions_covered: true,
    marketing_options: traveller.marketingOptions,
    metadata: traveller.metadata,
  };

  if (key === 0 && type === 'adult') {
    data.email = traveller.email;
    data.phone1 = traveller.phone;
    data.address = traveller.address;
  }

  return data;
}

const dateToApi = (date) => {
  if (!date) {
    return '';
  }
  const formattedDate = moment(date, 'DD/MM/YYYY').utcOffset(0, true).format();
  return formattedDate !== 'Invalid Date' ? formattedDate : '';
}
