import * as React from 'react';

const ErrorFormControl = ({
                            meta,
                            showMessage,
                            fontColor = ''
                          }) => {
  if (fontColor) {
    return (
      <div className={showMessage && meta.error ? '' : 'hidden'}>
        {meta.error && (
          <div style={{color: fontColor}}>{meta.error}</div>
        )}
      </div>
    )
  }else {
    return (
      <div className={showMessage && meta.error ? 'error-message' : 'hidden'}>
        {meta.error && (
          <div>{meta.error}</div>
        )}
      </div>
    );
  }
};

export default ErrorFormControl;
