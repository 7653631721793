import * as React from 'react';

/* tslint:disable:max-line-length */

interface IProps {
  type: string;
}

const plane = (
  <svg viewBox="0 0 58 58" version="1.1" xmlns="http://www.w3.org/2000/svg" fill="#41a5f5" >
    <path d="M25.9209511,28.3690782 L7.84941329,16.9764781 C7.84941329,16.9764781 9.45953387,15.7247638 11.1723428,14.7953759 C13.3705144,15.1488952 34.9976349,19.3199433 34.9976349,19.3199433 L25.9209511,28.3690782" id="Fill-1"  />
    <path d="M27.9587527,16.543851 L29.1824407,15.3239052 C29.6030294,14.9046028 29.5995677,14.5206736 29.1824407,14.1039594 L26.1223552,11.0540949 C25.7441715,10.6770678 25.2716583,10.6822444 24.8986671,11.0540949 L21.2267376,14.7139323 L27.9587527,16.543851 Z" id="Fill-2" />
    <path d="M41.3193839,50.3440632 C41.3193839,50.3440632 42.5749089,48.7396294 43.5071262,47.0320191 C43.1525306,44.8405192 38.9687843,23.2790398 38.9687843,23.2790398 L29.8921005,32.3281747 L41.3193839,50.3440632 Z" id="Fill-3" />
    <path d="M41.9670837,30.5660461 L43.1916372,29.3461003 C43.6122259,28.9267979 43.9973328,28.9302489 44.4153252,29.3461003 L47.4745453,32.3968276 C47.852729,32.7738547 47.8475365,33.2449229 47.4745453,33.6167734 L43.8034812,37.2774736 L41.9670837,30.5660461 Z" id="Fill-4" />
    <path d="M49.2881504,9.50170269 C50.4573177,10.6672945 48.7143844,16.8489859 46.9359693,18.6228251 L23.7481124,40.4306302 L24.3616872,45.9195236 L21.9220998,48.3516503 L17.8641979,40.6575367 L10.2953325,36.7604397 L12.7357853,34.3283129 L18.2415161,34.9400114 L40.1399139,11.8475532 C41.8672699,10.124617 48.0938863,8.3110908 49.2881504,9.50170269 Z" id="Fill-5" />
    <path d="M20.4708092,42.2898829 L14.6578583,43.8152465 L16.187901,38.0192098" id="Fill-6" />
  </svg>
);

const planeAnnual = (
  <svg id="Layer_1" data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100" fill="#41a5f5">
    <g id="Group_317" data-name="Group 317">
      <g id="Admiral_nonresponseive_plane" data-name="Admiral nonresponseive plane">
        <path id="Fill-1" d="M59.12,31.49,37.62,8.26a40.79,40.79,0,0,1,5.6-1.85C46.23,7.76,75.59,22,75.59,22L59.12,31.49" />
        <path id="Fill-2" d="M66.58,15.34l2.21-1.28a1.11,1.11,0,0,0,.66-1.43,1.5,1.5,0,0,0-.19-.32L66,6.85a1.19,1.19,0,0,0-1.61-.55l-.14.08-6.63,3.76Z" />
        <path id="Fill-3" d="M72.74,68.84a39.7,39.7,0,0,0,4.4-3.9c.33-3.27,2.61-35.73,2.61-35.73L63.28,38.68Z" />
        <path id="Fill-4" d="M81.25,40.79l2.21-1.28a1.14,1.14,0,0,1,1.59.17,1.57,1.57,0,0,1,.18.31l3.21,5.54a1.2,1.2,0,0,1-.33,1.66l-.14.08L81.3,51.1Z" />
        <path id="Fill-5" d="M99.81,13.44c1.22,2.13-3.65,10.3-6.87,12.16L51.39,47.92,50.15,56l-4.42,2.54L42.87,46l-9.33-8.48L38,35l7.63,3L85.81,13.3C89,11.48,98.55,11.28,99.81,13.44Z" />
        <path id="Fill-6" d="M45.92,49.31l-8.84,0,4.43-7.78"/>
      </g>
      <g id="Admiral_nonresponseive_plane-2" data-name="Admiral nonresponseive plane-2">
        <path id="Fill-1-2" d="M13.75,32.91,2.19,20.43a22.89,22.89,0,0,1,3-1c1.62.72,17.4,8.39,17.4,8.39l-8.84,5.08"/>
        <path id="Fill-2-2" d="M17.74,24.24l1.19-.69a.6.6,0,0,0,.35-.77.42.42,0,0,0-.1-.17l-1.73-2.94a.64.64,0,0,0-.86-.29l-.08,0-3.56,2Z" />
        <path id="Fill-3-2" d="M21.05,53a22.31,22.31,0,0,0,2.36-2.09c.18-1.76,1.4-19.2,1.4-19.2L16,36.78Z"/>
        <path id="Fill-4-2" d="M25.63,37.91l1.19-.69a.6.6,0,0,1,.85.09.56.56,0,0,1,.09.17l1.73,3a.65.65,0,0,1-.18.9l-.07,0-3.59,2.06Z"/>
        <path id="Fill-5-2" d="M35.6,23.22c.66,1.14-2,5.53-3.69,6.53l-22.33,12-.65,4.35L6.55,47.45,5,40.7,0,36.15l2.38-1.37,4.1,1.6L28.07,23.14C29.76,22.16,34.92,22.06,35.6,23.22Z"/>
        <path id="Fill-6-2" d="M6.65,42.48H1.9L4.28,38.3"/>
      </g>
      <g id="Admiral_nonresponseive_plane-3" data-name="Admiral nonresponseive plane-3">
        <path id="Fill-1-3" d="M37.33,73.76,25.77,61.28a21.24,21.24,0,0,1,3-1c1.62.73,17.39,8.39,17.39,8.39l-8.84,5.09"/>
        <path id="Fill-2-3" d="M41.33,65.08l1.19-.69a.59.59,0,0,0,.35-.76.69.69,0,0,0-.1-.17L41,60.52a.65.65,0,0,0-.86-.3l-.07.05-3.57,2Z"/>
        <path id="Fill-3-3" d="M44.63,93.83A21.44,21.44,0,0,0,47,91.73c.17-1.76,1.4-19.2,1.4-19.2l-8.84,5.09Z"/>
        <path id="Fill-4-3" d="M49.21,78.75l1.19-.68a.6.6,0,0,1,.85.09.86.86,0,0,1,.1.16l1.72,3a.64.64,0,0,1-.17.89l-.08,0-3.58,2.06Z"/>
        <path id="Fill-5-3" d="M59.18,64.06c.66,1.14-2,5.53-3.68,6.53l-22.34,12-.65,4.35L30.14,88.3l-1.55-6.75-5-4.56L26,75.62l4.1,1.61L51.66,64C53.35,63,58.5,62.9,59.18,64.06Z"/>
        <path id="Fill-6-3" d="M30.23,83.33H25.48l2.38-4.18"/>
      </g>
    </g>
  </svg>
)

export default ({type}: IProps) => {
  switch (type) {
    case 'single':
      return plane;
    case 'annual':
      return planeAnnual;
    default:
      console.error('No type provided');
  }
};
