import * as React from 'react';
import { Col, ControlLabel, FormGroup, Row } from 'react-bootstrap';
import { Field } from 'redux-form';
import FieldFormControl from '../FieldFormControl';
import { doesNotStartWith, hasEmojis, isNotLongerThan, isPostcode, isRequired } from '../../util/validators';
import PostcodeControl from '../PostcodeControl';
import { II18nMessages } from '../../services/branding/models';
import { IChannel, IPostcodeAddress, ITraveller } from '../../data/api/models';
import TitleField from '../TitleField';
import { get } from 'lodash';
import { BsInfoCircle } from 'react-icons/bs';

interface IBillingAddressForm {
  i18n: II18nMessages;
  formBasePath: string;
  channel: IChannel;
  change: any;
  dispatch: any;
  reuseCustomerAddress: boolean;
  travellerAddress?: string;
  leadTraveller?: ITraveller;
}

class BillingAddress extends React.Component<IBillingAddressForm> {

  constructor(props) {
    super(props);
    const { leadTraveller, change, formBasePath } = props;
    if (leadTraveller) {
      change(`${formBasePath}.title`, get(leadTraveller, 'title'));
      change(`${formBasePath}.firstName`, get(leadTraveller, 'firstName'));
      change(`${formBasePath}.lastName`, get(leadTraveller, 'lastName'));
    }
  }

  public state = {
    showAddressField: false,
  };

  private handleSelectAddress = (address: IPostcodeAddress) => {
    const { change, formBasePath, dispatch } = this.props;
    dispatch(change(`${formBasePath}.address.line1`, address.line1));
    dispatch(change(`${formBasePath}.address.line2`, address.line2));
    dispatch(change(`${formBasePath}.address.town`, address.town));
    dispatch(change(`${formBasePath}.address.county`, address.county));
    dispatch(change(`${formBasePath}.address.postcode`, address.postcode));
    dispatch(change(`${formBasePath}.address.country`, address.country));
    this.setState({ showAddressField: true });
  }

  public render() {
    const { i18n, channel, formBasePath, reuseCustomerAddress, travellerAddress } = this.props;

    const postcodeValidators = [
      isRequired,
      isPostcode,
      hasEmojis,
      isNotLongerThan(8)
    ];

    if (channel.channelType === 'AGG') {
      postcodeValidators.push(doesNotStartWith(
        ['JE', 'GY', 'GX'],
        true,
        'We cannot provide cover for your area.',
      ));
    }

    return (
      <div className="section">
        <Row className="section billing" style={{ paddingTop: '0' }}>
          <Col>
            <h3 className="title" style={{ marginBottom: '15px', marginTop: '0' }}>Cardholder Details</h3>
          </Col>
        </Row>
        <div style={{ marginBottom: '15px' }}>
          <Field
            name="reuseCustomerAddress"
            type="checkbox"
            value="true"
            component={FieldFormControl}
          >
            <div/>
            Is billing address same as your address?
          </Field>
        </div>
        {travellerAddress && (
          <Row className="billing">
            <Col xs={12} style={{ marginBottom: '10px' }}>
              <strong>{'( ' + travellerAddress + ' )'}</strong>
            </Col>
          </Row>
        )}
        <div className="manual-field-label">
          {i18n.quote.tripDetails.traveller.name}
        </div>
        <FormGroup controlId="name">
          <div className="full-name-fields">
            <Col xs={3} className="full-width-xs">
              <TitleField
                type={'adult'}
                name={`${formBasePath}.title`}
                validate={[isRequired]}
              />
            </Col>
            <Col xs={3} className="full-width-xs">
              <Field
                name={`${formBasePath}.firstName`}
                type="text"
                placeholder="First Name"
                component={FieldFormControl}
                validate={[isRequired, hasEmojis]}
                autocomplete="new-password"
              />
            </Col>
            <Col xs={6} className="full-width-xs">
              <Field
                name={`${formBasePath}.lastName`}
                type="text"
                placeholder="Last Name"
                component={FieldFormControl}
                validate={[isRequired, hasEmojis]}
                autocomplete="new-password"
              />
            </Col>
          </div>
        </FormGroup>
        {!reuseCustomerAddress && (
          <>
            <Row className="section billing">
              <FormGroup controlId="postcode">
                <Col componentClass={ControlLabel} xs={6} className="full-width-xs">
                  {'Billing ' + i18n.quote.tripDetails.traveller.postcode}
                </Col>
                <Col xs={6} className="full-width-xs">
                  <Field
                    autocomplete="new-password"
                    name={`${formBasePath}.address.postcode`}
                    type="text"
                    component={PostcodeControl}
                    onSelectAddress={this.handleSelectAddress}
                    validate={postcodeValidators}
                  />
                </Col>
              </FormGroup>
            </Row>

            <Row className="section no-top-border billing">
              <FormGroup controlId="address">
                <Col componentClass={ControlLabel} xs={6} className="full-width-xs">
                  {'Billing ' + i18n.quote.tripDetails.traveller.address}
                </Col>
                <Col xs={6} className="full-width-xs">
                  <Field
                    autocomplete="new-password"
                    className="line-top"
                    name={`${formBasePath}.address.line1`}
                    placeholder="Line 1"
                    type="text"
                    component={FieldFormControl}
                    validate={[isRequired, hasEmojis]}
                  />
                  <Field
                    autocomplete="new-password"
                    className="line-middle"
                    name={`${formBasePath}.address.line2`}
                    placeholder="Line 2"
                    type="text"
                    component={FieldFormControl}
                    validate={[
                      hasEmojis,
                    ]}
                  />
                  <Field
                    autocomplete="new-password"
                    className="line-middle"
                    name={`${formBasePath}.address.town`}
                    type="text"
                    placeholder="Town"
                    component={FieldFormControl}
                    validate={[
                      isRequired,
                      hasEmojis,
                    ]}
                  />
                  <Field
                    autocomplete="new-password"
                    className="line-bottom"
                    name={`${formBasePath}.address.county`}
                    placeholder="County"
                    type="text"
                    component={FieldFormControl}
                    validate={[
                      hasEmojis,
                    ]}
                  />
                  <Field
                    autocomplete="new-password"
                    className="line-bottom"
                    name={`${formBasePath}.address.country`}
                    placeholder="Country"
                    type="text"
                    component={FieldFormControl}
                    validate={[
                      isRequired,
                      hasEmojis,
                    ]}
                  />
                </Col>
              </FormGroup>
            </Row>
          </>
        )}

        <Row className={'dynamic-row'}>
          <Col>
            <div className={'card-details-helper'}> <span><BsInfoCircle/></span>  Card details can be entered in the next step</div>
          </Col>
        </Row>
      </div>
    );
  }
}

export default BillingAddress;
