import * as React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { IBrandingActions, IBrandingState, II18nMessages } from '../../../../services/branding/models';
import { withRouter } from 'react-router';
import { flow, get } from 'lodash';
import { Nav, Navbar } from 'react-bootstrap';
import { IHeaderActions } from '../Header/models';
import { actions as quoteActions } from '../../../../services/quote/reducer';
import { actions as headerActions } from '../Header/reducer';
import { IChannel } from '../../../../data/api/models';
import { destroy } from 'redux-form';
import { IQuoteActions } from '../../../../services/quote/models';
import { push } from 'react-router-redux';
import { IStore } from '../../../../redux/IStore';
import NavLink from './components/NavLink';
import CustomerAreaTopNav from './components/CustomerAreaTopNav';
import { actions as brandingActions } from '../../../../services/branding/reducer';
import { actions as authActions, IAuthActions } from '../../../../services/auth/reducer';
import { IAuthState } from '../../../../services/auth/models';
import { MarketingUpsellModal } from '../../../../components/MarketingUpsellModal';
import { actions as modalActions } from '../../../../services/modal/reducer';
import { IModalActions, IUpsellModal } from '../../../../services/modal/model';
import { mtaActions } from '../../../../services/mta/actions';
import { IMtaActions } from '../../../../services/mta/models';

interface IHeaderProps {
  auth: IAuthState;
  classes: any;
  isDrawerOpened: boolean;
  i18n: II18nMessages;
  actions: {
    auth: IAuthActions;
    push: (route: string) => void;
    header: IHeaderActions;
    quote: IQuoteActions;
    destroy(formName: string);
    branding: IBrandingActions,
    modal: IModalActions,
    mta: IMtaActions
  };
  location: any;
  channel: IChannel;
  branding?: IBrandingState;
  upsellModal: IUpsellModal;
  hideContactUsSelection: boolean;
  isUpsellModalOpened: boolean;
}

interface IHeaderState {
  showModal: boolean;
  expanded: boolean;
}

const RENEWAL_PRODUCT_CODE = 'TRAV-RENEW';

// This can be used as a drop in replacement for header with some minor changes
class NewHeader extends React.Component<IHeaderProps, IHeaderState> {

  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      expanded: false
    }

  }

  private handleLogoClick = () => {
    return;
  }

  private renderLogo = () => {
    const { channel, auth } = this.props;

    if (!auth.isAuthenticated && channel.channelType === 'AGG' && channel.channelLogo) {
      return (
        <div className="logo-container">
          <div className="channel-logo-solo">
            <img src={channel.channelLogo} alt={channel.name}/>
          </div>
        </div>
      );
    }

    if (!auth.isAuthenticated && channel.channelType === 'AGG' && !channel.channelLogo && channel.additionalLogo
      && channel.products[0].productCode !== RENEWAL_PRODUCT_CODE) {
      return (
        <div className="logo-container">
          <div className="logo-link">
            <img src={require('./img/admiral_logo.png')} alt="Admiral"/>
          </div>
          <div className="logo-divider"/>
          <div className="agg-logo">
            <img src={channel.additionalLogo} alt={channel.name}/>
          </div>
        </div>
      );
    }

    return (
      <div className="logo-container">
        <div className="logo-link" onClick={this.handleLogoClick}>
          <img src={require('./img/admiral_logo.png')} alt="Admiral"/>
        </div>
        <div className="logo-divider"/>
        <div className="logo-title">
          Travel
        </div>
      </div>
    );
  }

  private handleGetNewQuote = () => {
    const { actions, branding } = this.props;

    actions.auth.setHouseholdOffer(null)

    const loadQuoteProcess = () => {
      actions.destroy('quote');
      actions.quote.reset();
      actions.quote.loadOccupations();
      actions.quote.loadIndustries();
      actions.quote.loadDestinations();
      actions.push('/');
    };

    if (branding.channel.channelType !== 'B2C' || branding.channel.key !== 'admiral-b2c') {
      actions.branding.loadChannel(branding.defaultChannelKey).then(loadQuoteProcess);
      return;
    }

    loadQuoteProcess();
  }

  private handleOnselect = () => {
    this.setState({ expanded: false })
  }

  private handleOnToggle = () => {
    const { expanded } = this.state;
    this.setState({ expanded: !expanded })
  }

  public render() {
    const { channel, auth, hideContactUsSelection, actions, upsellModal, } = this.props;
    const { expanded } = this.state;
    const customerHasActivePolicy = get(auth, 'customer.activePolicies', []).length >= 1;

    return (
      <>
        <Navbar className="new-header" expanded={expanded} onToggle={this.handleOnToggle}>
          <CustomerAreaTopNav channel={channel} hideContactUsSelection={hideContactUsSelection}/>
          <Navbar.Header>
            {this.renderLogo()}
            {auth.isAuthenticated &&
              <Navbar.Toggle/>}
          </Navbar.Header>
          {auth.isAuthenticated &&
            <Navbar.Collapse>
              <Nav pullRight={true}>
                <NavLink to="/account/summary" onSelect={this.handleOnselect}>
                  Home
                </NavLink>
                <NavLink to="/account/policies" onSelect={this.handleOnselect}>
                  Policies
                </NavLink>
                <NavLink to="/account/quotes" onSelect={this.handleOnselect}>
                  Quotes
                </NavLink>
                <NavLink to="/account/renewals" onSelect={this.handleOnselect}>
                  Renewals
                </NavLink>
                {customerHasActivePolicy && (
                  <NavLink to="/account/make-a-change" onSelect={this.handleOnselect}>
                    Make a Change
                  </NavLink>
                )}
                {auth.customer.loadClaims && (
                  <NavLink to="/account/claims" onSelect={this.handleOnselect}>
                    Claims
                  </NavLink>
                )}
                <li><
                  a className="pointer" onClick={this.handleGetNewQuote}>
                  Quick Buy
                </a>
                </li>
              </Nav>
            </Navbar.Collapse>
          }
        </Navbar>
        {auth.isAuthenticated && <MarketingUpsellModal
          push={actions.push}
          upsellModal={upsellModal}
          modalActions={actions.modal}
          mtaActions={actions.mta}
        />
        }
      </>
    );
  }
}

export default flow([
  connect(
    (state: IStore) => ({
      isDrawerOpened: state.header.isDrawerOpened,
      i18n: state.branding.i18n,
      channel: state.branding.channel,
      branding: state.branding,
      auth: state.auth,
      upsellModal: state.modal.upsellModal,
    }),
    (dispatch) => ({
      actions: {
        dispatch,
        push: bindActionCreators(push, dispatch),
        modal: bindActionCreators({ ...modalActions }, dispatch),
        header: bindActionCreators({ ...headerActions }, dispatch),
        destroy: bindActionCreators(destroy, dispatch),
        quote: bindActionCreators({ ...quoteActions }, dispatch),
        branding: bindActionCreators({ ...brandingActions }, dispatch),
        auth: bindActionCreators({ ...authActions }, dispatch),
        mta: bindActionCreators({ ...mtaActions }, dispatch),
      },
    }),
  ),
  withRouter,
])(NewHeader);
