import * as React from 'react';
import { Field } from 'redux-form';
import { II18nMessages } from '../../../../../../services/branding/models';
import { Button, Col, FormGroup, Row } from 'react-bootstrap';
import SelectControl from '../../../../../../components/SelectControl';
import { FaTrashAlt } from 'react-icons/fa';
import { IStateCities } from '../../../../../../data/api/models';
import { isRequired } from '../../../../../../util/validators';
import { filter, intersection } from 'lodash';

interface ITripDetailsForm {
  i18n: II18nMessages;
  stateCities: IStateCities[];
  stateCitiesValue: string[];
  destinationsValue: string[];
  placeholder?: string;

  renderDefinition(name: string): any;

  pristine: boolean;
  fields: any;
  mta?: boolean;
}

class StateCities extends React.Component<ITripDetailsForm> {
  private addField = () => {
    const { fields } = this.props;

    fields.push('');
  }

  private deleteField = (index) => () => {
    const { fields } = this.props;

    fields.remove(index);
  }

  private renderFields() {
    const { fields, stateCities, stateCitiesValue, mta = false, placeholder = 'Select...' } = this.props;

    const stateCitiesFiltered = filter(stateCities, (stateCity) => stateCity.included === true);

    const stateCityList = stateCitiesFiltered.map((stateCity) => ({
      label: stateCity.name,
      value: stateCity.name,
      disabled: stateCitiesValue && stateCitiesValue.length ? stateCitiesValue.indexOf(stateCity.name) > -1 : false,
    }));

    return fields.map((stateCity, index) => {
      const columns = {
        first: {
          field: {
            xs: 12,
          },
          btn: {
            xs: 0,
            className: 'hidden',
          },
        },
        normal: {
          field: {
            xs: 9,
            sm: 10,
            md: 10,
            lg: 10,
          },
          btn: {
            xs: 3,
            sm: 2,
            md: 2,
            lg: 2,
          },
        },
      };
      const colConfig = columns[index === 0 ? 'first' : 'normal'];

      return (
        <Row key={index} className={mta ? 'dynamic-row' : ''}>
          <Col {...colConfig.field}>
            <Field
              placeholder={placeholder}
              name={stateCity}
              options={stateCityList}
              component={SelectControl}
              validate={[isRequired]}
            />
          </Col>
          <Col {...colConfig.btn}>
            <Button bsStyle="link" onClick={this.deleteField(index)}>
              <FaTrashAlt/>
            </Button>
          </Col>
        </Row>
      );
    });
  }

  public componentWillMount() {
    if (this.props.fields.length === 0) {
      this.addField();
    }
  }

  public render() {
    const { i18n, renderDefinition, destinationsValue, mta = false } = this.props;
    if (destinationsValue.length && intersection(destinationsValue, ['USA', 'United States']).length) {
      return (
        <div className={mta ? '' : 'section'}>
          {i18n.quote.tripDetails.stateCities} {renderDefinition('stateCities')}
          <Row className={mta ? 'dynamic-row margin-top' : 'margin-top'}>
            <Col sm={12}>
              <FormGroup controlId="destination">
                <Col sm={12} className="destinations-inputs">
                  {this.renderFields()}
                </Col>
              </FormGroup>
            </Col>
          </Row>
          <Button bsStyle="primary" onClick={this.addField}>
            {i18n.quote.tripDetails.addStateOrCity}
          </Button>
        </div>
      );
    }
    return null;
  }
}

export default StateCities;
