import { ITrackingLink } from '../models';

export function fromJsonApi(data: any, included: any[]): ITrackingLink {
  const { id, attributes: { name, whitelabel : { phone }, status } } = data;

  return {
    id,
    name,
    phone,
    status,
  };
}
