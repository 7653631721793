import { Col, HelpBlock, OverlayTrigger, Popover, Row } from 'react-bootstrap';
import * as Commonmark from 'react-commonmark';
import { FaInfoCircle } from 'react-icons/fa';
import { Field } from 'redux-form';
import FieldFormControl from '../../../../../../../components/FieldFormControl';
import { isRequired } from '../../../../../../../util/validators';
import * as React from 'react';
import { get } from 'lodash';
import { connect } from 'react-redux';
import { IStore } from '../../../../../../../redux/IStore';
import { bindActionCreators } from 'redux';
import { actions as modalActions } from '../../../../../../../services/modal/reducer';
import { IModalActions, IModalState } from '../../../../../../../services/modal/model';

interface IDynamicTextWithHelpProps {
  title: string,
  name: string,
  active: string | boolean,
  optionKeyName?: string,
  props?: any,
  validationMessage?: string,
  onChange?: (value: boolean) => any,
  selectedNoText?: any,
  selectedYesText?: any,
  popOverTitle?: string,
  dynamicText?: string,
  popOverText?: string,
  modal: IModalState,
  actions: {
    modal: IModalActions
  }
}

const DynamicTextWithHelp = (
  {
    title,
    name,
    active,
    props,
    modal,
    actions,
    optionKeyName,
    validationMessage = 'both',
    onChange = null,
    selectedNoText = null,
    selectedYesText = null,
    popOverTitle = null,
    dynamicText = null,
    popOverText = null,
  }: IDynamicTextWithHelpProps) => {

  const popover = (
    <Popover id={`popover-trigger-${name}`}>
      <Commonmark source={popOverTitle}/>
      <Commonmark source={popOverText}/>
    </Popover>
  );

  const error = get(props, `synchronousError.options.${optionKeyName}`, null);
  let hasIcon = false;
  let source = '';
  let subText;

  const openOptionsTextModal = () => {
    actions.modal.openOptionsModal(optionKeyName)
  }

  switch (optionKeyName) {
    case 'winter_sports':
      hasIcon = true;
      source = require('../../../img/winter_sports.svg');
      subText = (
        <div className={'option-subtext'}>Check out our winter sports activities list <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openOptionsTextModal}>here</span>
        </div>
      )
      break;
    case 'cruise_cover':
      hasIcon = true;
      source = require('../../../img/cruise_cover.svg');
      subText = (
        <div className={'option-subtext'}>Click <span
          style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={openOptionsTextModal}>here</span> to see what we define as a cruise
        </div>
      )
      break;
  }

  const showSubText = () => {
    if (active && selectedYesText) {
      return <>{selectedYesText}</>;
    }
    if (active === false && selectedNoText) {
      return <div style={{ color: '#FF7911' }}>{selectedNoText}</div>;
    }
  };

  return (

    <div className="medical-question option-section">

      <Row>
        {hasIcon && (
          <Col sm={9}>
            <div className="question-text">
              <>
                <img src={source} alt="Admiral" className="additional-cover-icon-desktop-ws-cc"/>
                <div className="title" style={{ paddingTop: '30px' }}>{title}</div>
                <div>{subText}</div>
              </>
            </div>
          </Col>
        )}

        {!hasIcon && (
          <Col sm={9}>
            <div className="question-text">
              <div className="title">
                {title}
                <OverlayTrigger
                  trigger="click"
                  rootClose={true}
                  placement="right"
                  overlay={popover}
                >
                  <FaInfoCircle className="info-btn"/>
                </OverlayTrigger>
              </div>
              {showSubText()}
            </div>

          </Col>
        )}

        <Col sm={3}>
          <Row>
            <Col xs={12}>

              <div className="trip-details-addition">
                <Col xs={6} className="radio-button-yes">
                  <Field
                    name={name}
                    type="radio"
                    format={null}
                    value={true}
                    complexValue={true}
                    className="answer"
                    component={FieldFormControl}
                    validationMessage={validationMessage}
                    validate={[isRequired]}
                    onChange={onChange ? () => onChange(true) : null}
                  >

                    <div className="medical-btn yes">
                      Yes
                    </div>
                  </Field>
                </Col>
                <Col xs={6} className="radio-button-no">
                  <Field
                    name={name}
                    type="radio"
                    format={null}
                    value={false}
                    complexValue={true}
                    className="answer"
                    validationMessage={validationMessage}
                    component={FieldFormControl}
                    validate={[isRequired]}
                    onChange={onChange ? () => onChange(false) : null}
                  >
                    <div className="medical-btn no">
                      No
                    </div>
                  </Field>
                </Col>
                {error && validationMessage !== 'both' && props.submitFailed && (
                  <Row style={{ textAlign: 'center' }}>
                    <Col>
                      <div className={error ? 'has-error' : ''}>
                        {<HelpBlock>{error}</HelpBlock>}
                      </div>
                    </Col>
                  </Row>
                )}
              </div>
            </Col>
          </Row>
        </Col>

      </Row>

      <Row>
        <div className={'option-subtext-display'}>
          {showSubText()}
        </div>
      </Row>
    </div>
  );
}

export default connect(
  (state: IStore) => {
    return {
      modal: state.modal,
    };
  },
  (dispatch) => ({
    actions: {
      modal: bindActionCreators({ ...modalActions }, dispatch),

    },
  }))(DynamicTextWithHelp);
