import { routerReducer as router } from 'react-router-redux';
import { reducer as form } from 'redux-form';
import { combineReducers, Reducer } from 'redux';
import { reducer as header } from '../scenes/Shell/scenes/Header/reducer';
import { reducer as content } from '../services/content/reducer';
import { reducer as branding } from '../services/branding/reducer';
import { reducer as quote } from '../services/quote/reducer';
import { reducer as verisk } from '../services/verisk/reducer';
import { reducer as modal } from '../services/modal/reducer';
import { reducer as auth } from '../services/auth/reducer';
import { reducer as mta } from '../services/mta/reducer';
import { reducer as toastr } from 'react-redux-toastr'
import { IStore } from './IStore';

const rootReducer: Reducer<IStore> = combineReducers<IStore>({
  auth,
  router,
  form,
  branding,
  header,
  verisk,
  modal,
  content,
  quote,
  toastr,
  mta,
});

export default rootReducer;
