import { IMarketingUpsell } from '../models';

export function fromJsonApi(data: any[]): IMarketingUpsell[] {
  return data.map((item) => {
    const { attributes: { policy_id, policy_application_id, metadata, updated_at } } = item;

    return {
      policyId: policy_id,
      policyApplicationId: policy_application_id,
      metadata,
      updatedAt: updated_at
    };
  });
}
