import React = require('react');
import { useState } from 'react';

interface IFlightCancelledPanel {
  premium: any;
}

export const FlightCancelledPanel = ({ premium }: IFlightCancelledPanel) => {
  const [showText, setShowText] = useState(false);

  const handleOnClick = () => {
    setShowText(!showText)
  }

  return (
    <div className={'information-panel-container'}>

      <div className={'dropdown-box'} onClick={handleOnClick}>
        <div className={'dropdown-box-text'}>What happens if my holiday or flight gets cancelled?</div>
        <div className={'dropdown-box-icon'}>{showText ? 'Less Information' : 'More Information'}</div>
      </div>

      {showText && (
        <div className={'information-panel-body'}>
          <div>
            <ul style={{ paddingLeft: '20px' }}>
              <li>
                Some consumer protections are already in place ensure airlines and some holiday providers take
                responsibility if your flight/trip is cancelled. For example, if your airline cancels your flight, you
                are legally entitled to a full refund or a replacement flight to get you to your destination and you may
                also be entitled to <a
                href="https://www.citizensadvice.org.uk/consumer/holiday-cancellations-and-compensation/if-your-flights-delayed-or-cancelled/"
                target="_blank">compensation</a>
              </li>
              <li>
                Whilst you need to explore the options the airline provides you first, Travel Insurance sits around some
                of these existing protections to provide additional support. For example, if your departure flight is
                delayed/cancelled due to <u>strike</u>, <u>industrial action</u>, <u>severe weather
                conditions</u> or <u>mechanical breakdown</u> and the airline does not provide alternative transport
                within 24 hours of your original departure, we’ll
                pay for the reasonable cost of additional transport/accommodation to get you to your destination, or
                cover your irrecoverable costs if you choose to abandon your trip, whichever is less

              </li>
            </ul>
            Please note however:
            <ul style={{ paddingLeft: '20px' }}>
              <li>
                If the reason for your airline cancelling your flight is due staff shortages or errors leading to
                overbooking, this is the responsibility of the airline and there is no cover under your travel insurance
                policy
              </li>
              <li>
                If a strike or industrial action has already been announced when you bought your insurance or booked
                your trip (whichever is later), there is no cover if a cancellation or trip disruption occurs as a
                result
              </li>
            </ul>
            <div>
              <>
                Please refer to the Insurance Product Information document or Policy Wordings for further
                information.
              </>
            </div>
          </div>
        </div>
      )}

    </div>
  )
}
