import { applyFilters, applyIncludes, applySorts, IFilter, queryString } from '../../util/url';
import { Endpoints } from './endpoints';
import { forEach } from 'lodash';

export interface IEndpointConfiguration {
  filters?: IFilter[];
  includes?: string[];
  sorts?: string[];
  limit?: number;
  offset?: number;
  params?: {
    [key: string]: string;
  };
}

/**
 * Construct URL
 * @param endpoint
 * @param config
 * @returns {string}
 */
export function url(endpoint: Endpoints, config: IEndpointConfiguration = {}): string {
  let ep = endpoint.toString();

  if (config.filters) {
    ep = applyFilters(ep, config.filters);
  }

  if (config.includes) {
    ep = applyIncludes(ep, config.includes);
  }

  if (config.sorts) {
    ep = applySorts(ep, config.sorts);
  }

  if (config.limit) {
    ep = queryString(ep, ['page[limit]=' + config.limit]);
  }

  if (config.offset) {
    ep = queryString(ep, ['page[offset]=' + config.limit]);
  }

  if (config.params) {
    forEach(config.params, (value, key) => {
      if (ep.indexOf(`{${key}}`) > -1) {
        ep = ep.replace(`{${key}}`, value);
        return;
      }

      ep = queryString(ep, [`${key}=` + value]);
    });
  }

  return ep;
}

/**
 * Takes the included relationships and filters through them to find the correct relationship
 * to return.
 *
 * @param resource
 * @param included
 * @param relationship
 * @param collection
 */
export function getIncludedResource(resource, included, relationship, collection = false) {
  let type = '';
  const ids = [];
  const data = [];

  if (resource && relationship && resource.relationships && resource.relationships[relationship]) {
    const includedRelationship = resource.relationships[relationship];

    if (Array.isArray(includedRelationship.data)) {
      // collection
      includedRelationship.data.forEach((relation) => {
        ids.push(relation.id);
        type = relation.type;
      });
    } else if (includedRelationship.data) {
      // single
      ids.push(includedRelationship.data.id);
      type = includedRelationship.data.type;
    }
  }

  if (included) {
    included.forEach((relation) => {
      if (relation.type === type && ids.indexOf(relation.id) !== -1) {
        data.push(relation);
      }
    });
  }

  return collection ? data : (data[0] || null);
}
