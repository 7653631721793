import {
  AdditionalCover,
  AggPayment,
  Complete,
  MedicalDeclaration,
  Payment,
  SchemeChoice,
  Signpost,
  TravellersDetails,
  TripDeclaration,
  TripDetails,
} from './scenes';
import {IChannel, IStep} from '../../data/api/models';
import {Component} from 'react';

export interface IReactStep extends IStep {
  component: Component;
}

const componentKeyMap = {
  tripDeclaration: TripDeclaration,
  tripDetails: TripDetails,
  travellersDetails: TravellersDetails,
  medicalDeclaration: MedicalDeclaration,
  additionalCover: AdditionalCover,
  schemeChoice: SchemeChoice,
  signpost: Signpost,
  payment: Payment,
  cytiPayment: AggPayment,
  idolPayment: AggPayment,
  complete: Complete,
};

function mapComponentsFromKey(step): IReactStep {
  return {
    ...step,
    component: componentKeyMap[step.key],
  };
}

export default (channel?: IChannel): IReactStep[] => {
  const defaultSteps: IStep[] = [
    {
      title: 'Declaration',
      key: 'tripDeclaration',
      route: '/',
      calculatePremium: false,
      saveApplication: false,
      saveApplicationWithPatch: false,
      hidden: true,
    },
    {
      title: 'Trip',
      key: 'tripDetails',
      route: '/trip-details',
      calculatePremium: false,
      saveApplication: false,
      saveApplicationWithPatch: false,
    },
    {
      title: 'Travellers',
      key: 'travellersDetails',
      route: '/travellers-details',
      calculatePremium: false,
      saveApplication: true,
      saveApplicationWithPatch: false,
    },
    {
      title: 'Medical',
      key: 'medicalDeclaration',
      route: '/medical-declaration',
      calculatePremium: false,
      saveApplication: true,
      saveApplicationWithPatch: false,
    },
    {
      title: 'Quote',
      key: 'schemeChoice',
      route: '/scheme-choice',
      calculatePremium: true,
      saveApplication: true,
      saveApplicationWithPatch: false,
    },
    {
      title: 'Signpost',
      key: 'signpost',
      route: '/signpost',
      calculatePremium: false,
      saveApplication: false,
      saveApplicationWithPatch: false,
      parent: 'schemeChoice',
      hidden: true,
    },
    {
      title: 'Additional',
      key: 'additionalCover',
      route: '/additional-cover',
      calculatePremium: true,
      saveApplication: true,
      saveApplicationWithPatch: false,
      parent: 'schemeChoice',
    },
    {
      title: 'Payment',
      key: 'payment',
      route: '/payment',
      calculatePremium: true,
      saveApplication: true,
      saveApplicationWithPatch: false,
      parent: 'schemeChoice',
    },
    {
      title: 'Complete',
      key: 'complete',
      route: '/complete',
      calculatePremium: true,
      saveApplication: true,
      saveApplicationWithPatch: false,
      parent: 'schemeChoice',
    },
  ];

  const config = channel.steps ? channel.steps : defaultSteps;

  return config.map(mapComponentsFromKey);
};
