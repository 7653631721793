import * as React from 'react';
import { flow, map } from 'lodash';
import { Field, formValueSelector, reduxForm } from 'redux-form';
import { Button, Col, Form, FormGroup, Modal } from 'react-bootstrap';
import { ITraveller } from '../../../../../../data/api/models';
import { isRequired, isValidPassword } from '../../../../../../util/validators';
import { IAuthState } from '../../../../../../services/auth/models';
import { connect } from 'react-redux';
import { IQuoteState } from '../../../../../../services/quote/models';
import PasswordInput from '../../../../../../components/PasswordInput';
import { IStore } from '../../../../../../redux/IStore';

interface ISaveQuoteFormProps {
  travellers: ITraveller[];
  auth: IAuthState;
  quote: IQuoteState;
  password: string;
  passwordConfirmation: string;
  onGoToMyAccount: any;
  onCloseModal: any;
  handleSubmit: any;
  dispatch: any;
  change: any;
  isQuoteSaved: boolean;
  submissionErrors: any;
  valid: any;
}

interface ISaveQuoteFormState {
  openModal: boolean;
}

class SaveQuoteForm extends React.Component<ISaveQuoteFormProps, ISaveQuoteFormState> {
  public render() {
    const {
      quote, handleSubmit, onGoToMyAccount, onCloseModal,
      password, passwordConfirmation, isQuoteSaved, submissionErrors, valid } = this.props;

    const NOT_APPLICABLE = 'not applicable';
    const customer = quote.customer;
    const customerStatus = customer && customer[0].status;
    const quoteReference = quote.application.quoteReference;
    const disableSaveQuote = (customerStatus === 'new' || customerStatus === NOT_APPLICABLE) &&
      !password && !passwordConfirmation && password !== passwordConfirmation;

    const isRenewal = quote.application.quoteType === 'renewal';

    return (
      <Form horizontal={true} onSubmit={handleSubmit} autoComplete="off">
        <Modal.Body>
          {!isQuoteSaved ? (
            <div>
              {customerStatus === NOT_APPLICABLE && (
                <div>
                  <p className="margin-top">
                    Please create an account by setting a password below so you can access these documents later.
                    Your password must be a minimum of 10 characters long with atleast one lower case, one uppercase
                    letter, one number and one of the following special characters : !@#$%?
                  </p>
                  <FormGroup>
                    <Col sm={12} className="">
                      <div className="manual-field-label">
                        <strong>Password</strong>
                      </div>
                      <Field
                        name="password"
                        type="password"
                        placeholder="Password"
                        component={PasswordInput}
                        strengthMeter={true}
                        autocomplete="new-password"
                        validate={[isRequired, isValidPassword]}
                      />
                    </Col>
                    <Col sm={12} className="margin-top">
                      <div className="manual-field-label">
                        <strong>Password confirmation</strong>
                      </div>
                      <Field
                        name="passwordConfirmation"
                        type="password"
                        placeholder="Confirm Password"
                        autocomplete="new-password"
                        component={PasswordInput}
                        validate={[isRequired]}
                      />
                    </Col>
                  </FormGroup>
                </div>
              )}
              {customerStatus === 'pending' || customerStatus === 'new' && (
                <div>
                  <p>We noticed that you already have an account with us, thanks for coming back!</p>
                  <p>Your quote has been saved under your account</p>
                  <h4>Reference: {quoteReference}</h4>
                  <p>You can access your account at any time, to view your quotes or documentation.</p>
                </div>
              )}
              {customerStatus === 'active' && !isQuoteSaved && !isRenewal && (
                <div>
                  <p>To save your your quote to your account, please click save</p>
                </div>
              )}
              {customerStatus === 'active' && !isQuoteSaved && isRenewal && (
                <div>
                  <p>
                    By choosing to save you are confirming that you would like us to renew your policy
                    based on the new details you have provided.
                  </p>
                </div>
              )}

              {submissionErrors && !!submissionErrors.length && (
                <div className="alert alert-danger" style={{ marginTop: '10px' }}>
                  {map(submissionErrors, (error: any, key) => {
                    if (error.detail === 'Customer is not active') {
                      return <p key={key}>Email address has been previously used and not activated. Please use an
                        alternative email to save your quote.</p>;
                    }
                    return <p key={key}>{error.detail}</p>;
                  })}
                </div>
              )}
            </div>
          ) : (
            <div>
              <p>Your quote has been saved under your account</p>
              <h3>Reference: {quoteReference}</h3>
              <p>You can access your account at any time, to view your documentation.</p>
              <p>If you have any queries about your quote, please call us on 0333 234 9913.</p>
            </div>
          )}
        </Modal.Body>
        <Modal.Footer>
          {!isQuoteSaved ? (
            <div>
              <div>
                <Button
                  bsStyle="primary"
                  type="submit"
                  disabled={disableSaveQuote || !valid}
                >
                  Save
                </Button>
                <Button
                  bsStyle="primary"
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </div>
            </div>
          ) : (
            <div>
              <Button
                bsStyle="primary"
                onClick={onCloseModal}
              >
                Close
              </Button>
              <Button
                bsStyle="primary"
                onClick={onGoToMyAccount}
              >
                Go to My Account
              </Button>
            </div>
          )}
        </Modal.Footer>
      </Form>
    );
  }
}

const validate = (values) => {
  const errors = { passwordConfirmation: '' };
  if (values.passwordConfirmation !== values.password) {
    errors.passwordConfirmation = 'Your confirmed password doesn\'t match the first field, please enter them again.';
  }
  return errors;
};

let selector;

export default flow([
  reduxForm({
    form: 'save_quote',
    validate,
    destroyOnUnmount: false,
    forceUnregisterOnUnmount: true,
  }),
  (component) => {
    selector = formValueSelector('save_quote');

    return component;
  },
  connect(
    (state: IStore) => {
      const password = selector(state, 'password');
      const passwordConfirmation = selector(state, 'passwordConfirmation');

      return {
        password,
        passwordConfirmation,
        auth: state.auth,
        quote: state.quote,
      };
    },
  ),
])(SaveQuoteForm);
