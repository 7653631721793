import { IMtaAction, IMtaState } from './models';

export const MTA_UPDATE_SUCCESS = 'MTA_UPDATE_SUCCESS';
export const MTA_UPDATE_FAILED = 'MTA_UPDATE_FAILED';
export const MTA_UPDATE_RESET = 'MTA_UPDATE_RESET';
export const SET_SELECTED_POLICY = 'SET_SELECTED_POLICY';
export const SET_POLICY_INFO = 'SET_POLICY_INFO';
export const MTA_SET_SELECTED_COVER_DETAIL = 'MTA_SET_SELECTED_COVER_DETAIL';
export const MTA_SET_CALCULATED_QUOTES = 'MTA_SET_CALCULATED_QUOTES';
export const MTA_SET_POLICY_PAYMENT_METHOD = 'MTA_SET_POLICY_PAYMENT_METHOD';
export const MTA_SET_QUOTE = 'MTA_SET_QUOTE';
export const MTA_IS_LOADING = 'MTA_IS_LOADING';
export const MTA_SET_COMPLETE = 'MTA_SET_COMPLETE';
export const MTA_SET_SIGNPOST = 'MTA_SET_SIGNPOST';
export const MTA_SET_PAYMENT_MODAL = 'MTA_SET_PAYMENT_MODAL';
export const MTA_SET_DESTINATIONS = 'MTA_SET_DESTINATIONS';
export const MTA_SET_STATE_CITIES = 'MTA_SET_STATE_CITIES';
export const MTA_SET_UPSELL_RULES = 'MTA_SET_UPSELL_RULES';
export const MTA_SET_UPSELL_OPTIONS = 'MTA_SET_UPSELL_OPTIONS';
export const MTA_SET_CANCELLATION_OPTIONS = 'MTA_SET_CANCELLATION_OPTIONS';
export const MTA_VOUCHER_CODE_SUCCESS = 'MTA_VOUCHER_CODE_SUCCESS';
export const MTA_SET_CAMPAIGN_CODE = 'MTA_SET_CAMPAIGN_CODE';
export const MTA_VOUCHER_CODE_ERROR = 'MTA_VOUCHER_CODE_ERROR';
export const MTA_VOUCHER_CODE_RESET = 'MTA_VOUCHER_CODE_RESET';

const initialState: IMtaState = {
  selectedPolicy: null,
  policyInfo: null,
  paymentMethod: null,
  rules: {
    canUpsell: false
  },
  mtaQuote: null,
  coverDetails: {
    selectedMta: null,
  },
  quotes: null,
  signpost: { isSignposted: false, isMedical: false },
  paymentModal: { open: false, transactionId: null },
  destinations: [],
  stateCities: [],
  complete: false,
  isLoading: false,
  cancellationOptions: null,
  upsellOptions: null,
  voucherCodeStatus: '',
  voucherCodeMessage: '',
  voucherCodePreventDisplay: false,
  campaignCode: null,
  afterSubmit: {
    changes: null,
    policyId: null,
    success: null,
    returnPremium: null,
    renewalPremium: null,
    quoteReference: null,
    discount: null,
    error: null,
    reason: null,
  },
};

export function reducer(state: IMtaState = initialState, action: IMtaAction): IMtaState {
  switch (action.type) {
    case SET_SELECTED_POLICY:
      return {
        ...state,
        selectedPolicy: action.payload,
      };
    case SET_POLICY_INFO:
      return {
        ...state,
        policyInfo: action.payload,
      };
    case MTA_SET_POLICY_PAYMENT_METHOD:
      return {
        ...state,
        paymentMethod: action.payload,
      };
    case MTA_SET_QUOTE:
      return {
        ...state,
        mtaQuote: action.payload,
      };
    case MTA_SET_CANCELLATION_OPTIONS:
      return {
        ...state,
        cancellationOptions: action.payload,
      };
    case MTA_SET_UPSELL_OPTIONS:
      return {
        ...state,
        upsellOptions: action.payload
      }
    case MTA_UPDATE_RESET:
      return {
        ...state,
        afterSubmit: {
          changes: null,
          policyId: null,
          success: null,
          error: null,
          discount: null,
          returnPremium: null,
          renewalPremium: null,
          quoteReference: null,
          reason: null,
        },
      };
    case MTA_UPDATE_SUCCESS:
      return {
        ...state,
        afterSubmit: {
          changes: action.payload.changes,
          policyId: action.payload.policyId,
          returnPremium: action.payload.returnPremium,
          renewalPremium: action.payload.renewalPremium,
          quoteReference: action.payload.quoteReference,
          discount: action.payload.discount,
          reason: action.payload.reason,
          success: true,
          error: null,
        },
      };
    case MTA_UPDATE_FAILED:
      return {
        ...state,
        afterSubmit: {
          changes: null,
          policyId: null,
          discount: null,
          returnPremium: null,
          renewalPremium: null,
          quoteReference: null,
          reason: null,
          success: false,
          error: action.payload,
        },
      };
    case MTA_SET_SELECTED_COVER_DETAIL:
      return {
        ...state,
        coverDetails: {
          selectedMta: action.payload,
        },
      };
    case MTA_SET_UPSELL_RULES:
      return {
        ...state,
        rules: {
          canUpsell: action.payload
        }
      };
    case MTA_SET_CALCULATED_QUOTES:
      return {
        ...state,
        quotes: action.payload,
      };
    case MTA_SET_SIGNPOST:
      return {
        ...state,
        signpost: action.payload,
      };
    case MTA_SET_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: action.payload,
      };
    case MTA_SET_DESTINATIONS:
      return {
        ...state,
        destinations: action.payload.destinations,
      };
    case MTA_SET_STATE_CITIES:
      return {
        ...state,
        stateCities: action.payload.stateCities,
      };
    case MTA_IS_LOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case MTA_SET_COMPLETE:
      return {
        ...state,
        complete: action.payload,
      };
    case MTA_SET_CAMPAIGN_CODE:
      return {
        ...state,
        campaignCode: action.payload.campaignCode,
      };
    case MTA_VOUCHER_CODE_SUCCESS:
      return {
        ...state,
        voucherCodeStatus: 'success',
        voucherCodeMessage: 'Voucher code applied',
        voucherCodePreventDisplay: action.payload.preventDisplay,
        regexVoucherCode: action.payload.regexVoucherCode,
      };
    case MTA_VOUCHER_CODE_ERROR:
      return {
        ...state,
        voucherCodeStatus: 'error',
        voucherCodeMessage: 'Voucher code not found',
      };
    case MTA_VOUCHER_CODE_RESET:
      return {
        ...state,
        voucherCodeStatus: '',
        voucherCodeMessage: '',
        regexVoucherCode: false,
      };
    default:
      return state;
  }
}
