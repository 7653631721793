import { IDocument } from '../models';

export function fromJsonApi(data, included: any[]): IDocument {
  return {
    id: data.id,
    name: data.attributes.name,
    filePath: data.attributes.file_path,
    shortName: data.attributes.short_name,
    displayName: data.attributes.display_name,
    versionNumber: data.attributes.version_number || '',
    status: data.attributes.status || '',
    documentType: data.attributes.document_type || '',
    fromDate: data.attributes.from_date || '',
    toDate: data.attributes.to_date || '',
    createdAt: data.attributes.created_at || '',
    updatedAt: data.attributes.updated_at || '',
  };
}
